import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTagsSearch } from "../request";

export const useGetTagsSearch = (params) => {

  const { isLoading, isFetching, data, error } = useQuery(
    [QUERY_KEYS.GET_TAGS_SEARCH, { ...params }],
    () => getTagsSearch(params),
    {
      enabled: !params.skip,
    }
  );

  return { data, isLoading: isLoading || isFetching, error };
};
