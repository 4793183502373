import { useCallback } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../../utils/shortenInteger";

const tooltip = {
  renderer: ({ datum }) => {
    const date = new Date(datum.timestamp);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const humanReadableDate = date.toLocaleString("en-US", options);
    const title = `<span style="font-size: 12px">${humanReadableDate}</span>`;

    return {
      title: title,
      content: `
                <b>Audience Votes:</b> ${shortenInteger(datum.dailyAudienceVotes)} <br />
                <b>Audience Votes (All time):</b> ${shortenInteger(
                  datum.allTimeAudienceVotes,
                )}<br/>
                <b>Audience Rating (All time):</b> ${datum.allTimeAudienceRating}<br/>
                <b>Critic Votes (All time):</b> ${shortenInteger(
                  datum.allTimeCriticVotes,
                )}<br/>
                <b>Critic Rating (All time):</b> ${datum.allTimeCriticRating}<br/>
            `,
    };
  },
};

export const useChartConfig = () => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);

  const getLineChartOptions = useCallback(() => {
    return {
      padding: { right: 0, bottom: 0, left: 0 },
      minWidth: 0,
      minHeight: 0,
      theme: theme,
      legend: {
        position: "bottom",
        spacing: 12,
      },
      series: [
        {
          type: "line",
          xKey: "timestamp",
          yKey: "dailyAudienceVotes",
          yName: "Audience Votes",
          marker: { enabled: false },
          visible: true,
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "allTimeAudienceVotes",
          yName: "Audience Votes (All time)",
          marker: { enabled: false },
          visible: false,
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "allTimeAudienceRating",
          yName: "Audience Rating (All time)",
          marker: { enabled: false },
          visible: false,
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "allTimeCriticVotes",
          yName: "Critic Votes (All time)",
          marker: { enabled: false },
          visible: false,
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "allTimeCriticRating",
          yName: "Critic Rating (All time)",
          marker: { enabled: false },
          visible: false,
          tooltip,
        },
      ],
      axes: [
        {
          type: "ordinal-time",
          position: "bottom",
          label: {
            format: "%b %-d, %Y",
          },
          crosshair: {
            label: {
              format: "%b %-d, %Y",
            },
          },
        },
        {
          // type: axisTypeValue || "number",
          type: "number",
          position: "left",
          nice: true,
          keys: ["allTimeRank", "dailyRank", "allTimeAudienceVotes", "allTimeCriticVotes", "dailyAudienceVotes", "allTimeAudienceRating", "allTimeCriticRating"],
          title: {
            enabled: false,
            text: "Votes & Ratings",
            fontSize: 10,
            color: "rgb(100 116 139)",
            spacing: 4,
          },
          label: {
            formatter: function (params) {
              return shortenInteger(params.value);
            },
          },
        },
      ],
    };
  }, [theme]);

  return { getLineChartOptions };
};
