import { useMemo, useState } from "react";
import { useGetChartTheme } from "../../../../../../hooks/UseTheme/useGetChartTheme";
import moment from "moment";
import { useThemeContext } from "../../../../../../app/ThemeProvider";
import { Mode } from "@cloudscape-design/global-styles";
import shortenFloat from "../../../../../../utils/shortenFloat";

const groupSeries = (seriesData, groupBy, timestampKey="timestamp") => {
  const grouped = {};

  seriesData.forEach((item) => {
    const key = moment.utc(item[timestampKey]).startOf(groupBy).unix();

    const timestampKeys = Object.keys(item).filter(k => k.startsWith(timestampKey));
    const otherValueKeys = Object.keys(item).filter(k => !k.startsWith(timestampKey));
    if (!grouped[key]) {
      grouped[key] = { ...otherValueKeys.reduce((acc, k) => ({ ...acc, [k]: 0 }), {}) };
      timestampKeys.forEach(k => {
        grouped[key][k] = item[k];
      });
    }
    otherValueKeys.forEach(k => {
      grouped[key][k] += item[k] ?? 0;
    });
  });

  const newSeriesData = Object.keys(grouped).map(key => {
    const item = grouped[key];
    const timestamp = moment.unix(key as any).toDate();
    return { ...item, [timestampKey]: timestamp };
  });

  return newSeriesData;
};

export const useChartConfig = ({
  nestedData,
  latestDataDate,
  selectedCategories,
  selectedCountries,
  selectedChartType,
  selectedGranularity,
  timelineEvents,
}) => {

  const { theme } = useGetChartTheme();
  const { mode } = useThemeContext();

  const [seriesMetadata, setSeriesMetadata] = useState({});

  const data = useMemo(() => {
    if (!nestedData || Object.keys(nestedData).length === 0) return [];

    const titleId = Object.keys(nestedData)[0];
    const titleData = nestedData[titleId];

    const selectedCountryCodes = selectedCountries.map(country => country.value);
    const selectedCategoryKeys = selectedCategories.map(category => category.value);
    const dateData = {};
    const seriesMetadata = {};
    Object.keys(titleData).filter(countryCode => selectedCountryCodes.includes(countryCode)).forEach(countryCode => {
      const countryData = titleData[countryCode];
      Object.keys(countryData).filter(categoryKey => selectedCategoryKeys.includes(categoryKey)).forEach(categoryKey => {
        const categoryData = titleData[countryCode][categoryKey];
        const itemKey = `${categoryKey}_${countryCode}`.replaceAll(".", "_");
        categoryData.forEach(d => {
          if (!dateData[d.date]) {
            dateData[d.date] = { "date": moment.utc(d.date).toDate() };
          }
          dateData[d.date][itemKey] = d.value;
        });
        seriesMetadata[itemKey] = {
          key: itemKey,
          category: selectedCategories.find(category => category.value === categoryKey),
          country: selectedCountries.find(country => country.value === countryCode),
        };
      });
    });
    setSeriesMetadata(seriesMetadata);

    const data = groupSeries(Object.values(dateData).sort((a: any, b: any) => a.date - b.date), selectedGranularity.value, "date");

    return data;
  }, [nestedData, selectedCountries, selectedCategories, selectedGranularity]);

  const series = useMemo(() => (
    Object.values(seriesMetadata).map((metadata: any) => (
      {
        type: selectedChartType.value,
        xKey: "date",
        yKey: metadata.key,
        yName: `${metadata.category.label} (${metadata.country.value.toUpperCase()})`,
        marker: { enabled: false },
        normalizedTo: selectedChartType.value === "area" ? 100 : undefined,
        stacked: selectedChartType.value === "area",
        tooltip: {
          renderer: (params) => {
            return {
              content: `${moment.utc(params.datum.date).format("LL")}: ${params.datum[metadata.key].toLocaleString(undefined, { maximumFractionDigits: 2 })}`,
            };
          },
        },
      }
    ))
  ), [seriesMetadata, selectedChartType]);

  const crosslines = useMemo(() => (
    timelineEvents?.[Object.keys(nestedData)[0]]?.events?.map(event => ({
      type: "line",
      enabled: true,
      value: moment.unix(event.timestamp).toDate(),
      label: {
        enabled: false,
        text: event.label,
      },
      lineDash: [5, 5],
      strokeOpacity: 0.7,
      stroke: mode === Mode.Dark ? "lightgray" : "gray",
      created_by: event.created_by,
      event_type: event.event_type,
    })) ?? []
  ), [timelineEvents, nestedData, mode]);

  const axes = useMemo(() => ([
    {
      type: "time",
      position: "bottom",
      crossLines: crosslines,
      max: latestDataDate ? moment.utc(latestDataDate).toDate() : undefined,
      nice: false,
    },
    {
      type: "number",
      position: "left",
      label: {
        formatter: (params) => shortenFloat(params.value) + (selectedChartType.value === "area" ? "%" : ""),
      },
    },
  ]), [crosslines, selectedChartType, latestDataDate]);

  return {
    data,
    theme,
    axes,
    series,
    height: 500,
    navigator: { enabled: true },
    crosslines,
    padding: { top: 30 },
  };
};