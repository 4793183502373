import {
  Alert,
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Multiselect,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";

interface TrackUnTrackConfirmationModalProps {
  ip_id: string;
  itemData: any;
  ipLists?: Array<{ value: string; label: string }>;
  visible: boolean;
  onDiscard: () => void;
  onConfirm: (params: {
    track: boolean;
    ip_id: string;
    ip_list: string[];
    data: any;
  }) => void;
  consentText?: string;
  track?: boolean;
  defaultSelectedIps?: string[];
}

export function TrackUnTrackConfirmationModal({
  ip_id,
  itemData,
  ipLists = [],
  visible,
  onDiscard,
  onConfirm,
  consentText,
  track = true,
  defaultSelectedIps,
}: TrackUnTrackConfirmationModalProps) {
  const [selectedIps, setSelectedIps] = useState(defaultSelectedIps || []);

  useEffect(() => {
    setSelectedIps(defaultSelectedIps || []);
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header={
        track
          ? "Remove Title from Tracking List"
          : "Add Title to Tracking List"
      }
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                onConfirm({
                  track,
                  ip_id,
                  ip_list: selectedIps,
                  data: itemData,
                })
              }
              disabled={
                (!track && selectedIps.length === 0)
              }
              data-testid="submit"
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        {!track && (
          <Alert statusIconAriaLabel="Info" type="info">
            Data for newly tracked titles will be available within 24 hours.
          </Alert>
        )}
        <FormField label="IP Lists">
          <Multiselect
            options={ipLists}
            selectedOptions={selectedIps.map((o) => ({ label: o, value: o }))}
            onChange={({ detail }) => setSelectedIps(detail.selectedOptions.map((o) => o.value))}
            placeholder="Select options"
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
}
