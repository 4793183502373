import { Icon, Link } from "@cloudscape-design/components";
import React from "react";
import { FC } from "react";

interface IMDbScoreProps {
  score: number;
  votes: number;
  id: string;
}

const IMDbScore: FC<IMDbScoreProps> = ({ score, votes, id }) => {
  return (
    <div className="flex items-center space-x-2">
      <img src="/svg/imdb.svg" alt="Votes" className="h-12 w-12" />
      <div className="text-black dark:text-slate-100">
        <div className="text-lg  dark:text-slate-50 font-bold leading-[1.25rem]">
          {score}
        </div>
        {id ? (
          <Link 
            href={`https://www.imdb.com/title/${id}/ratings/?ref_=tt_ov_rt`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="text-xs font-bold">IMDb Rating <Icon name="external" size="inherit" /></div>
          </Link>
          ) : null}
        <div className="text-xs italic dark:text-slate-400">{votes.toLocaleString()} votes</div>
      </div>
    </div>
  );
};

export default IMDbScore;
