import React, { useMemo, useState } from "react";
import { COLUMN_DEFINITIONS, ColumnDefinition, SortBy } from "../../../constants";
import { Button, ColumnLayout, Container, FormField, Header, Input, Select, SpaceBetween } from "@cloudscape-design/components";
import { ColumnDefinitionItem } from "../ColumnDefinitionItem";
import { DragDropContext, Draggable, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { useCreateReportContext } from "../hooks/useCreateReportContext";
import { BetterExpandableSection } from "../../../../../dashboards/news-dashboard/components/BetterExpandableSection";
import { PreviewTable } from "../PreviewTable";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index] as any;

  destClone.splice(droppableDestination.index, 0, { ...item, id: `${item.id}_${uuid()}` });

  return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const sortOrderOptions = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
];


export const SelectColumns = () => {

  const { 
    columnDefinitions, 
    setColumnDefinitions, 
    verifyErrors, 
    limit, 
    setLimit,
    sortBy,
    setSortBy,
  } = useCreateReportContext();

  const [searchValue, setSearchValue] = useState("");

  const filteredAllColumns = useMemo<Array<ColumnDefinition>>(() => {
    return COLUMN_DEFINITIONS.filter((definition) => {
      return definition.tags.some((tag) => tag.includes(searchValue));
    });
  }, [searchValue]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const idToList = {
      columns: columnDefinitions,
      allColumns: filteredAllColumns,
    };

    switch(source.droppableId) {
      case "columns":
        setColumnDefinitions(reorder(columnDefinitions, source.index, destination.index) as any);
        break;
      case "allColumns":
        setColumnDefinitions(copy(filteredAllColumns, columnDefinitions, source, destination) as any);
        break;
      default:
        setColumnDefinitions(move(idToList[source.droppableId], idToList[destination.droppableId], source, destination) as any);
        break;
    }
  };

  const getItemStyle = (draggableStyle, snapshot: DraggableStateSnapshot, droppableId) => {
    let baseStyle = {
      ...draggableStyle,
      userSelect: "none",
      margin: `0 0 1rem 0`,
      borderRadius: "1rem",
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0)",
    };

    if (snapshot.isDragging) {
      baseStyle.boxShadow = "0px 4px 8px 0px rgba(0, 0, 0, 0.25)";
    }

    if (snapshot.isDropAnimating) {
      const { curve, duration } = snapshot.dropAnimation;

      return {
        ...baseStyle,
        transition: `all ${curve} ${duration}s`,
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0)",
      };
    }

    return baseStyle;
  };

  const columnOptions = useMemo(() => {
    return columnDefinitions.map((col) => ({ label: col.headerText, value: col.id }));
  }, [columnDefinitions]);

  return (
    <SpaceBetween size="m">
      <Container
        header={
          <Header>Preview</Header>
        }
      >
        <PreviewTable columnDefinitions={columnDefinitions} />
      </Container>
      <Container>
        <FormField stretch errorText={(verifyErrors.includes("column_definitions_empty") && columnDefinitions.length === 0) && "Columns cannot be empty"}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex w-full h-[70vh]">
              <Droppable droppableId="columns">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} className={`flex flex-col basis-3/5 overflow-y-auto pr-4 ${columnDefinitions.length > 0 && "pb-36"}`}>
                    {columnDefinitions.length > 0 ? columnDefinitions.map((definition, index) => (
                      <Draggable
                        key={`${definition.id}_columns`}
                        draggableId={`${definition.id}_columns`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div 
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(provided.draggableProps.style, snapshot, "columns")}
                          >
                            <ColumnDefinitionItem 
                              dragHandleProps={provided.dragHandleProps} 
                              definition={definition} 
                              variant="default" 
                              itemIndex={index}
                            />
                          </div>
                        )}
                      </Draggable>
                    )) : (
                      <div className={`w-full h-full border-2 border-dashed flex items-center justify-center rounded-2xl ${snapshot.isDraggingOver ? "border-blue-400/50 bg-blue-400/5" : "dark:border-slate-600 border-slate-400"}`}>
                        <div className={`text-center ${snapshot.isDraggingOver ? "text-blue-400" : "dark:text-slate-400 text-slate-600"}`}>Drag columns here</div>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
              <div className="w-0 border-r dark:border-gray-600 border-gray-400"></div>
              <Droppable droppableId="allColumns" isDropDisabled>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} className="flex flex-col basis-2/5 overflow-y-auto px-4">
                    <div className="sticky top-0 z-50 dark:bg-[#161d26] bg-white pb-1 mb-2">
                      <Input
                        value={searchValue}
                        onChange={({ detail }) => setSearchValue(detail.value)}
                        placeholder="Search"
                        type="search"
                        invalid={false}
                      />
                    </div>
                    {Array.from(new Set(filteredAllColumns.map((x) => x.source))).map((source) => (
                      <div className="flex flex-col gap-2">
                        <BetterExpandableSection
                          header={<b>{source}</b>}
                          defaultExpanded
                        >
                          {filteredAllColumns.filter((x) => x.source === source).map((definition) => (
                            <Draggable
                              key={`${definition.id}_allColumns`}
                              draggableId={`${definition.id}_allColumns`}
                              index={filteredAllColumns.indexOf(definition)}
                            >
                              {(provided, snapshot) => (
                                <>
                                  <div 
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={{
                                      ...getItemStyle(provided.draggableProps.style, snapshot, "allColumns"),
                                      transform: snapshot.isDragging ? provided.draggableProps.style?.transform : "translate(0px, 0px)",
                                    }}
                                    className="mb-4"
                                  >
                                    <ColumnDefinitionItem 
                                      dragHandleProps={provided.dragHandleProps} 
                                      definition={definition} 
                                      variant="compact" 
                                    />
                                  </div>
                                  {snapshot.isDragging && (
                                    <div className={`opacity-50 mb-4`} style={{ transform: "none !important" }}>
                                      <ColumnDefinitionItem definition={definition} variant="compact" />
                                    </div>
                                  )}
                                </>
                              )}
                            </Draggable>
                          ))}
                        </BetterExpandableSection>
                      </div>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </FormField>
        <style>{`
        div[data-rbd-placeholder-context-id] {
          display: none !important;
        }
        `}</style>
      </Container>
      <Container
        header={
          <Header>Additional options</Header>
        }
      >
        <ColumnLayout columns={2}>
          <FormField label={<span>Sort by{" "}<i>- optional</i></span>}>
            <div className="flex flex-col gap-2">
              {sortBy.map((sort, index) => (
                <div className="flex gap-2 w-full">
                  <div className="grow">
                    <Select
                      options={columnOptions}
                      selectedOption={columnOptions.find((col) => col.value === sort.column_id)}
                      onChange={({ detail }) => {
                        const newOption = { column_id: detail.selectedOption.value, order: sort.order ?? "asc" } as SortBy;
                        setSortBy(sortBy.map((s, i) => i === index ? newOption : s));
                      }}
                      placeholder="Select a column"
                      disabled={columnDefinitions.length === 0}
                    />
                  </div>
                  <Select
                    options={sortOrderOptions}
                    selectedOption={sortOrderOptions.find((col) => col.value === sort.order ?? "asc")}
                    onChange={({ detail }) => {
                      const newOption = { column_id: sort.column_id, order: detail.selectedOption.value } as SortBy;
                      setSortBy(sortBy.map((s, i) => i === index ? newOption : s));
                    }}
                    placeholder="Select a sort order"
                    disabled={sort.column_id === undefined || columnDefinitions.length === 0}
                  />
                  <Button 
                    iconName="remove"
                    onClick={() => {
                      setSortBy(sortBy.filter((_, i) => i !== index));
                    }}
                  />
                </div>
              ))}
              <div className="mt-1">
                <Button
                  variant="inline-link"
                  onClick={() => {
                    setSortBy([...sortBy, { column_id: columnOptions.filter(o => !sortBy.map(s => s.column_id).includes(o.value))[0].value, order: "asc" }]);
                  }}
                >
                  (+) Add sort column
                </Button>
              </div>
            </div>
          </FormField>
          <FormField label={<span>Limit rows{" "}<i>- optional</i></span>}>
            <Input 
              onChange={({ detail }) => {
                if (detail.value.length === 0) {
                  setLimit(null);
                  return;
                }
                const newValue = parseInt(detail.value);
                if (newValue > 0) {
                  setLimit(newValue);
                }
              }}
              value={limit ? limit.toString() : null}
              type="number"
              inputMode="numeric"
              placeholder="Enter a value"
              step={1}
            />
          </FormField>
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  );
};