import {
  Button,
  ButtonDropdown,
  Container,
  ExpandableSection,
  Header,
  SegmentedControl,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import { React, useEffect, useState } from "react";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { IP_LIST, TAGS_LIST } from "../../../../config-global";
import { formatDate } from "../../../../utils/dateFormatter";
import getTextFilterCounterText from "../../../../utils/getTextFilterCounterText";
import { addQueryParams, getQueryParams } from "../../../../utils/queryUtils";
import { useAddYoutubeVideos } from "../../api/hooks/useAddYoutubeVideos";
import { calculateStartDate } from "../../utils";
import { AwardsTable } from "../components/AwardsTable";
import CastAndCrewComponent from "../components/CastAndCrewComponent";
import OTTProvider from "../components/OTTProvider";
import ProdnDistrib from "../components/ProductionnDistribution";
import IMDbScore from "../components/Scores/IMDbScore";
import RottenTomatesAudienceScore from "../components/Scores/RTAudienceScore";
import RottenTomatesCriticScore from "../components/Scores/RTCriticScore";
import StreamingWindowComponent from "../components/StreamingWindow";
import TagList from "../components/TagList";
import TagsAndIDsComponent from "../components/TagsAndIDsComponent";
import TrailerGrid from "../components/Trailer/Trailer";
import { RankTrends } from "../components/Trends/RankTrends";
import WikiSummaryBox from "../components/WikipediaTab";
import { AddToFranchiseModal } from "../components/modals/AddToFranchiseModal";
import { AddYoutubeTrailerModal } from "../components/modals/AddYoutubeTrailerModal";
import AttributesEditModal from "../components/modals/AttributesEditModal";
import AuditModal from "../components/modals/AuditModal";
import { CompareModal } from "../components/modals/CompareModal/CompareModal";
import { DeleteConfirmationModal } from "../components/modals/DeleteConfirmationModal";
import { TrackUnTrackConfirmationModal } from "../components/modals/TrackUnTrackConfirmationModal";
import SeasonsGrid from "./components/Seasons";
import SortableFilterableTable from "./components/SortableFilterableTable";
import { GoogleTitleInsights } from "../components/GoogleTitleInsights";
import { MdOutlinePlaylistAdd, MdOutlinePlaylistRemove } from "react-icons/md";
import { News } from "../components/News";
import GlobalScore from "../components/Scores/GlobalScore";
import { DateRange } from "../components/DateRange";

export default function ItemTelevisionSeries({
  itemData,
  onTrackItem,
  onUntrackItem,
  onDelete,
}) {
  const { user } = useAuthContext();
  const [isTracked, setIsTracked] = useState(false);
  const [showTrackUnTrackModal, setShowTrackUnTrackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddFranchiseModal, setShowAddFranchiseModal] = useState(false);
  const [showAddYoutubeTrailerModal, setShowAddYoutubeTrailerModal] =
    useState(false);
  const [showAttrModal, setShowAttrModal] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const onTrackUnTrackInit = () => setShowTrackUnTrackModal(true);
  const onTrackUnTrackClose = () => setShowTrackUnTrackModal(false);
  const onDeleteModalInit = () => setShowDeleteModal(true);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onAddFranchiseModalInit = () => setShowAddFranchiseModal(true);
  const onAddFranchiseModalClose = () => setShowAddFranchiseModal(false);
  const onShowAttrInit = () => setShowAttrModal(true);
  const onShowAttrClose = () => setShowAttrModal(false);
  const onShowAuditInit = () => setShowAuditModal(true);
  const onShowCompareModalInit = () => setShowCompareModal(true);
  const [showAuditModal, setShowAuditModal] = useState(false);
  const [triggerTrailersApi, setTriggerTrailersApi] = useState(true);

  const releaseDate = new Date(Date.parse(itemData.data.release_date));

  const [activeTabId, setActiveTabId] = useState(getQueryParams("tab") ?? "trends");
  const [trendsType, setTrendsType] = useState(getQueryParams("chartType") ?? "rank");
  const [dateRange, setDateRange] = useState({
    type: "absolute",
    startDate: calculateStartDate(releaseDate),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const allEpisodes = itemData?.data?.seasons?.reduce((acc, season) => {
    const seasonNumber = season.season_number ?? 0;
    const episodesWithSeason = (season.episodes ?? []).map((episode) => ({
      ...episode,
      season_number: seasonNumber,
      sortKey: `${seasonNumber.toString().padStart(3, "0")}${(episode.episode_number ?? 0).toString().padStart(3, "0")}`,
    }));
    return acc.concat(episodesWithSeason);
  }, []) ?? [];

  const episodeColumnDefinition = [
    {
      id: "sortKey",
      header: "Episode",
      cell: (item) => `S${item.season_number}E${item.episode_number}` || "N/A",
      sortingField: "sortKey",
    },
    {
      id: "name",
      header: "Title",
      cell: (item) => item.name || "N/A",
      sortingField: "name",
    },
    {
      id: "release_date",
      header: "Air Date",
      cell: (item) => {
        if (!item.release_date) return "N/A";
        const date = new Date(item.release_date);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
      },
      sortingField: "release_date",
    },
    {
      id: "overview",
      header: "Overview",
      minWidth: 300,
      maxWidth: 300,
      cell: (item) => {
        const truncatedSummary =
          item?.overview?.length > 100
            ? item?.overview?.substring(0, 100) + "..."
            : item.overview;

        return <span>{truncatedSummary}</span>;
      },
      sortingField: "overview",
    },
    {
      id: "imdb_vote_average",
      header: "IMDb Rating",
      cell: (item) => item.imdb_vote_average || "N/A",
      sortingField: "imdb_vote_average",
    },
    {
      id: "imdb_vote_count",
      header: "IMDb Votes",
      cell: (item) => item.imdb_vote_count || "N/A",
      sortingField: "imdb_vote_count",
    },
  ];

  useEffect(() => {
    if (itemData.data && itemData.data.tracked !== isTracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData, isTracked]);

  const checkIpList = (ip_list) => {
    return _.every(IP_LIST, ({ value }) => ip_list?.includes(value));
  };

  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";

  useEffect(() => {
    onShowAttrClose();
  }, [itemData]);

  const rtCriticSummary = itemData?.data?.rtCriticSummary;
  const rtFanSummary = itemData?.data?.rtFanSummary;
  const formattedDate = formatDate(releaseDate);
  const releaseYear = releaseDate.getFullYear();
  const newsBoxId = `${itemData?.data?.ip}+${releaseYear}+${itemData?.data?.vertical}`;

  const onSuccess = () => {
    setTriggerTrailersApi(true);
    setShowAddYoutubeTrailerModal(false);
  };
  const { mutate: addYoutubeVideos } = useAddYoutubeVideos(onSuccess);

  const handleTabChange = ({ detail }) => {
    setActiveTabId(detail.activeTabId);
    addQueryParams({ tab: detail.activeTabId });
  };

  return (
    <Container
      variant="borderless"
      disableContentPaddings
      disableHeaderPaddings
      header={
        <Header
          className="mb-4"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel="Track Title"
                variant="normal"
                iconSvg={<span><MdOutlinePlaylistAdd className="w-full h-full scale-125" /></span>}
                onClick={() => {
                  setAddTrack(true);
                  onTrackUnTrackInit();
                }}
                disabled={isAdmin ? itemData?.data?.ip_list?.length >= IP_LIST.length : true}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to track this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Untrack Title"
                variant="normal"
                iconSvg={<span><MdOutlinePlaylistRemove className="w-full h-full scale-125" /></span>}
                onClick={() => {
                  setAddTrack(false);
                  onTrackUnTrackInit();
                }}
                disabled={isAdmin ? !itemData?.data?.tracked : true}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to untrack this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Edit Attributes"
                variant="normal"
                iconName="edit"
                onClick={() => onShowAttrInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin ? "You don't have permission to edit tags." : ""
                }
              />
              <Button
                ariaLabel="Delete Title"
                iconName="remove"
                variant="normal"
                onClick={() => onDeleteModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to delete this title."
                    : ""
                }
              />
              <ButtonDropdown
                items={[
                  { text: "Add to franchise", id: "add-to-franchise", disabled: !isAdmin, disabledReason: "You don't have permission to add titles to franchises" },
                  { text: "Compare with other titles", id: "compare" },
                  { text: "View audit logs", id: "audit-logs" },
                ]}
                onItemClick={({ detail }) => {
                  switch (detail.id) {
                    case "add-to-franchise":
                      onAddFranchiseModalInit();
                      break;
                    case "compare":
                      onShowCompareModalInit();
                      break;
                    case "audit-logs":
                      onShowAuditInit();
                      break;
                  }
                }}
              >
                Actions
              </ButtonDropdown>
            </SpaceBetween>
          }
        >
          <div className="flex items-center">
            <div className="text-xl font-bold inline-block">
              {itemData.data.name}
            </div>
            <div className="inline-block whitespace-nowrap ml-2">
              {isTracked && (
                <div className="flex space-x-2">
                  {itemData.data?.ip_list?.length > 0 ? (
                    itemData.data.ip_list.map((ip) => (
                      <span
                        key={ip}
                        className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700"
                      >
                        <svg
                          className="h-1.5 w-1.5 fill-green-400"
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                        >
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        {ip}
                      </span>
                    ))
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/30">
                      Tracked
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="dark:text-slate-400 text-slate-600 text-sm font-normal">
            {[
              itemData.data.type.name,
              ...(itemData.data.release_date ? [formattedDate] : []),
              ...(itemData.data.number_of_seasons ? [`${itemData.data.number_of_seasons} ${itemData.data.number_of_seasons > 1 ? "Seasons" : "Season"}`] : []),
              ...(itemData.data.number_of_episodes ? [`${itemData.data.number_of_episodes} ${itemData.data.number_of_episodes > 1 ? "Episodes" : "Episode"}`] : []),
              ...(itemData.data.certificates?.rating ? [itemData.data.certificates.rating] : []),
              ...(itemData.data.countries ? [itemData.data.countries.map((country) => new Intl.DisplayNames(['en'], { type: 'region' }).of(country)).join(", ")] : []),
              ...(itemData.data.original_language ? [new Intl.DisplayNames(['en'], { type: 'language' }).of(itemData.data.original_language)] : []),
            ].join(" \u2022 ")}
          </div>
        </Header>
      }
    >
      <Container variant="borderless" disableContentPaddings>
        <div className="space-y-2">
          <div className="flex flex-col sm:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full rounded-md md:w-auto">
              {itemData.data.image_url ? (
                <div className="w-64 h-auto">
                  <img
                    src={`https://image.tmdb.org/t/p/w342/${itemData?.data?.image_url}`}
                    alt="..."
                    className="w-64 drop-shadow-lg h-min rounded-md"
                  />
                </div>
              ) : (
                <div className="w-64 h-full  flex items-center justify-center rounded-md bg-gray-400">
                  <span className="text-white font-extrabold text-4xl">?</span>
                </div>
              )}
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-col gap-1">
                <div className="mb-2">
                  <SpaceBetween direction="horizontal" size="xl">
                    {itemData.data?.global_rank?.pantheon && (
                      <GlobalScore
                        rank={itemData.data.global_rank?.pantheon?.weekly_rank}
                        rankChange={itemData.data.global_rank?.pantheon?.weekly_rank_change}
                        vertical={itemData.data.vertical}
                      />
                    )}
                  </SpaceBetween>
                </div>
                <div className="flex md:flex-row flex-col md:gap-3 items-start">
                  {itemData.data?.genre && itemData.data.genre.length > 0 && (
                    <TagList
                      title="Genres"
                      tagData={itemData.data.genre?.map((genre) => ({
                        label: genre,
                        color: "purple",
                      })) ?? []}
                    />
                  )}
                  {itemData.data?.sub_genres && itemData.data.sub_genres.length > 0 && (
                    <>
                      <div className="w-0 h-full border-r dark:border-slate-600 border-slate-400"></div>
                      <TagList
                        title="Sub-genres"
                        tagData={itemData.data.sub_genres?.map((genre) => ({
                          label: genre,
                          color: "purple",
                        })) ?? []}
                      />
                    </>
                  )}
                  {itemData.data.cTags && itemData.data.cTags.length > 0 && (
                    <>
                      <div className="w-0 h-full border-r dark:border-slate-600 border-slate-400"></div>
                      <TagList
                        title="Tags"
                        tagData={itemData.data.cTags.map((cTags) => ({
                          label: cTags,
                          color: "green",
                        }))}
                      />
                    </>
                  )}
                </div>
                {itemData.data.franchise &&
                  itemData.data.franchise.length > 0 && (
                    <TagList
                      title="Franchises"
                      tagData={itemData.data.franchise.map((franchise) => ({
                        label: franchise.name,
                        color: "green",
                        href: `/item/${franchise.id}`,
                      }))}
                    />
                  )}
                {itemData.data.production &&
                  itemData.data.production.length > 0 && (
                    <TagList
                      title="Production companies"
                      tagData={itemData.data.production.map((company) => ({
                        label: company.name,
                        color: "red",
                        href: `/item/companies-${company.id}`,
                      }))}
                    />
                  )}
                {itemData.data.providers && itemData.data.providers.length > 0 && <OTTProvider itemData={itemData} />}
              </div>
              <div className="pt-4">
                <SpaceBetween direction="horizontal" size="xl">
                  {!!rtCriticSummary?.score && (
                    <RottenTomatesCriticScore
                      rtCriticSummary={rtCriticSummary}
                    />
                  )}
                  {!!rtFanSummary?.score && (
                    <RottenTomatesAudienceScore rtFanSummary={rtFanSummary} />
                  )}
                  {!!itemData.data.imdb_vote_average && (
                    <IMDbScore
                      score={itemData.data.imdb_vote_average}
                      votes={itemData.data.imdb_vote_count}
                      id={itemData.data.imdb_id}
                    />
                  )}
                </SpaceBetween>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="flex space-x-2 mt-4">
        <div className="w-full ">
          <Tabs
            onChange={handleTabChange}
            activeTabId={activeTabId}
            tabs={[
              {
                label: "Trends",
                id: "trends",
                content: (
                  <SpaceBetween direction="vertical" size="m">
                    <Header
                      actions={
                        <SpaceBetween direction="horizontal" size="s">
                          <SegmentedControl
                            selectedId={trendsType}
                            onChange={({ detail }) => {
                              setTrendsType(detail.selectedId);
                              addQueryParams({ chartType: detail.selectedId });
                            }}
                            options={[
                              { id: "rank", text: "Rank trends" },
                              { id: "datapoint", text: "Datapoint trends" },
                            ]}
                          />
                          <DateRange dateRange={dateRange} setDateRange={setDateRange} />
                        </SpaceBetween>
                      }
                    >
                      
                    </Header>
                    <RankTrends
                      itemData={itemData}
                      dateRange={dateRange}
                      configType={trendsType === "rank" ? "basic" : "advanced"}
                    />
                  </SpaceBetween>
                ),
              },
              {
                label: "Google Search",
                id: "google",
                content: (
                  <GoogleTitleInsights
                    titleData={itemData.data}
                  />
                ),
              },
              {
                label: "Summary",
                id: "wiki",
                content: (
                  <WikiSummaryBox
                    id={itemData.data?.wikidata_id}
                    summary={itemData.data?.summary}
                    plotLong={itemData.data?.plotLong}
                  />
                ),
              },
              {
                label: "Cast & Crew",
                id: "CCT",
                content: (
                  <CastAndCrewComponent itemData={itemData.data} />
                ),
              },
              {
                label: "IDs",
                id: "tags",
                content: (
                  <ExpandableSection
                    defaultExpanded
                    headingTagOverride="h4"
                    variant="container"
                    headerText="IDs"
                  >
                    <TagsAndIDsComponent itemData={itemData.data} />
                  </ExpandableSection>
                ),
              },
              {
                label: "News",
                id: "news",
                content: (
                  <Container
                    header={
                      <Header>Recent News</Header>
                    }
                  >
                    <News 
                      ip={itemData.data.ip}
                      ip_id={itemData.data.ip_id} 
                    />
                  </Container>
                ),
              },
              {
                label: "Seasons",
                id: "seasons",
                content: itemData.data.seasons.length > 0 && (
                  <SeasonsGrid itemData={itemData.data.seasons} />
                ),
              },
              {
                label: "Episodes",
                id: "episodes",
                content: allEpisodes.length > 0 && (
                  <SortableFilterableTable
                    columnDefinitions={episodeColumnDefinition}
                    items={allEpisodes}
                    filterCountTextFn={getTextFilterCounterText}
                  />
                ),
              },
              {
                label: "Trailers",
                id: "Trailers",
                content: (
                  <TrailerGrid
                    id={itemData.data.ip_id}
                    triggerTrailersApi={triggerTrailersApi}
                    setTriggerTrailersApi={setTriggerTrailersApi}
                    videoId={undefined}
                    setShowAddYoutubeTrailerModal={
                      setShowAddYoutubeTrailerModal
                    }
                  />
                ),
              },
              ...(itemData.data.distributors &&
                itemData.data.distributors.length > 0
                ? [
                  {
                    label: "Distribution",
                    id: "PD",
                    content: (
                      <ProdnDistrib
                        distributors={itemData.data.distributors}
                      />
                    ),
                  },
                ]
                : []),
              ...(itemData.data.awards && itemData.data.awards.length > 0
                ? [
                  {
                    label: "Awards",
                    id: "awards",
                    content: (
                      <Container>
                        <AwardsTable itemData={itemData} />
                      </Container>
                    ),
                  },
                ]
                : []),
              {
                label: "Streaming Window",
                id: "tl",
                content: (
                  <SpaceBetween direction="vertical" size="xl">
                    <StreamingWindowComponent
                      titleId={itemData.data.imdb_id}
                      vertical={itemData.data.vertical}
                    />
                  </SpaceBetween>
                ),
              },
            ]}
          />
        </div>
      </div>
      <TrackUnTrackConfirmationModal
        visible={showTrackUnTrackModal}
        ipLists={IP_LIST}
        track={!addTrack}
        itemData={itemData?.data}
        ip_id={itemData?.data?.ip_id}
        defaultSelectedIps={itemData?.data?.ip_list}
        onDiscard={onTrackUnTrackClose}
        consentText={itemData?.data?.name}
        onConfirm={(item) => {
          if (item.track) {
            onUntrackItem(item);
            onTrackUnTrackClose();
          } else {
            onTrackItem(item);
            onTrackUnTrackClose();
          }
        }}
      />
      <DeleteConfirmationModal
        instances={[itemData?.data]}
        visible={showDeleteModal}
        onDiscard={onDeleteModalClose}
        onDelete={() => onDelete(itemData?.data?.ip_id)}
        consentText={itemData?.data?.name}
      />
      <AddToFranchiseModal
        visible={showAddFranchiseModal}
        onDiscard={onAddFranchiseModalClose}
        itemData={itemData}
      />
      <AddYoutubeTrailerModal
        visible={showAddYoutubeTrailerModal}
        onSubmit={(videoIds) => {
          addYoutubeVideos({
            videoIds,
            ip: itemData?.data?.ip,
            ipId: itemData?.data?.ip_id,
          });
        }}
        onDiscard={() => setShowAddYoutubeTrailerModal(false)}
      />
      <AttributesEditModal
        visible={showAttrModal}
        itemData={itemData?.data}
        onDismiss={() => setShowAttrModal(false)}
        editableAttributes={TAGS_LIST.map((x) => x.value).filter(
          (x) => !["youtubeChannel", "twitch", "discord", "steam"].includes(x),
        )}
      />
      <AuditModal
        visible={showAuditModal}
        ip_id={itemData?.data?.ip_id}
        onDiscard={() => setShowAuditModal(false)}
      />
      <CompareModal
        visible={showCompareModal}
        setVisible={setShowCompareModal}
        titleId={itemData?.data?.ip_id}
      />
    </Container>
  );
}