import React, { useMemo } from "react";
import { ColumnDefinition } from "../../../constants";

export const useGridConfig = ({
  columnDefinitions=[],
  sortBy=[],
}) => {
  const defaultColDef = {
    sortable: true,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100,
    suppressMovable: true,
  } as any;

  const columnDefs = useMemo<any>(() => {
    return columnDefinitions.map((col: ColumnDefinition) => {
      const colDef = {
        field: col.id,
        headerName: col.headerText,
        maxWidth: 300,
        sortable: false,
        valueFormatter: (params) => {
          if (params.value != null && !isNaN(+params.value) && typeof params.value !== "boolean") {
            return Math.round(params.value * 100.0) / 100.0;
          } else if (Array.isArray(params.value)) {
            return params.value.join(", ");
          } else {
            return params.value;
          }
        },
      } as any;

      const sortByColumn = sortBy.find((sort) => sort.column_id === col.id);
      if (sortByColumn) {
        colDef.sort = sortByColumn.order;
        colDef.sortable = true;
      }

      return colDef;
    });
  }, [columnDefinitions, sortBy]);

  return {
    defaultColDef,
    columnDefs,
  };
};