import React from "react";
import { Icon } from "@cloudscape-design/components";
import {
    DecreaseIcon,
    IncreaseIcon,
    NeutralIcon,
    NewIcon,
} from "../components/Icons";

const renderTrend = (params, rankField, changeField, trendField, renderBg=true) => {
    const rank = parseInt(params.data[rankField]);
    const trendChange = params.data.hasOwnProperty(changeField) && !isNaN(params.data[changeField]) ? parseInt(params.data[changeField]) : ""; 
    const trend = params.data[trendField];
    const trendIcons = {
        up: <IncreaseIcon />,
        down: <DecreaseIcon />,
        same: <NeutralIcon />,
        new: <NewIcon />
    };
    const trendIcon = trendIcons[trend] || null;
    const trendVariant = {
        up: "success",
        down: "error",
        same: "subtle",
        new: "success"
    }[trend];

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <span>{rank}</span>
            <span className="ml-2 inline-flex items-center justify-center rounded-md bg-slate-400/10 text-xs font-mono text-slate-400" style={{ width: "50px" }}>
                {trendIcon && <Icon svg={trendIcon} variant={trendVariant} />}
                <span style={{ width: "3px" }}></span>
                {trendChange}
            </span>
        </div>
    );
};

export const renderTrendSimple = (rank, rankChange, rankVisible=true) => {
    const trend = rankChange > 0 ? "up" : rankChange < 0 ? "down" : "same";
    
    const trendChange = !isNaN(rankChange) ? parseInt(rankChange) : ""; 
    const trendIcons = {
        up: <IncreaseIcon />,
        down: <DecreaseIcon />,
        same: <NeutralIcon />,
        new: <NewIcon />
    };
    const trendIcon = trendIcons[trend] || null;
    const trendVariant = {
        up: "success",
        down: "error",
        same: "subtle",
        new: "success"
    }[trend];

    return (
        <div className="flex items-center gap-x-2">
            {rankVisible && (<span>{rank.toLocaleString()}</span>)}
            <span className="inline-flex items-center justify-center rounded-md text-xs font-mono text-slate-200 gap-x-1">
                <span>{trendIcon && <Icon svg={trendIcon} variant={trendVariant} />}</span>
                <span>{trendChange}</span>
            </span>
        </div>
    );
};

export const formattedTrendBadge = (changeValue, formatter=((x) => x.toString())) => {
    const trend = changeValue >= 1 ? "up" : changeValue <= -1 ? "down" : "same";
    
    const trendChange = !isNaN(changeValue) ? formatter(changeValue) : ""; 
    const trendIcons = {
        up: <IncreaseIcon />,
        down: <DecreaseIcon />,
        same: <NeutralIcon />,
        new: <NewIcon />
    };
    const trendIcon = trendIcons[trend] || null;
    const trendVariant = {
        up: "success",
        down: "error",
        same: "subtle",
        new: "success"
    }[trend];

    return (
        <div className="flex items-center gap-x-2">
            <span className="inline-flex items-center justify-center rounded-md text-xs font-mono gap-x-1 bg-slate-400/10 px-2">
                <span>{trendIcon && <Icon svg={trendIcon} variant={trendVariant} />}</span>
                <span className="whitespace-nowrap">{trendChange}</span>
            </span>
        </div>
    );
};


export default renderTrend;