import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import CustomLoadingOverlay from "../../components/PantheonLoading";
import { ItemTypes } from "../../utils/itemUtils";
import { CompanyIndex } from "./pages/company";
import ItemFranchise from "./pages/franchise/franchise";
import { GameIndex } from "./pages/game";
import { MovieIndex } from "./pages/movie";
import { BooksIndex } from "./pages/books";
import { TelevisionIndex } from "./pages/television";
import { getItemData } from "./redux/actions/item-actions";
import { Layout } from "./Layout";
import { navItems, NavItemsWithId } from "../../layouts/common/menu/side-menu";
import { Vertical } from "../../types/verticalTypes";

const itemTypes = {
  [Vertical.Movies]: MovieIndex,
  [Vertical.Series]: TelevisionIndex,
  [Vertical.Games]: GameIndex,
  [Vertical.Franchises]: ItemFranchise,
  [Vertical.Books]: BooksIndex,
};

export default function Item() {
  const { id } = useParams();
  const flashbarRef = useRef(null);
  const itemData = useSelector((state: any) => state?.itemData);
  const itemDataLoading = useSelector((state: any) => state?.itemData?.loading);
  const itemDataHasError = useSelector((state: any) => state?.itemData?.hasError);
  const itemDataError = useSelector((state: any) => state?.itemData?.message);

  const ItemComponent = useMemo<React.FC<any>>(() => {
    return itemData?.data?.type?.id === ItemTypes.COMPANY ? CompanyIndex : (itemTypes[itemData?.data?.vertical] || null);
  }, [itemData]);

  useEffect(() => {
    getItemData(id, {});
  }, [id]);

  return (
    ItemComponent ? (
      <ItemComponent itemData={itemData} flashbarRef={flashbarRef} />
    ) : (
      <Layout
        flashbarRef={flashbarRef}
        title={itemData?.data?.name ?? "Item"}
        breadcrumbs={[]}
        navItems={navItems as NavItemsWithId}
        content={
          itemDataHasError ? (
            <div className="h-[60vh] flex items-center justify-center">
              {itemDataError === "Data not found:" ? (
                <div className="text-center">
                  <h1 className="text-2xl font-bold">404</h1>
                  <p className="text-md dark:text-slate-400 text-slate-600">The item could not be found.</p>
                </div>
              ) : (
                <div className="text-center">
                  <h1 className="text-2xl font-bold">Error</h1>
                  <p className="text-md dark:text-slate-400 text-slate-600">An error occurred while fetching the item data.</p>
                </div>
              )}
            </div>
          ) : itemDataLoading ? (
            <div className="h-[60vh] flex items-center justify-center">
              <CustomLoadingOverlay />
            </div>
          ) : null
        }
        contentType="dashboard"
      />
    )
  );
}
