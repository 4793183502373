import axiosInstance from "../../../../utils/axios";

export const getUserData = ({
  username,
}) => {
  if (!username || username?.length == 0) {
    return Promise.resolve({ data: null });
  }

  return axiosInstance.request({
    url: "/twitter/querytool/getuser",
    method: "GET",
    params: {
      user: username,
    },
  });
};

export const getTitlesData = ({
  offset,
  limit,
  filter,
}) => {

  const params = {} as any;
  if (offset) {
    params.offset = offset;
  }
  if (limit) {
    params.limit = limit;
  }

  const payload = {} as any;
  if (filter) {
    payload.filter = filter;
  }

  return axiosInstance.request({
    url: "/report/builder/titles",
    method: "POST",
    params: params,
    data: payload,
  });
};

export const getTitleListData = ({
  offset,
  limit,
  list,
}) => {

  const params = {} as any;
  if (offset) {
    params.offset = offset;
  }
  if (limit) {
    params.limit = limit;
  }

  return axiosInstance.request({
    url: `/report/builder/titlelists/${encodeURIComponent(list)}`,
    method: "GET",
    params: params,
  });
};

export const saveTitleListData = ({
  list,
  oldList,
}) => {

  const params = {} as any;
  if (oldList) {
    params.old_list = oldList;
  }

  return axiosInstance.request({
    url: `/report/builder/titlelists/${encodeURIComponent(list)}`,
    method: "POST",
    params: params,
  });
};

export const modifyTitleListData = ({
  list,
  source,
  action,
  filter,
  selectAll,
  toggledIndices,
}) => {

  const params = {} as any;
  if (source) {
    params.source = source;
  }
  if (action) {
    params.action = action;
  }

  const payload = {} as any;
  if (filter) {
    payload.filter = filter;
  }
  if (selectAll) {
    payload.selectAll = selectAll;
  }
  if (toggledIndices) {
    payload.toggledIndices = toggledIndices;
  }

  return axiosInstance.request({
    url: `/report/builder/titlelists/${encodeURIComponent(list)}`,
    method: "PATCH",
    params: params,
    data: payload,
  });
};

export const deleteTitleListData = ({
  list,
}) => {
  return axiosInstance.request({
    url: `/report/builder/titlelists/${encodeURIComponent(list)}`,
    method: "DELETE",
  });
};

export const getSavedTitleListsData = () => {
  return axiosInstance.request({
    url: `/report/builder/titlelists`,
    method: "GET",
  });
};

export const listReports = (parameters) => {
  return axiosInstance.request({
    url: "/report/builder/jobs",
    method: "GET",
    params: parameters,
  });
};

export const startReport = ({
  reportDefinition,
}) => {

  const payload = {
    report_definition: reportDefinition,
  } as any;

  return axiosInstance.request({
    url: "/report/builder/jobs",
    method: "POST",
    data: payload,
  });
};

export const getReport = ({
  id,
}) => {
  return axiosInstance.request({
    url: `/report/builder/jobs/${id}`,
    method: "GET",
  });
};

export const deleteReport = ({
  id,
}) => {
  return axiosInstance.request({
    url: `/report/builder/jobs/${id}`,
    method: "DELETE",
  });
};

export const updateReport = ({
  id,
  action,
  ...params
}) => {
  return axiosInstance.request({
    url: `/report/builder/jobs/${id}/${action}`,
    method: "PATCH",
    params: params,
  });
};

export const getReportResults = ({
  id,
  offset=null,
  limit=null,
  download=null,
}) => {

  const params = {} as any;
  if (offset) {
    params.offset = offset;
  }
  if (limit) {
    params.limit = limit;
  }
  if (download) {
    params.download = download;
  }

  return axiosInstance.request({
    url: `/report/builder/jobs/${id}/results`,
    method: "GET",
    params,
  });
};