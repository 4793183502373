import {
  Box,
  Button,
  FormField,
  Modal,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC, useState } from "react";

interface AddYoutubeTrailerModalProps {
  visible?: boolean;
  onDiscard?: () => void;
  onSubmit?: (videoIds: string[]) => void;
}

export const AddYoutubeTrailerModal: FC<AddYoutubeTrailerModalProps> = ({
  visible = false,
  onDiscard = () => {},
  onSubmit = () => {},
}) => {
  const videoIdPattern =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/g;
  const [url, setUrl] = useState("");
  const [error, setError] = useState(null);

  const handleUrlChange = (e) => setUrl(e.detail.value);

  const handleAddYoutubeTrailer = async () => {
    setError(null);
    const extractedVideoIds = [];
    let match;

    while ((match = videoIdPattern.exec(url)) !== null) {
      extractedVideoIds.push(match[1]);
    }

    if (extractedVideoIds.length === 0) {
      setError("Invalid YouTube URL");
      return;
    }

    onSubmit(extractedVideoIds);
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header={"Add YouTube Trailer"}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={(e) => {
                e.preventDefault();
                onDiscard();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddYoutubeTrailer} disabled={url.length === 0}>
              Add
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <FormField label="Trailer URL" stretch errorText={error ? error : ""}>
        <Input
          type="text"
          placeholder="https://www.youtube.com/watch?v=..."
          value={url}
          onChange={handleUrlChange}
        />
      </FormField>
    </Modal>
  );
};
