import { useMemo } from "react";
import { getFilterConditionsFromFilters, sanitizeFilterObject } from "../../../utils/filters/filterUtils";
import { FilterObject } from "../../../types/filterTypes";

interface UseFilterQueryProps {
  filterObject: FilterObject;
  treatAsDefault?: Record<string, string>;
}

export const useFilterQuery = ({ 
  filterObject,
  treatAsDefault={},
}: UseFilterQueryProps) => {
  const filterQuery = useMemo(() => {
    const sanitizedFilterObject = sanitizeFilterObject(filterObject);

    const mustConditions = [];

    // Table advanced filters
    if (sanitizedFilterObject.advancedFilter) {
      mustConditions.push(filterObject.advancedFilter);
    }

    // Filters drawer filters
    const filterConditions = getFilterConditionsFromFilters(
      sanitizedFilterObject.filters,
    );
    if (filterConditions.length > 0) {
      filterConditions.filter((c) => !(c.colId in treatAsDefault && treatAsDefault[c.colId] === c.filter)).forEach((condition) => mustConditions.push(condition));
    }

    if (mustConditions.length === 0) {
      return {};
    } else if (mustConditions.length === 1) {
      return mustConditions[0];
    } else {
      return {
        filterType: "join",
        type: "AND",
        conditions: mustConditions,
      };
    }
  }, [filterObject]);

  return filterQuery;
};