import {
  Button,
  ButtonDropdown,
  Container,
  ContentLayout,
  ExpandableSection,
  Header,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { MessageBox } from "../../../../components/MessageBox";
import ReadMore from "../../../../components/ReadMore";
import { TabActions } from "../../../../components/TabActions";
import { VerticalIcon } from "../../../../components/VerticalIcon";
import { SERVICES, TYPES } from "../../../../config-global";
import { navItems } from "../../../../layouts/common/menu/side-menu";
import { useGetGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { getFilterConditionsFromFilters } from "../../../../utils/filters/filterUtils";
import { CHART_LABELS } from "../../../platforms/hooks/useGridConfig";
import {
  CATEGORIES,
  getCategories,
} from "../../../platforms/utils/categoryUtils";
import {
  DEFAULT_FILTER_OBJECTS,
  DEFAULT_WEIGHTS_OBJECTS,
} from "../../../ranking/global-ranking/constants";
import { BasicParameters } from "../../../ranking/global-ranking/pantheon-ranking/components/BasicParameters";
import { DataTable } from "../../../ranking/global-ranking/pantheon-ranking/components/DataTable/DataTable";
import { GRPantheonRanking } from "../../../ranking/global-ranking/pantheon-ranking/components/GRPantheonRanking";
import { weightsObjectToWeightageValues } from "../../../ranking/global-ranking/pantheon-ranking/utils";
import { useFranchiseActions } from "../../hooks/useFranchiseActions.ts";
import { Layout } from "../../Layout";
import {
  getItemData,
  updateFranchiseCoverImageAction,
} from "../../redux/actions/item-actions";
import AttributesEditModal from "../components/modals/AttributesEditModal";
import TagsAndIDsComponent from "../components/TagsAndIDsComponent";
import { CompareContainer } from "./components/CompareContainer";
import { AddTitlesToFranchiseModal } from "./components/modals/AddTitlesToFranchiseModal";
import { FranchiseEditModal } from "./components/modals/FranchiseEditModal";
import { MergeFranchisesModal } from "./components/modals/MergeFranchisesModal";
import { RemoveTitlesFromFranchiseModal } from "./components/modals/RemoveTitlesFromFranchiseModal";
import { useGetBreadcrumbs } from "./hooks/useGetBreadcrumbs";
import { Vertical, verticalOptions, Verticals } from "../../../../types/verticalTypes";

const additionalColumns = {
  [Vertical.Movies]: Object.values(CHART_LABELS).filter(
    (label) =>
      !label.toLowerCase().includes("trend") &&
      getCategories(label)?.includes(CATEGORIES.MOVIES),
  ),
  [Vertical.Series]: Object.values(CHART_LABELS).filter(
    (label) =>
      !label.toLowerCase().includes("trend") &&
      getCategories(label)?.includes(CATEGORIES.SERIES),
  ),
  [Vertical.Games]: Object.values(CHART_LABELS).filter(
    (label) =>
      !label.toLowerCase().includes("trend") &&
      getCategories(label)?.includes(CATEGORIES.GAMING),
  ),
};

const sortOptions = [
  { label: "Top", value: "score" },
  { label: "Rising", value: "score_change" },
];

const ItemFranchise = ({ itemData, flashbarRef }) => {
  const apiParams = {
    type: TYPES.PERSONAL,
    service: SERVICES.EXPLORE,
    module: "franchise",
  };

  const { data: preferenceData } = useGetGenericPreference_V2({ apiParams });
  const { user } = useAuthContext();
  const gridRefs = useRef({});
  const tileRefs = useRef({});

  const [searchParams, setSearchParams] = useSearchParams();
  const filterDrawerRef = useRef(null);
  const messageBoxRef = useRef(null);

  const titleCounts = useMemo(
    () => ({
      [Vertical.Movies]: itemData.data.items.filter(
        (item) => item.vertical === Vertical.Movies,
      ).length,
      [Vertical.Series]: itemData.data.items.filter(
        (item) => item.vertical === Vertical.Series,
      ).length,
      [Vertical.Games]: itemData.data.items.filter(
        (item) => item.vertical === Vertical.Games,
      ).length,
    }),
    [itemData],
  );

  const [selectedVertical, setSelectedVertical] = useState(
    verticalOptions.find(
      (option) =>
        option.value ===
        Object.keys(titleCounts).reduce((a, b) =>
          titleCounts[a] > titleCounts[b] ? a : b,
        ),
    ),
  );
  const [activeTabId, setActiveTabId] = useState(
    searchParams.get("tab") ?? "tile",
  );
  const [verticalSelectedIpIds, setVerticalSelectedIpIds] = useState({});
  const [initialVerticalSelectedIpIds, setInitialVerticalSelectedIpIds] =
    useState({});
  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const [dateRange, setDateRange] = useState({
    type: "absolute",
    startDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [filterObject, setFilterObject] = useState(
    DEFAULT_FILTER_OBJECTS[selectedVertical.value],
  );
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState(
    [],
  );
  const [overrideTitleCounts, setOverrideTitleCounts] = useState({});
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  const [attrModalVisible, setAttrModalVisible] = useState(false);
  const [addTitlesModalVisible, setAddTitlesModalVisible] = useState(false);
  const [removeTitlesModalVisible, setRemoveTitlesModalVisible] =
    useState(false);
  const [mergeModalVisible, setMergeModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const title = useMemo(() => itemData?.data?.name ?? "Franchise", [itemData]);

  const { breadcrumbs } = useGetBreadcrumbs(itemData);
  const {
    updateFranchiseCards,
    mergeFranchise,
    updateFranchise,
    deleteFranchise,
  } = useFranchiseActions();

  const filterQuery = useMemo(() => {
    const mustConditions = [];

    // Quick title search
    if (
      filterObject.titleSearchQuery &&
      filterObject.titleSearchQuery.length > 0
    ) {
      mustConditions.push({
        filterType: "text",
        colId: "ip",
        type: "contains",
        filter: filterObject.titleSearchQuery,
      });
    }

    // Table advanced filters
    if (filterObject.advancedFilter) {
      mustConditions.push(filterObject.advancedFilter);
    }

    // Filters drawer filters
    const filterConditions = getFilterConditionsFromFilters(
      filterObject.filters,
    );
    if (filterConditions.length > 0) {
      filterConditions.forEach((condition) => mustConditions.push(condition));
    }

    mustConditions.push({
      filterType: "object",
      colId: "franchise",
      type: "contains",
      filter: itemData.data.name.replaceAll(",", "\\,"),
    });

    if (mustConditions.length === 0) {
      return {};
    } else if (mustConditions.length === 1) {
      setOverrideTitleCounts({});
      return mustConditions[0];
    } else {
      return {
        filterType: "join",
        type: "AND",
        conditions: mustConditions,
      };
    }
  }, [filterObject, itemData]);

  const handleSetVerticalSelectedIpIds = (vertical, ids) => {
    setVerticalSelectedIpIds((prev) => ({ ...prev, [vertical]: ids }));
  };

  const rankingTiles = useMemo(() => {
    const newDataTables = {};
    Object.values(Verticals).forEach((vertical) => {
      newDataTables[vertical] = (
        <GRPantheonRanking
          ref={(ref) => (tileRefs.current[vertical] = ref)}
          vertical={vertical}
          selectedIpIds={initialVerticalSelectedIpIds[vertical] ?? []}
          setSelectedIpIds={(ids) =>
            handleSetVerticalSelectedIpIds(vertical, ids)
          }
          setSplitPanelStatus={setSplitPanelStatus}
          filterQuery={filterQuery}
          weightageValues={weightsObjectToWeightageValues(
            DEFAULT_WEIGHTS_OBJECTS[vertical],
          )}
          dateRange={dateRange}
          useInfiniteScroll={false}
          onItemCountSet={(count) =>
            setOverrideTitleCounts((prev) => ({ ...prev, [vertical]: count }))
          }
          sortField={selectedSortOption.value}
        />
      );
    });
    return newDataTables;
  }, [
    initialVerticalSelectedIpIds,
    filterQuery,
    dateRange,
    selectedSortOption,
  ]);

  // Memoized just so the onRowCountSet prop doesn't trigger a re-render
  const rankingTables = useMemo(() => {
    const newDataTables = {};
    Object.values(Verticals).forEach((vertical) => {
      newDataTables[vertical] = (
        <DataTable
          ref={(ref) => (gridRefs.current[vertical] = ref)}
          vertical={vertical}
          selectedIpIds={initialVerticalSelectedIpIds[vertical] ?? []}
          setSelectedIpIds={(ids) => {
            handleSetVerticalSelectedIpIds(vertical, ids);
          }}
          setSplitPanelStatus={setSplitPanelStatus}
          filterQuery={filterQuery}
          weightageValues={weightsObjectToWeightageValues(
            DEFAULT_WEIGHTS_OBJECTS[vertical],
          )}
          dateRange={dateRange}
          advancedFilter={filterObject.advancedFilter}
          setAdvancedFilter={(value) =>
            setFilterObject({ ...filterObject, advancedFilter: value })
          }
          additionalColumns={additionalColumns[vertical]}
          selectedAdditionalColumns={selectedAdditionalColumns}
          showLegend={false}
          autoHeight={true}
          showAdvancedFilter={false}
          usePagination={false}
          onRowCountSet={(count) =>
            setOverrideTitleCounts((prev) => ({ ...prev, [vertical]: count }))
          }
          sortField={selectedSortOption.value}
        />
      );
    });
    return newDataTables;
  }, [
    initialVerticalSelectedIpIds,
    filterQuery,
    dateRange,
    filterObject,
    selectedAdditionalColumns,
    selectedSortOption,
  ]);

  const getTab = ({ id, label }) => {
    return {
      label: label,
      id: id,
      action: <TabActions tabId={id} preferencesApiParams={apiParams} />,
    };
  };

  const noImage = require("../../../../assets/images/icons/no-image_342.jpg");
  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";

  useEffect(() => {
    const pref = preferenceData?.[0]?.data;
    if (pref) {
      if (pref.primaryTab && !searchParams.get("tab")) {
        setActiveTabId(pref.primaryTab);
      }
    }
  }, [preferenceData, searchParams]);

  useEffect(() => {
    setFilterObject(DEFAULT_FILTER_OBJECTS[selectedVertical.value]);
    filterDrawerRef.current?.setCurrentFilterObject(
      DEFAULT_FILTER_OBJECTS[selectedVertical.value],
    );
  }, [selectedVertical, filterDrawerRef]);

  const onFranchiseUpdateCoverImage = (id) => {
    updateFranchiseCoverImageAction(id).then(() => getItemData(id, {}));
  };

  const handleOnItemClick = ({ detail }) => {
    switch (detail.id) {
      case "associate-titles":
        setAddTitlesModalVisible(true);
        break;
      case "disassociate-titles":
        setRemoveTitlesModalVisible(true);
        break;
      case "refresh-cover":
        onFranchiseUpdateCoverImage(itemData?.data?.ip_id);
        break;
      case "edit-tags":
        setAttrModalVisible(true);
        break;
      case "edit-franchise":
        setEditModalVisible(true);
        break;
      case "merge-franchise":
        setMergeModalVisible(true);
        break;
      case "delete-franchise":
        messageBoxRef.current.open({
          headerText: "Delete franchise?",
          messageBody: (
            <div>
              Are you sure you want to delete the franchise{" "}
              <b>{itemData.data.name}</b>?
            </div>
          ),
          primaryButtonText: "Yes",
          secondaryButtonText: "No",
          onPrimaryButtonClick: () => {
            deleteFranchise([itemData?.data?.ip_id]);
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Layout
        flashbarRef={flashbarRef}
        title={title}
        breadcrumbs={breadcrumbs}
        drawers={
          [
            /*filterDrawerConfig*/
          ]
        }
        navItems={navItems}
        content={
          <ContentLayout
            disableOverlap
            disableContentPaddings
            header={
              <Container
                variant="borderless"
                disableContentPaddings
                disableHeaderPaddings
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          ariaLabel="Associate Titles"
                          variant="normal"
                          iconName="add-plus"
                          onClick={() => handleOnItemClick({ detail: { id: "associate-titles" } })}
                          disabled={false}
                          disabledReason={"You don't have permission to add titles"}
                        />
                        <Button
                          ariaLabel="Disassociate Titles"
                          iconName="subtract-minus"
                          variant="normal"
                          onClick={() => handleOnItemClick({ detail: { id: "disassociate-titles" } })}
                          disabled={false}
                          disabledReason={"You don't have permission to remove titles"}
                        />
                        <Button
                          ariaLabel="Edit Tags"
                          variant="normal"
                          iconName="edit"
                          onClick={() => handleOnItemClick({ detail: { id: "edit-tags" } })}
                          disabled={!isAdmin}
                          disabledReason={"You don't have permission to edit tags"}
                        />
                        <Button
                          ariaLabel="Delete Title"
                          iconName="remove"
                          variant="normal"
                          onClick={() => handleOnItemClick({ detail: { id: "delete-franchise" } })}
                          disabled={!isAdmin}
                          disabledReason={"You don't have permission to delete this franchise"}
                        />
                        <ButtonDropdown
                          expandToViewport
                          items={[
                            /*{ text: "Associate titles", id: "associate-titles" },
                            {
                              text: "Disassociate titles",
                              id: "disassociate-titles",
                            },
                            {
                              text: "Refresh cover image",
                              id: "refresh-cover",
                              disabled: itemData?.data?.total_ips <= 0,
                            },*/
                            /*{
                              text: "Edit tags",
                              id: "edit-tags",
                              disabled: !isAdmin,
                            },*/
                            { text: "Edit franchise details", id: "edit-franchise" },
                            { text: "Merge franchise", id: "merge-franchise" },
                            /* { text: "Delete franchise", id: "delete-franchise" }, */
                          ]}
                          onItemClick={handleOnItemClick}
                        >
                          Actions
                        </ButtonDropdown>
                      </SpaceBetween>
                    }
                  >
                    <div className="text-xl font-bold">{title}</div>
                    <div className="text-sm text-slate-600 dark:text-slate-400 flex gap-1 font-normal">
                      {[
                        "Franchise",
                        moment(itemData.data.first_date).format("YYYY") +
                          " - " +
                          moment(itemData.data.last_date).format("YYYY"),
                        ...(titleCounts[Vertical.Movies] > 0
                          ? [
                              <VerticalIcon
                                vertical={Vertical.Movies}
                                titleCount={titleCounts[Vertical.Movies]}
                              />,
                            ]
                          : []),
                        ...(titleCounts[Vertical.Series] > 0
                          ? [
                              <VerticalIcon
                                vertical={Vertical.Series}
                                titleCount={titleCounts[Vertical.Series]}
                              />,
                            ]
                          : []),
                        ...(titleCounts[Vertical.Games] > 0
                          ? [
                              <VerticalIcon
                                vertical={Vertical.Games}
                                titleCount={titleCounts[Vertical.Games]}
                              />,
                            ]
                          : []),
                      ].map((element, index) => [
                        index > 0 && " \u2022 ",
                        <span key={index}>{element}</span>,
                      ])}
                    </div>
                  </Header>
                }
              >
                <div className="flex justify-between">
                  <div className="flex gap-4 py-1">
                    <img
                      className="h-28 rounded-md"
                      src={`https://images.searchpantheon.com/collections/${itemData.data.ip_id}_684.jpg`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = noImage;
                      }}
                      alt="Franchise cover"
                    />
                    <div className="flex flex-col">
                      <b>Overview</b>
                      <div className="text-sm font-normal">
                        <ReadMore
                          text={itemData.data.summary}
                          maxLength={500}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            }
          >
            <SpaceBetween direction="vertical" size="m">
              <Tabs
                disableContentPaddings
                onChange={({ detail }) => {
                  setActiveTabId(detail.activeTabId);
                  setSearchParams({ tab: detail.activeTabId });
                  setInitialVerticalSelectedIpIds(verticalSelectedIpIds);
                }}
                activeTabId={activeTabId}
                tabs={[
                  getTab({ id: "tile", label: "Titles - Tile View" }),
                  getTab({ id: "table", label: "Titles - Table View" }),
                  getTab({ id: "ids", label: "IDs" }),
                ]}
              />
              {(activeTabId === "tile" || activeTabId === "table") && (
                <BasicParameters
                  dateRange={dateRange}
                  setDateRange={(dateRange) => {
                    setDateRange(dateRange);
                    setInitialVerticalSelectedIpIds(verticalSelectedIpIds);
                  }}
                  titleSearchQuery={filterObject.titleSearchQuery}
                  setTitleSearchQuery={(value) =>
                    setFilterObject({
                      ...filterObject,
                      titleSearchQuery: value,
                    })
                  }
                  showAdvancedFilter={false}
                  additionalFilters={
                    <>
                      {/*<Select
                        options={sortOptions}
                        selectedOption={selectedSortOption}
                        onChange={({ detail }) => setSelectedSortOption(detail.selectedOption)}
                      />*/}
                      {/*activeTabId === "table" ? (
                        <Multiselect
                          options={additionalColumnsOptions}
                          selectedOptions={selectedAdditionalColumns}
                          onChange={({ detail }) => setSelectedAdditionalColumns([...detail.selectedOptions])}
                          placeholder="Additional columns"
                          hideTokens
                        />
                      ) : null*/}
                      {/*<Select 
                        options={verticalOptions}
                        selectedOption={selectedVertical}
                        onChange={({ detail }) => setSelectedVertical(detail.selectedOption)}
                      />*/}
                    </>
                  }
                  loading={false}
                />
              )}
              {activeTabId === "tile" ? (
                <div className="flex flex-col gap-4">
                  {[
                    { vertical: Vertical.Movies, headerText: "Movies" },
                    { vertical: Vertical.Series, headerText: "Series" },
                    { vertical: Vertical.Games, headerText: "Video Games" },
                  ]
                    .sort(
                      (a, b) =>
                        titleCounts[b.vertical] - titleCounts[a.vertical],
                    )
                    .filter(
                      (x) =>
                        titleCounts[x.vertical] > 0 &&
                        overrideTitleCounts[x.vertical] !== 0,
                    )
                    .map((item) => (
                      <ExpandableSection
                        key={item.vertical}
                        headerText={`${item.headerText} (${
                          overrideTitleCounts[item.vertical] != null &&
                          overrideTitleCounts[item.vertical] !=
                            titleCounts[item.vertical]
                            ? (overrideTitleCounts[item.vertical] === -1
                                ? "?"
                                : overrideTitleCounts[item.vertical]) +
                              " of " +
                              titleCounts[item.vertical]
                            : titleCounts[item.vertical]
                        })`}
                        defaultExpanded
                      >
                        {rankingTiles[item.vertical]}
                      </ExpandableSection>
                    ))}
                </div>
              ) : activeTabId === "table" ? (
                <div className="flex flex-col gap-4">
                  {[
                    { vertical: Vertical.Movies, headerText: "Movies" },
                    { vertical: Vertical.Series, headerText: "Series" },
                    { vertical: Vertical.Games, headerText: "Video Games" },
                  ]
                    .sort(
                      (a, b) =>
                        titleCounts[b.vertical] - titleCounts[a.vertical],
                    )
                    .filter(
                      (x) =>
                        titleCounts[x.vertical] > 0 &&
                        overrideTitleCounts[x.vertical] !== 0,
                    )
                    .map((item) => (
                      <div>
                        <ExpandableSection
                          key={item.vertical}
                          headerText={`${item.headerText} (${
                            overrideTitleCounts[item.vertical] != null &&
                            overrideTitleCounts[item.vertical] !=
                              titleCounts[item.vertical]
                              ? (overrideTitleCounts[item.vertical] === -1
                                  ? "?"
                                  : overrideTitleCounts[item.vertical]) +
                                " of " +
                                titleCounts[item.vertical]
                              : titleCounts[item.vertical]
                          })`}
                          defaultExpanded
                        >
                          {rankingTables[item.vertical]}
                        </ExpandableSection>
                      </div>
                    ))}
                </div>
              ) : activeTabId === "ids" ? (
                <div className="flex flex-col gap-2">
                  <Header
                    actions={
                      <Button
                        iconName="edit"
                        onClick={() => setAttrModalVisible(true)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    IDs
                  </Header>
                  <TagsAndIDsComponent itemData={itemData.data} />
                </div>
              ) : null}
            </SpaceBetween>
          </ContentLayout>
        }
        contentType="table"
        splitPanel={
          <CompareContainer
            ipIds={Object.values(verticalSelectedIpIds).flat()}
            dateRange={dateRange}
            onParametersChanged={(parameters) => {
              if (
                !parameters ||
                parameters.length === 0 ||
                !parameters[0]?.titleIds
              ) {
                return;
              }
              const newTitleIds = parameters[0].titleIds;
              if (
                !_.isEqual(
                  newTitleIds.sort(),
                  Object.values(verticalSelectedIpIds).flat().sort(),
                )
              ) {
                const prefixes = {
                  [Vertical.Movies]: "film",
                  [Vertical.Series]: "series",
                  [Vertical.Games]: "game",
                };
                const newVerticalSelectedIpIds = {};
                Object.keys(prefixes).map((vertical) => {
                  newVerticalSelectedIpIds[vertical] = newTitleIds.filter(
                    (id) => id.startsWith(prefixes[vertical]),
                  );
                });
                setVerticalSelectedIpIds(newVerticalSelectedIpIds);
                if (gridRefs.current) {
                  Object.keys(gridRefs.current).forEach((vertical) => {
                    gridRefs.current[vertical]?.api?.forEachNode((node) => {
                      if (
                        newVerticalSelectedIpIds[vertical].includes(
                          node?.data?.ip_id,
                        )
                      ) {
                        node.setSelected(true);
                      } else {
                        node.setSelected(false);
                      }
                    });
                    gridRefs.current[vertical]?.setInnerSelectedIpIds(
                      newVerticalSelectedIpIds[vertical],
                    );
                  });
                }
                if (tileRefs.current) {
                  Object.keys(tileRefs.current).forEach((vertical) => {
                    tileRefs.current[vertical]?.setInnerSelectedIpIds(
                      newVerticalSelectedIpIds[vertical],
                    );
                  });
                }
              }
            }}
          />
        }
        splitPanelStatus={splitPanelStatus}
        setSplitPanelStatus={setSplitPanelStatus}
      />
      <MessageBox ref={messageBoxRef} />
      <AttributesEditModal
        itemData={itemData.data}
        visible={attrModalVisible}
        onDismiss={() => setAttrModalVisible(false)}
        editableAttributes={[
          "wikipedia",
          "trends",
          "fandom",
          "a03",
          "subreddit",
          "twitter",
          "youtubeChannel",
          "wattpad",
          "instagram",
          "tiktok"
        ]}
      />
      <AddTitlesToFranchiseModal
        visible={addTitlesModalVisible}
        onDiscard={() => setAddTitlesModalVisible(false)}
        onSubmit={(items) => {
          updateFranchiseCards({
            id: itemData?.data?.ip_id,
            name: itemData?.data?.name,
            removed: [],
            added: items,
          });
          //setAddTitlesModalVisible(false);
        }}
        excludeIpIds={
          itemData?.data?.items
            ? itemData?.data?.items.map((item) => item.ip_id)
            : []
        }
      />
      <RemoveTitlesFromFranchiseModal
        visible={removeTitlesModalVisible}
        onDiscard={() => setRemoveTitlesModalVisible(false)}
        onSubmit={(items) => {
          updateFranchiseCards({
            id: itemData?.data?.ip_id,
            name: itemData?.data?.name,
            removed: items,
            added: [],
          });
          //setRemoveTitlesModalVisible(false);
        }}
        titles={
          itemData?.data?.items
            ? [...itemData?.data?.items].sort((a, b) =>
                a.ip.toString().localeCompare(b.ip.toString()),
              )
            : []
        }
      />
      <FranchiseEditModal
        visible={editModalVisible}
        onDiscard={() => setEditModalVisible(false)}
        onSubmit={(e) => {
          updateFranchise(e);
          setEditModalVisible(false);
        }}
        title={itemData?.data?.name || ""}
        description={itemData?.data?.summary || ""}
      />
      <MergeFranchisesModal
        visible={mergeModalVisible}
        onDiscard={() => setMergeModalVisible(false)}
        onSubmit={(items) => {
          if (items) {
            mergeFranchise({
              source: {
                id: items?.name,
                name: items?.id,
              },
              destination: {
                id: itemData?.data?.ip_id,
                name: itemData?.data?.name,
              },
            });
          }
          setMergeModalVisible(false);
        }}
        ip={itemData?.data?.name}
        ip_id={itemData?.data?.ip_id}
      />
    </>
  );
};

export default ItemFranchise;
