import {
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ExpandableSection,
  Grid,
  Header,
  Link,
  SegmentedControl,
  SpaceBetween,
  Table,
  Tabs,
  TextContent,
} from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { IP_LIST, TAGS_LIST } from "../../../../config-global";
import { formatDate } from "../../../../utils/dateFormatter";
import { addQueryParams, getQueryParams } from "../../../../utils/queryUtils";
import { useAddYoutubeVideos } from "../../api/hooks/useAddYoutubeVideos";
import { calculateStartDate, isDataAvailable } from "../../utils";
import { DateRange } from "../components/DateRange";
import { News } from "../components/News";
import TagList from "../components/TagList";
import TagsAndIDsComponent from "../components/TagsAndIDsComponent";
import TrailerGrid from "../components/Trailer/Trailer";
import { RankTrends } from "../components/Trends/RankTrends";
import WikiSummaryBox from "../components/WikipediaTab";
import { AddToFranchiseModal } from "../components/modals/AddToFranchiseModal";
import { AddYoutubeTrailerModal } from "../components/modals/AddYoutubeTrailerModal";
import AttributesEditModal from "../components/modals/AttributesEditModal";
import AuditModal from "../components/modals/AuditModal";
import { CompareModal } from "../components/modals/CompareModal/CompareModal";
import { DeleteConfirmationModal } from "../components/modals/DeleteConfirmationModal";
import { TrackUnTrackConfirmationModal } from "../components/modals/TrackUnTrackConfirmationModal";
import AddOnsComponent from "./components/AddOnGames";
import {
  MetacriticScore,
  MetacriticUserScore,
  SteamReviews,
} from "./components/GamingScores";
import { GoogleTitleInsights } from "../components/GoogleTitleInsights";
import { MdOutlinePlaylistAdd, MdOutlinePlaylistRemove } from "react-icons/md";
import GlobalScore from "../components/Scores/GlobalScore";

export default function ItemVideoGame({
  itemData,
  onTrackItem,
  onUntrackItem,
  onDelete,
}) {
  const { user } = useAuthContext();
  const [isTracked, setIsTracked] = useState(false);
  const [showTrackUnTrackModal, setShowTrackUnTrackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddFranchiseModal, setShowAddFranchiseModal] = useState(false);
  const [showAuditModal, setShowAuditModal] = useState(false);
  const onShowAuditInit = () => setShowAuditModal(true);
  const [showAddYoutubeTrailerModal, setShowAddYoutubeTrailerModal] =
    useState(false);
  const [showAttrModal, setShowAttrModal] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const onTrackUnTrackInit = () => setShowTrackUnTrackModal(true);
  const onTrackUnTrackClose = () => setShowTrackUnTrackModal(false);
  const onDeleteModalInit = () => setShowDeleteModal(true);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onAddFranchiseModalInit = () => setShowAddFranchiseModal(true);
  const onAddFranchiseModalClose = () => setShowAddFranchiseModal(false);
  const onShowCompareModalInit = () => setShowCompareModal(true);
  const onShowAttrInit = () => setShowAttrModal(true);
  const onShowAttrClose = () => setShowAttrModal(false);
  const [triggerTrailersApi, setTriggerTrailersApi] = useState(true);

  const releaseDate = new Date(Date.parse(itemData.data.release_date));
  const year = new Date(releaseDate).getFullYear();

  const [activeTabId, setActiveTabId] = useState(getQueryParams("tab") ?? "trends");
  const [trendsType, setTrendsType] = useState(getQueryParams("chartType") ?? "rank");
  const [dateRange, setDateRange] = useState({
    type: "absolute",
    startDate: calculateStartDate(releaseDate),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const categoryNames = {
    1: "Official",
    2: "Wikia",
    3: "Wikipedia",
    4: "Facebook",
    5: "Twitter",
    6: "Twitch",
    8: "Instagram",
    9: "YouTube",
    10: "iPhone",
    11: "iPad",
    12: "Android",
    13: "Steam",
    14: "Reddit",
    15: "Itch",
    16: "Epic Games",
    17: "GOG",
    18: "Discord",
  };

  useEffect(() => {
    if (itemData.data && itemData.data.tracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData]);

  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";
  useEffect(() => {
    onShowAttrClose();
  }, [itemData]);

  const onSuccess = () => {
    setTriggerTrailersApi(true);
    setShowAddYoutubeTrailerModal(false);
  };

  const { mutate: addYoutubeVideos } = useAddYoutubeVideos(onSuccess);

  const handleTabChange = ({ detail }) => {
    setActiveTabId(detail.activeTabId);
    addQueryParams({ tab: detail.activeTabId });
  };

  return (
    <Container
      variant="borderless"
      disableContentPaddings
      disableHeaderPaddings
      header={
        <Header
          className="mb-4"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel="Track Title"
                variant="normal"
                iconSvg={<span><MdOutlinePlaylistAdd className="w-full h-full scale-125" /></span>}
                onClick={() => {
                  setAddTrack(true);
                  onTrackUnTrackInit();
                }}
                disabled={isAdmin ? itemData?.data?.ip_list?.length >= IP_LIST.length : true}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to track this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Untrack Title"
                variant="normal"
                iconSvg={<span><MdOutlinePlaylistRemove className="w-full h-full scale-125" /></span>}
                onClick={() => {
                  setAddTrack(false);
                  onTrackUnTrackInit();
                }}
                disabled={isAdmin ? !itemData?.data?.tracked : true}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to untrack this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Edit Tags"
                variant="normal"
                iconName="edit"
                onClick={() => onShowAttrInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin ? "You don't have permission to edit tags." : ""
                }
              />
              <Button
                ariaLabel="Delete Title"
                iconName="remove"
                variant="normal"
                onClick={() => onDeleteModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to delete this title."
                    : ""
                }
              />
              <ButtonDropdown
                items={[
                  { text: "Add to franchise", id: "add-to-franchise", disabled: !isAdmin, disabledReason: "You don't have permission to add titles to franchises" },
                  { text: "Compare with other titles", id: "compare" },
                  { text: "View audit logs", id: "audit-logs" },
                ]}
                onItemClick={({ detail }) => {
                  switch (detail.id) {
                    case "add-to-franchise":
                      onAddFranchiseModalInit();
                      break;
                    case "compare":
                      onShowCompareModalInit();
                      break;
                    case "audit-logs":
                      onShowAuditInit();
                      break;
                  }
                }}
              >
                Actions
              </ButtonDropdown>
            </SpaceBetween>
          }
        >
          <div>
            <div className="flex items-center">
              <div className="text-xl font-bold inline-block">
                {itemData.data.name}
                {itemData.data.status && (
                  <span className="text-slate-500">
                    {" "}
                    ({itemData.data.status})
                  </span>
                )}
              </div>

              <div className="inline-block whitespace-nowrap ml-2">
                {isTracked && (
                  <div className="flex space-x-2">
                    {itemData.data?.ip_list?.length > 0 ? (
                      itemData.data.ip_list.map((ip) => (
                        <span
                          key={ip}
                          className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700"
                        >
                          <svg
                            className="h-1.5 w-1.5 fill-green-400"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {ip}
                        </span>
                      ))
                    ) : (
                      <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/30">
                        Tracked
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="dark:text-slate-400 text-slate-600 text-sm font-normal">
              {[
                itemData.data.type.name,
                ...(itemData.data.release_date ? [(new Date(Date.parse(itemData.data.release_date)) > new Date()
                  ? `${formatDate(
                    new Date(Date.parse(itemData.data.release_date)),
                  )} (${moment(itemData.data.release_date).fromNow()})`
                  : formatDate(
                    new Date(Date.parse(itemData.data.release_date)),
                  ))] : []),
                ...(itemData.data.countries ? [itemData.data.countries.map((country) => new Intl.DisplayNames(['en'], { type: 'region' }).of(country)).join(", ")] : []),
              ].join(" \u2022 ")}
            </div>
          </div>
        </Header>
      }
    >
      <Container variant="borderless" disableContentPaddings>
        <div className="space-y-6">
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full rounded-md md:w-auto">
              {itemData.data.image_url ? (
                <div className="w-64 h-auto mx-auto">
                  <img
                    src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${itemData.data.image_url}.png`}
                    alt="..."
                    className="w-64 drop-shadow-lg h-min rounded-md"
                  />
                </div>
              ) : (
                <div className="h-full w-60 flex items-center justify-center rounded-md bg-gray-400">
                  <span className="text-white font-extrabold text-4xl">?</span>
                </div>
              )}
            </div>
            
            <div className="flex flex-col justify-between">
              <div className="flex flex-col gap-1">
                <div className="mb-2">
                  <SpaceBetween direction="horizontal" size="xl">
                    {itemData.data?.global_rank?.pantheon && (
                      <GlobalScore
                        rank={itemData.data.global_rank?.pantheon?.weekly_rank}
                        rankChange={itemData.data.global_rank?.pantheon?.weekly_rank_change}
                        vertical={itemData.data.vertical}
                      />
                    )}
                  </SpaceBetween>
                </div>
                <div className="flex md:flex-row flex-col md:gap-3 items-start">
                  <TagList
                    title="Genres"
                    tagData={itemData.data.genre.map((genre) => ({
                      label: genre,
                      color: "purple",
                    }))}
                  />
                  {itemData.data.cTags && itemData.data.cTags.length > 0 && (
                    <>
                      <div className="w-0 h-full border-r dark:border-slate-600 border-slate-400"></div>
                      <TagList
                        title="Tags"
                        tagData={itemData.data.cTags.map((cTags) => ({
                          label: cTags,
                          color: "green",
                        }))}
                      />
                    </>
                  )}
                </div>
                {itemData.data.franchise &&
                  itemData.data.franchise.length > 0 && (
                    <TagList
                      title="Franchises"
                      tagData={itemData.data.franchise.map((franchise) => ({
                        label: franchise.name,
                        color: "green",
                        href: `/item/${franchise.id}`,
                      }))}
                    />
                  )}
                <div className="flex gap-3 items-start">
                  {itemData.data.involved_companies &&
                    itemData.data.involved_companies.filter(
                      (x) => x.publisher === true,
                    ).length > 0 && (
                      <TagList
                        title="Publishers"
                        tagData={itemData.data.involved_companies
                          .filter((x) => x.publisher === true)
                          .map((publisher) => ({
                            label: publisher.company.name,
                            color: "red",
                          }))}
                      />
                    )}
                  {itemData.data.involved_companies &&
                    itemData.data.involved_companies.filter(
                      (x) => x.developer === true,
                    ).length > 0 && (
                      <>
                        <div className="w-0 h-full border-r dark:border-slate-600 border-slate-400"></div>
                        <TagList
                          title="Developers"
                          tagData={itemData.data.involved_companies
                            .filter((x) => x.developer === true)
                            .map((developer) => ({
                              label: developer.company.name,
                              color: "indigo",
                            }))}
                        />
                      </>
                    )}
                </div>
                {itemData.data.release_dates && (
                  <TagList
                    title="Platforms"
                    tagData={[
                      ...new Set(
                        itemData.data.release_dates.map((x) => x.platform),
                      ),
                    ].map((platform) => ({
                      label: platform,
                      color: "amber",
                    }))}
                  />
                )}
              </div>
              <div className="pt-4">
                <SpaceBetween direction="horizontal" size="xl">
                  {!!itemData.data.steam &&
                    !!itemData.data.steam_rating_description &&
                    (itemData.data.steam_rating_positive !== 0 ||
                      itemData.data.steam_rating_negative !== 0) &&
                    itemData.data.steam_rating_description !==
                    "No user reviews" ? (
                    <SteamReviews
                      positiveReviews={itemData.data.steam_rating_positive}
                      negativeReviews={itemData.data.steam_rating_negative}
                      ratingDescription={
                        itemData.data.steam_rating_description
                      }
                      appid={itemData.data.steam}
                    />
                  ) : null}

                  {!!itemData.data.metacritic_rating && (
                    <MetacriticScore
                      score={itemData.data.metacritic_rating}
                      votes={itemData?.data?.metacritic_vote_count}
                      slug={itemData?.data?.metacritic_slug}
                    />
                  )}
                  {!!itemData?.data?.metacritic_user_rating &&
                    !!itemData?.data?.metacritic_user_vote_count && (
                      <MetacriticUserScore
                        score={itemData.data.metacritic_user_rating}
                        votes={itemData?.data?.metacritic_user_vote_count}
                        slug={itemData?.data?.metacritic_slug}
                      />
                    )}
                  {/*!!itemData.data.imdb_vote_average && (
                    <IMDbScore
                      score={itemData.data.imdb_vote_average}
                      votes={itemData.data.imdb_vote_count}
                    />
                  )*/}
                </SpaceBetween>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="flex space-x-6 mt-4">
        <div className="w-full">
          <Tabs
            onChange={handleTabChange}
            activeTabId={activeTabId}
            tabs={[
              {
                label: "Trends",
                id: "trends",
                content: (
                  <SpaceBetween direction="vertical" size="m">
                    <Header
                      actions={
                        <SpaceBetween direction="horizontal" size="s">
                          <SegmentedControl
                            selectedId={trendsType}
                            onChange={({ detail }) => {
                              setTrendsType(detail.selectedId);
                              addQueryParams({ chartType: detail.selectedId });
                            }}
                            options={[
                              { id: "rank", text: "Rank trends" },
                              { id: "datapoint", text: "Datapoint trends" },
                            ]}
                          />
                          <DateRange dateRange={dateRange} setDateRange={setDateRange} />
                        </SpaceBetween>
                      }
                    >
                      
                    </Header>
                    <RankTrends
                      itemData={itemData}
                      dateRange={dateRange}
                      configType={trendsType === "rank" ? "basic" : "advanced"}
                    />
                  </SpaceBetween>
                ),
              },
              {
                label: "Google Search",
                id: "google",
                content: (
                  <GoogleTitleInsights
                    titleData={itemData.data}
                  />
                ),
              },
              {
                label: "Summary",
                id: "wiki",
                content: (
                  <WikiSummaryBox
                    id={itemData.data.wikidata_id}
                    summary={itemData.data.summary}
                  />
                ),
              },
              ...((itemData.data.dlcs && itemData.data.dlcs.length > 0) ||
                (itemData.data.bundles && itemData.data.bundles.length > 0) ||
                (itemData.data.expansions &&
                  itemData.data.expansions.length > 0) ||
                (itemData.data.remakes && itemData.data.remakes.length > 0) ||
                (itemData.data.remasters && itemData.data.remasters.length > 0)
                ? [
                  {
                    label: "Add-ons",
                    id: "addons",
                    content: (
                      <AddOnsComponent
                        dlcs={itemData.data.dlcs}
                        bundles={itemData.data.bundles}
                        expansions={itemData.data.expansions}
                        remakes={itemData.data.remakes}
                        remasters={itemData.data.remasters}
                      />
                    ),
                  },
                ]
                : []),
              {
                label: "IDs",
                id: "tags",
                content: (
                  <SpaceBetween direction="vertical" size="m">
                    <ExpandableSection
                      defaultExpanded
                      headingTagOverride="h4"
                      variant="container"
                      headerText="Metadata"
                    >
                      <ColumnLayout
                        columns={4}
                        minColumnWidth={150}
                        variant="text-grid"
                      >
                        {itemData.data?.game_modes && (
                          <div>
                            <TextContent>
                              <h4>Game Modes</h4>
                            </TextContent>
                            {itemData.data.game_modes.map((x, index) => (
                              <div key={index}>{x}</div>
                            ))}
                          </div>
                        )}
                        <div>
                          {itemData.data?.themes && (
                            <div>
                              <TextContent>
                                <h4>Themes</h4>
                              </TextContent>
                              {itemData.data.themes.map((x) => (
                                <div>{x}</div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div>
                          {itemData.data?.player_perspectives && (
                            <div>
                              <TextContent>
                                <h4>Player Perspectives</h4>
                              </TextContent>
                              {itemData.data.player_perspectives.map((x) => (
                                <div>{x.name}</div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div>
                          {itemData.data?.game_engine && (
                            <div>
                              <TextContent>
                                <h4>Game Engines</h4>
                              </TextContent>
                              {itemData.data.game_engine.map((x) => (
                                <div>{x.name}</div>
                              ))}
                            </div>
                          )}
                        </div>
                      </ColumnLayout>
                    </ExpandableSection>
                    <ExpandableSection
                      defaultExpanded
                      headingTagOverride="h4"
                      variant="container"
                      headerText="Official Links"
                    >
                      <Table
                        columnDefinitions={[
                          {
                            id: "category",
                            header: "Category Name",
                            cell: (item) => item.categoryName || "-",
                            isRowHeader: true,
                          },
                          {
                            id: "link",
                            header: "Link",
                            cell: (item) => (
                              <Link href={item.url} external rel="noreferrer">
                                {item.url}
                              </Link>
                            ),
                          },
                        ]}
                        enableKeyboardNavigation
                        items={itemData.data.websites?.map((link) => ({
                          categoryName: categoryNames[link.category],
                          url: link.url,
                        }))}
                        loadingText="Loading links..."
                        variant="embedded"
                        empty={
                          <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                          >
                            <SpaceBetween size="m">
                              <b>No official links available</b>
                            </SpaceBetween>
                          </Box>
                        }
                      />
                    </ExpandableSection>
                    <ExpandableSection
                      defaultExpanded
                      headingTagOverride="h4"
                      variant="container"
                      headerText="IDs"
                      headerActions={
                        <Button
                          variant="normal"
                          iconName="edit"
                          onClick={() => onShowAttrInit()}
                          disabled={!isAdmin}
                          disabledReason={
                            !isAdmin ? "You don't have permission to edit." : ""
                          }
                        >
                          Edit
                        </Button>
                      }
                    >
                      <TagsAndIDsComponent itemData={itemData.data} />
                    </ExpandableSection>
                  </SpaceBetween>
                ),
              },
              {
                label: "Trailers",
                id: "Trailers",
                content: (
                  <TrailerGrid
                    id={itemData.data.ip_id}
                    triggerTrailersApi={triggerTrailersApi}
                    setTriggerTrailersApi={setTriggerTrailersApi}
                    setShowAddYoutubeTrailerModal={
                      setShowAddYoutubeTrailerModal
                    }
                  />
                ),
              },
              {
                label: "News",
                id: "news",
                content: (
                  <Container
                    header={
                      <Header>Recent News</Header>
                    }
                  >
                    <News 
                      ip={itemData.data.ip}
                      ip_id={itemData.data.ip_id} 
                    />
                  </Container>
                ),
              },
            ]}
          />
        </div>
      </div>

      <TrackUnTrackConfirmationModal
        visible={showTrackUnTrackModal}
        ipLists={IP_LIST}
        track={!addTrack}
        itemData={itemData?.data}
        ip_id={itemData?.data?.ip_id}
        defaultSelectedIps={itemData?.data?.ip_list}
        onDiscard={onTrackUnTrackClose}
        consentText={itemData?.data?.name}
        onConfirm={(item) => {
          if (item.track) {
            onUntrackItem(item);
            onTrackUnTrackClose();
          } else {
            onTrackItem(item);
            onTrackUnTrackClose();
          }
        }}
      />
      <DeleteConfirmationModal
        instances={[itemData?.data]}
        visible={showDeleteModal}
        onDiscard={onDeleteModalClose}
        onDelete={() => onDelete(itemData?.data?.ip_id)}
        consentText={itemData?.data?.name}
      />
      <AddToFranchiseModal
        visible={showAddFranchiseModal}
        onDiscard={onAddFranchiseModalClose}
        itemData={itemData}
      />
      <AddYoutubeTrailerModal
        visible={showAddYoutubeTrailerModal}
        onSubmit={(videoIds) => {
          addYoutubeVideos({
            videoIds,
            ip: itemData?.data?.ip,
            ipId: itemData?.data?.ip_id,
          });
        }}
        onDiscard={() => setShowAddYoutubeTrailerModal(false)}
      />
      <AttributesEditModal
        visible={showAttrModal}
        itemData={itemData?.data}
        onDismiss={() => setShowAttrModal(false)}
        editableAttributes={TAGS_LIST.map((x) => x.value).filter(
          (x) => !["youtubeChannel", "tmsId", "emsId", "rtUrl"].includes(x),
        )}
      />
      <AuditModal
        visible={showAuditModal}
        ip_id={itemData?.data?.ip_id}
        onDiscard={() => setShowAuditModal(false)}
      />
      <CompareModal
        visible={showCompareModal}
        setVisible={setShowCompareModal}
        titleId={itemData?.data?.ip_id}
      />
    </Container>
  );
}
