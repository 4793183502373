import { Container, Header } from "@cloudscape-design/components";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions } from "ag-grid-enterprise";
import React, { FC } from "react";
import { EmptyTrends } from "../EmptyTrends";
import { useChartConfig } from "./useChartConfig";

interface RedditTrendsProps {
  data: any;
}

export const RedditTrends: FC<RedditTrendsProps> = ({ data }) => {
  const { redditTrendChartOption } = useChartConfig(data?.data);

  return (
    <Container
      header={
        <Header
          variant="h3"
        >
          Reddit
        </Header>
      }
    >
      {redditTrendChartOption && data?.data?.length > 0 && (
        <div className="h-[260px]">
          <AgCharts
            options={redditTrendChartOption as any | AgChartOptions}
            style={{ height: "100%" }}
          />
        </div>
      )}
      {data?.data?.length === 0 && (
        <EmptyTrends text="No data available for the selected date range" />
      )}
    </Container>
  );
};
