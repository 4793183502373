import { ColumnLayout, Container, FormField, Header, Link } from "@cloudscape-design/components";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { CrosshairBoundingBox } from "../../../../../../components/datapoint-comparison/components/TimeseriesChart/CrosshairBoundingBox";
import CrosslineNotes from "../../../../../../components/datapoint-comparison/components/TimeseriesChart/CrosslineNotes";
import { PlatformError } from "../../../game/api/mappers/mapGetPlatformResponse";
import {
  MappedGetWikipediaResponseItem,
  WIKIPEDIA_GRID_HEADER_MAP,
} from "../../../game/api/mappers/mapWikipediaResponse";
import { DateRangeType } from "../../DateRange";
import { TrendsActions } from "../../TrendsActions";
import { useBasicChartConfig } from "../../useBasicChartConfig";
import { EmptyTrends } from "../EmptyTrends";
import { useChartConfig } from "./useChartConfig";
import RankInfo from "../RankInfo";

interface WikipediaTrendsProps {
  itemName?: string;
  rankInfo?: any;
  crosslines?: any;
  data: { data: Array<MappedGetWikipediaResponseItem>; errors: PlatformError };
  dateRange: DateRangeType;
  configType: "basic" | "advanced";
}

export const WikipediaTrends: FC<WikipediaTrendsProps> = ({
  itemName,
  rankInfo,
  crosslines,
  data,
  dateRange,
  configType,
}) => {
  const chartRef = useRef(null);
  const exportTableRef = useRef<AgGridReact>(null);
  const advancedChartConfig = useChartConfig(data?.data);
  const basicChartConfig = useBasicChartConfig(
    data?.data,
    crosslines,
    itemName,
  );
  const { getLineChartOptions } =
    configType === "basic" ? basicChartConfig : advancedChartConfig;
  const [chartOptions, setChartOptions] = useState<AgChartOptions>(null);

  const memoizedGetLineChartOptions = useCallback(getLineChartOptions, [
    getLineChartOptions,
  ]);

  const keys = Object.keys(WIKIPEDIA_GRID_HEADER_MAP);

  useEffect(() => {
    setChartOptions({
      ...memoizedGetLineChartOptions(),
      ...{ data: data?.data ?? {} },
    } as unknown as AgChartOptions);
  }, [data, memoizedGetLineChartOptions]);

  const getFileName = () =>
    `Wikipedia_Trends_${dateRange.startDate}_${dateRange.endDate}`;

  const downloadAsCsv = () =>
    exportTableRef.current.api.exportDataAsCsv({ fileName: getFileName() });
  const downloadAsExcel = () =>
    exportTableRef.current.api.exportDataAsExcel({ fileName: getFileName() });
  const downloadChart = () =>
    chartRef.current.download({
      fileName: getFileName(),
    });

  return (
    <Container
      header={
        <Header
          variant="h3"
          actions={
            configType === "advanced" ? (
              <TrendsActions
                downloadChart={downloadChart}
                downloadAsCsv={downloadAsCsv}
                downloadAsExcel={downloadAsExcel}
              />
            ) : null
          }
        >
          <div className="flex gap-2 items-center">
            {configType === "basic" ? "Wikipedia Rank" : "Wikipedia"}
            <Link
              href="/platforms/wikipedia"
            >
              <span className="text-xs">view more</span>
            </Link>
          </div>
        </Header>
      }
    >
      {configType === "basic" && rankInfo && (
        <div className="mb-2 w-full">
          <RankInfo rankInfo={rankInfo} />
        </div>
      )}
      {chartOptions && (
        <div className="h-[260px] relative">
          {data?.data?.length > 0 && (
            <>
              <AgCharts
                ref={chartRef}
                options={chartOptions as any}
                style={{ height: "100%" }}
              />
              <CrosshairBoundingBox chartRef={chartRef} />
              <CrosslineNotes chartRef={chartRef} crosslines={crosslines} />
            </>
          )}
          {data?.data?.length === 0 && (
            <EmptyTrends text={data?.errors?.[0]?.description} />
          )}
        </div>
      )}
      {/** following piece of code is only for the purpose download chart */}
      {data?.data?.length > 0 && (
        <div
          style={{ height: "450px", width: "100%" }}
          className="ag-theme-quartz-dark hidden"
        >
          <AgGridReact
            ref={exportTableRef}
            rowData={data?.data}
            columnDefs={[
              {
                field: "timestamp",
                headerName: "Date",
                valueFormatter: (params) =>
                  moment.utc(params.value).format("YYYY-MM-DD"),
                valueGetter: (params) =>
                  moment
                    .utc(params.data.timestamp)
                    .format("YYYY-MM-DDT00:00:00"),
                width: 150,
                cellClass: "dateType",
              },
              ...keys.map((key: string) => {
                return {
                  field: key,
                  headerName: WIKIPEDIA_GRID_HEADER_MAP[key],
                  width: 150,
                  valueFormatter: (params) => params.value,
                  valueGetter: (params) =>
                    Math.round(params.data[key] * 10000.0) / 10000.0,
                  cellClass: "numberType",
                };
              }),
            ]}
            excelStyles={[
              {
                id: "numberType",
                numberFormat: {
                  format: "0.0000",
                },
              },
              {
                id: "dateType",
                dataType: "DateTime",
                numberFormat: {
                  format: "m/d/yyy",
                },
              },
            ]}
          />
        </div>
      )}
    </Container>
  );
};
