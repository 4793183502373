import { FilterControlType, FilterJoinOperation, FilterObject, FilterOperation, FilterType } from "../../../types/filterTypes";
import { Vertical, verticalOptions } from "../../../types/verticalTypes";
import { CATEGORIES, COUNTRIES, DMA_REGIONS } from "../../platforms/google/constants";
import { getAllCategories } from "../../platforms/google/utils";

export const listOptions = [
  { value: "personal", label: "Personal" },
  { value: "organization", label: "Organization" },
];

export const DEFAULT_REPORT_BUILDER_TITLE_FILTERS: FilterObject = { 
  category: "all",
  name: "Untitled filters",
  filters: [
    {
      field: "ip",
      label: "Title contains",
      filterType: FilterType.Text,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Text,
      },
    },
    {
      field: "vertical",
      label: "Category",
      filterType: FilterType.Text,
      filterOperation: FilterOperation.Equals,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        setOptions: [...verticalOptions, { label: "Books", value: "Book" }],
        selectMultiple: true,
      },
    },
    { 
      label: "Release date", 
      field: "release_date", 
      filterType: FilterType.DateString, 
      filterOperation: FilterOperation.LessThan,
      valueJoinOperation: FilterJoinOperation.And,
      controlOptions: {
        type: FilterControlType.Date,
      },
    },
    {
      field: "tracked",
      label: "Is Tracked?",
      filterType: FilterType.Boolean,
      filterOperation: FilterOperation.Equals,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Boolean,
      },
    },
    { 
      label: "Franchise", 
      field: "franchise", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: true,
        selectMultiple: true,
      },
    },
    {
      label: "Genre",
      field: "genre",
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: false,
        allowBlank: false,
        selectMultiple: true,
      },
    },
    { 
      label: "Provider", 
      field: "providers", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: true,
        selectMultiple: true,
        filterVertical: Vertical.Movies,
      },
      group: "Movies and Series",
    },
    { 
      label: "Distributor", 
      field: "distributors", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Movies,
      },
      group: "Movies and Series",
    },
    { 
      label: "Production", 
      field: "production", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Movies,
      },
      group: "Movies and Series",
    },
    { 
      label: "Platform", 
      field: "platform", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Developer", 
      field: "developer", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Publisher", 
      field: "publisher", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Game Mode", 
      field: "game_modes", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: false,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Player Perspective", 
      field: "player_perspectives", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: false,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Theme", 
      field: "themes", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: false,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    {
      label: "ISBN-13",
      field: "isbn13",
      filterType: FilterType.Text,
      filterOperation: FilterOperation.Equals,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Text,
      },
      group: "Books",
    },
    {
      label: "Author",
      field: "author",
      filterType: FilterType.Text,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Text,
      },
      group: "Books",
    },
    { 
      label: "Book Series", 
      field: "book_series", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: true,
        selectMultiple: true,
        filterVertical: Vertical.Books,
      },
      group: "Books",
    },
  ],
};

export const AGGREGATION_FUNCTION_OPTIONS = [
  { value: "sum", label: "Sum" },
  { value: "count", label: "Count" },
  { value: "avg", label: "Average" },
  { value: "min", label: "Minimum" },
  { value: "max", label: "Maximum" },
];

export const URL_VALUE_OPTIONS = [
  { value: "value", label: "Raw value", description: "The raw value of this column. For example, if a title is tagged with the Wikipedia article 'The Great Gatsby (2013 film)', the value will be 'The Great Gatsby (2013 film)'." },
  { value: "url", label: "Formatted URL", description: "The URL formatted with the value of this column. For example, if a title is tagged with the Wikipedia article 'The Great Gatsby (2013 film)', the value will be 'https://en.wikipedia.org/wiki/The_Great_Gatsby_(2013_film)'." },
];

export const enum Source {
  Metadata = "Metadata",
  Wikipedia = "Wikipedia",
  YouTube = "YouTube",
  IMDb = "IMDb",
  RottenTomatoes = "Rotten Tomatoes",
  Piracy = "Piracy",
  Reddit = "Reddit",
  Steam = "Steam",
  Twitch = "Twitch",
  AO3 = "AO3",
  Google = "Google",
  Twitter = "Twitter",
  Goodreads = "Goodreads",
  BarnesAndNoble = "Barnes & Noble",
  AmazonBooks = "Amazon Books",
};

export const enum ColumnControlType {
  Range = "range",
  Date = "date",
  Set = "set",
};

export type ColumnControlOptions = {
  type: ColumnControlType;
  optionField: string;

  group?: string;

  label?: string;
  value?: string;
  description?: string;

  // Set options
  setOptions?: Array<{ label?: string; value?: string, [x: string]: any }>;
  defaultSetOption?: string;
  searchable?: boolean;

  // Range options
  bounds?: { startDate?: string; endDate?: string };
  isDateEnabled?: (date: Date) => boolean;
};

export type ColumnDefinition = {
  id: string;
  source: Source;
  field: string;
  headerText: string;
  description?: string;
  tags?: Array<string>;
  verticals?: Array<Vertical>;
  trackedOnly?: boolean;
  caveats?: Array<string>;
  options?: any;
  controlOptions?: Array<ColumnControlOptions>;
};

export const COLUMN_DEFINITIONS: Array<ColumnDefinition> = [
  {
    id: "title",
    source: Source.Metadata,
    field: "ip",
    headerText: "Title",
    description: "The name of the title",
    tags: ["ip", "title", "name"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games, Vertical.Books],
  },
  {
    id: "ip_id",
    source: Source.Metadata,
    field: "ip_id",
    headerText: "IP ID",
    description: "The Pantheon IP ID of the title",
    tags: ["ip_id", "id"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games, Vertical.Books],
  },
  {
    id: "vertical",
    source: Source.Metadata,
    field: "vertical",
    headerText: "Category",
    description: "The category of the title",
    tags: ["vertical", "category"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games, Vertical.Books],
  },
  {
    id: "release_date",
    source: Source.Metadata,
    field: "release_date",
    headerText: "Release Date",
    description: "The release date of the title",
    tags: ["release", "date", "premiere", "launch"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games, Vertical.Books],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "yearOrDate",
        setOptions: [
          { label: "Release year (YYYY)", value: "year" },
          { label: "Release date (YYYY-MM-DD)", value: "date" },
        ],
        defaultSetOption: "year",
        label: "Display format",
      },
    ],
  },
  {
    id: "tracked",
    source: Source.Metadata,
    field: "tracked",
    headerText: "Is Tracked?",
    description: "Whether the title is tracked in the Pantheon database",
    tags: ["tracked"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games, Vertical.Books],
  },
  {
    id: "franchise",
    source: Source.Metadata,
    field: "franchise",
    headerText: "Franchise",
    description: "The franchises the title belongs to",
    tags: ["franchise"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
  },
  {
    id: "genre",
    source: Source.Metadata,
    field: "genre",
    headerText: "Genre",
    description: "The genres of the title",
    tags: ["genre"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games, Vertical.Books],
  },
  {
    id: "countries",
    source: Source.Metadata,
    field: "countries",
    headerText: "Countries",
    description: "The countries the title is associated with",
    tags: ["countries", "country", "region"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
  },
  {
    id: "providers",
    source: Source.Metadata,
    field: "providers",
    headerText: "OTT Providers",
    description: "The OTT providers the title is available on",
    tags: ["ott", "providers", "streaming", "service"],
    verticals: [Vertical.Movies, Vertical.Series],
  },
  {
    id: "distributors",
    source: Source.Metadata,
    field: "distributors",
    headerText: "Distributor",
    description: "The companies that distributed the title",
    tags: ["distributor"],
    verticals: [Vertical.Movies, Vertical.Series],
  },
  {
    id: "production",
    source: Source.Metadata,
    field: "production",
    headerText: "Production Company",
    description: "The companies that produced the title",
    tags: ["production", "company"],
    verticals: [Vertical.Movies, Vertical.Series],
  },
  {
    id: "number_of_episodes",
    source: Source.Metadata,
    field: "number_of_episodes",
    headerText: "Number of Episodes",
    description: "The number of episodes in the series",
    tags: ["episodes"],
    verticals: [Vertical.Series],
  },
  {
    id: "number_of_seasons",
    source: Source.Metadata,
    field: "number_of_seasons",
    headerText: "Number of Seasons",
    description: "The number of seasons in the series",
    tags: ["seasons"],
    verticals: [Vertical.Series],
  },
  {
    id: "isTheatrical",
    source: Source.Metadata,
    field: "isTheatrical",
    headerText: "Is Theatrical?",
    description: "Whether the title was released in theaters",
    tags: ["theatrical", "theater"],
    verticals: [Vertical.Movies],
  },
  {
    id: "runtime",
    source: Source.Metadata,
    field: "runtime",
    headerText: "Runtime",
    description: "Average episode runtime for series, or total runtime for movies",
    tags: ["runtime", "length"],
    verticals: [Vertical.Movies, Vertical.Series],
  },
  {
    id: "platform",
    source: Source.Metadata,
    field: "platform",
    headerText: "Platform",
    description: "The platforms the game is available on",
    tags: ["platform"],
    verticals: [Vertical.Games],
  },
  {
    id: "developer",
    source: Source.Metadata,
    field: "developer",
    headerText: "Developer",
    description: "The developers of the game",
    tags: ["developer"],
    verticals: [Vertical.Games],
  },
  {
    id: "publisher",
    source: Source.Metadata,
    field: "publisher",
    headerText: "Publisher",
    description: "The publishers of the game",
    tags: ["publisher"],
    verticals: [Vertical.Games, Vertical.Books],
  },
  {
    id: "themes",
    source: Source.Metadata,
    field: "themes",
    headerText: "Themes",
    description: "The themes of the game according to IGDB",
    tags: ["themes", "genre"],
    verticals: [Vertical.Games],
  },
  {
    id: "age_ratings",
    source: Source.Metadata,
    field: "age_ratings",
    headerText: "Age Rating",
    description: "The ESRB age rating of the game",
    tags: ["age", "rating"],
    verticals: [Vertical.Games],
  },
  {
    id: "game_modes",
    source: Source.Metadata,
    field: "game_modes",
    headerText: "Game Mode",
    description: "The game modes available in the game",
    tags: ["game mode"],
    verticals: [Vertical.Games],
  },
  {
    id: "player_perspectives",
    source: Source.Metadata,
    field: "player_perspectives",
    headerText: "Player Perspective",
    description: "The player perspectives available in the game (e.g. first-person, third-person)",
    tags: ["player", "perspective"],
    verticals: [Vertical.Games],
  },
  {
    id: "isbn",
    source: Source.Metadata,
    field: "isbn",
    headerText: "ISBN",
    description: "The ISBN of the book",
    tags: ["isbn", "isbn10", "isbn-10"],
    verticals: [Vertical.Books],
  },
  {
    id: "isbn13",
    source: Source.Metadata,
    field: "isbn13",
    headerText: "ISBN-13",
    description: "The ISBN-13 of the book",
    tags: ["isbn", "isbn13", "isbn-13"],
    verticals: [Vertical.Books],
  },
  {
    id: "author",
    source: Source.Metadata,
    field: "author",
    headerText: "Author",
    description: "The author or authors of the book",
    tags: ["author", "writer"],
    verticals: [Vertical.Books],
  },
  {
    id: "book_series",
    source: Source.Metadata,
    field: "book_series",
    headerText: "Book Series",
    description: "The book series the book belongs to",
    tags: ["book", "series"],
    verticals: [Vertical.Books],
  },
  {
    id: "edition_count",
    source: Source.Metadata,
    field: "edition_count",
    headerText: "Number of Editions",
    description: "The number of editions of the book",
    tags: ["number", "editions", "count"],
    verticals: [Vertical.Books],
  },
  {
    id: "wikipedia_article",
    source: Source.Wikipedia,
    field: "article",
    headerText: "Wikipedia Article",
    description: "The Wikipedia article associated with the title",
    tags: ["wikipedia", "page", "article"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "urlOrValue",
        setOptions: URL_VALUE_OPTIONS,
        defaultSetOption: "value",
        label: "Display format",
      },
    ],
  },
  {
    id: "wikipedia_page_views",
    source: Source.Wikipedia,
    field: "page_views",
    headerText: "Wikipedia Page Views",
    description: "The number of visits the Wikipedia article has received",
    tags: ["wikipedia", "page", "article", "views"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "wikipedia_zscore",
    source: Source.Wikipedia,
    field: "zscore",
    headerText: "Wikipedia Z-Score",
    description: "The Pantheon-calculated Z-Score of the Wikipedia platform",
    tags: ["wikipedia", "zscore", "z score", "global"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "google_search_interest",
    source: Source.Google,
    field: "search_interest",
    headerText: "Google Search Interest",
    description: "A value indicating the search interest of the title on Google. This is not exact number of searches, but rather a relative value that should be analyzed comparatively.",
    caveats: [
      "The search interest value is not the exact number of searches, but instead a value relative to all other titles that represents search interest.",
      "Only the top 1,500 titles per vertical have data collected across all search categories. All others only have data collected for the 'Title and category' category.",
      "Data starts on 1/1/2023.",
      "Data lags between 1-6 days.",
    ],
    tags: ["google", "search", "interest", "volume"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Country", value: "country" },
          { label: "DMA region", value: "DMA Region" },
        ],
        defaultSetOption: "country",
        label: "Region type",
      },
      {
        type: ColumnControlType.Set,
        optionField: "geo_name",
        setOptions: [{ label: "Worldwide", value: "all" }, ...Object.values(COUNTRIES)],
        defaultSetOption: "all",
        label: "Region",
        searchable: true,
        group: "country",
      },
      {
        type: ColumnControlType.Set,
        optionField: "geo_name",
        setOptions: Object.values(DMA_REGIONS),
        defaultSetOption: DMA_REGIONS.LOS_ANGELES_CA.value,
        label: "Region",
        searchable: true,
        group: "DMA Region",
      },
      {
        type: ColumnControlType.Set,
        optionField: "category",
        setOptions: [{ label: "All categories", value: "all", description: "Average across all search categories the title has data for" }, ...getAllCategories(CATEGORIES)],
        defaultSetOption: "all",
        label: "Search category",
        searchable: true,
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
        bounds: {
          startDate: "2023-01-01",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "youtube_views",
    source: Source.YouTube,
    field: "views",
    headerText: "YouTube Trailer Views",
    description: "The total number of views the title's YouTube trailers have received",
    tags: ["youtube", "trailer", "video", "views"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "youtube_likes",
    source: Source.YouTube,
    field: "likes",
    headerText: "YouTube Trailer Likes",
    description: "The total number of likes the title's YouTube trailers have received",
    tags: ["youtube", "trailer", "video", "likes"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "youtube_comments",
    source: Source.YouTube,
    field: "comments",
    headerText: "YouTube Trailer Comments",
    description: "The total number of comments the title's YouTube trailers have received",
    tags: ["youtube", "trailer", "video", "comments"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "youtube_zscore",
    source: Source.YouTube,
    field: "zscore",
    headerText: "YouTube Z-Score",
    description: "The Pantheon-calculated Z-Score of the YouTube platform",
    tags: ["youtube", "zscore", "z score", "global"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "imdb_id",
    source: Source.IMDb,
    field: "id",
    headerText: "IMDb ID",
    description: "The IMDb ID of the title",
    tags: ["imdb", "id"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "urlOrValue",
        setOptions: URL_VALUE_OPTIONS,
        defaultSetOption: "value",
        label: "Display format",
      },
    ],
  },
  {
    id: "imdb_votes",
    source: Source.IMDb,
    field: "votes",
    headerText: "IMDb Votes",
    description: "The number of votes the title has received on IMDb",
    caveats: [
      "Data for this field starts on 2/14/2024.",
    ],
    tags: ["imdb", "votes"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The day-by-day difference in the number of votes the title has received on IMDb" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of votes the title has received on IMDb at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2024-02-14",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2024-02-14",
        },
      },
    ],
  },
  {
    id: "imdb_rating",
    source: Source.IMDb,
    field: "rating",
    headerText: "IMDb Rating",
    description: "The rating of the title on IMDb",
    caveats: [
      "Data for this field starts on 2/14/2024.",
    ],
    tags: ["imdb", "rating"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        label: "Date",
        bounds: {
          startDate: "2024-02-14",
        },
      },
    ],
  },
  {
    id: "imdb_box_office",
    source: Source.IMDb,
    field: "box_office",
    headerText: "Box Office Mojo Gross",
    description: "The box office gross of the title on Box Office Mojo",
    caveats: [
      "Not all movies have box office data.",
    ],
    tags: ["imdb", "box", "office", "mojo", "domestic", "gross"],
    verticals: [Vertical.Movies],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative domestic change", value: "cumulative", description: "The day-by-day difference in the box office domestic gross of the title on Box Office Mojo" },
          { label: "Gross to date", value: "gross_to_date", description: "The total box office gross of the title on Box Office Mojo to-date" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
        group: "cumulative",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
        group: "cumulative",
      },
      {
        type: ColumnControlType.Set,
        optionField: "region",
        setOptions: [
          { label: "Domestic", value: "XDOM" },
          { label: "International", value: "XNDOM" },
          { label: "Worldwide", value: "XWW" },
          { label: "Australia", value: "AU" },
          { label: "Brazil", value: "BR" },
          { label: "Germany", value: "DE" },
          { label: "Spain", value: "ES" },
          { label: "France", value: "FR" },
          { label: "United Kingdom", value: "GB" },
          { label: "India", value: "IN" },
          { label: "Italy", value: "IT" },
          { label: "Japan", value: "JP" },
          { label: "South Korea", value: "KR" },
          { label: "Mexico", value: "MX" },
          { label: "Thailand", value: "TH" },
        ],
        defaultSetOption: "XDOM",
        label: "Region",
        group: "gross_to_date",
      },
    ],
  },
  {
    id: "imdb_zscore",
    source: Source.IMDb,
    field: "zscore",
    headerText: "IMDb Z-Score",
    description: "The Pantheon-calculated Z-Score of the IMDb platform",
    caveats: [
      "Data for this field starts on 2/14/2024.",
    ],
    tags: ["imdb", "zscore", "z score", "global"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
        bounds: {
          startDate: "2024-02-14",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "rt_id",
    source: Source.RottenTomatoes,
    field: "id",
    headerText: "EMS ID",
    description: "The Rotten Tomatoes EMS ID of the title",
    tags: ["rotten", "tomatoes", "ems", "id"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "urlOrValue",
        setOptions: URL_VALUE_OPTIONS,
        defaultSetOption: "value",
        label: "Display format",
      },
    ],
  },
  {
    id: "rt_audience_votes",
    source: Source.RottenTomatoes,
    field: "audience_votes",
    headerText: "Rotten Tomatoes Audience Votes",
    description: "The number of audience votes (Popcornmeter) the title has received on Rotten Tomatoes",
    caveats: [
      "Data for this field starts on 2/21/2024.",
    ],
    tags: ["rt", "rotten", "tomatoes", "audience", "votes"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The day-by-day difference in the number of audience votes the title has received on Rotten Tomatoes" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of audience votes the title has received on Rotten Tomatoes at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2024-02-21",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2024-02-21",
        },
      },
    ],
  },
  {
    id: "rt_total_critic_votes",
    source: Source.RottenTomatoes,
    field: "total_critic_votes",
    headerText: "Rotten Tomatoes Critic Votes",
    description: "The total number of critic votes (Tomatometer) the title has received on Rotten Tomatoes",
    caveats: [
      "Data for this field starts on 2/21/2024.",
    ],
    tags: ["rt", "rotten", "tomatoes", "total", "critic", "votes"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        label: "Date",
        bounds: {
          startDate: "2024-02-21",
        },
      },
    ],
  },
  {
    id: "rt_audience_rating",
    source: Source.RottenTomatoes,
    field: "audience_rating",
    headerText: "Rotten Tomatoes Audience Rating",
    description: "The audience rating (Popcornmeter) of the title on Rotten Tomatoes",
    caveats: [
      "Data for this field starts on 2/21/2024.",
    ],
    tags: ["rt", "rotten", "tomatoes", "audience", "rating"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        label: "Date",
        bounds: {
          startDate: "2024-02-21",
        },
      },
    ],
  },
  {
    id: "rt_critic_rating",
    source: Source.RottenTomatoes,
    field: "critic_rating",
    headerText: "Rotten Tomatoes Critic Rating",
    description: "The critic rating (Tomatometer) of the title on Rotten Tomatoes",
    caveats: [
      "Data for this field starts on 2/21/2024.",
    ],
    tags: ["rt", "rotten", "tomatoes", "critic", "rating"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        label: "Date",
        bounds: {
          startDate: "2024-02-21",
        },
      },
    ],
  },
  {
    id: "rt_zscore",
    source: Source.RottenTomatoes,
    field: "zscore",
    headerText: "Rotten Tomatoes Z-Score",
    description: "The Pantheon-calculated Z-Score of the Rotten Tomatoes platform",
    caveats: [
      "Data for this field starts on 2/21/2024.",
    ],
    tags: ["rt", "rotten", "tomatoes", "zscore", "z score", "global"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
        bounds: {
          startDate: "2024-02-21",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "piracy_downloads",
    source: Source.Piracy,
    field: "downloads",
    headerText: "Piracy Downloads",
    description: "The number of times a title has been downloaded via torrents",
    tags: ["piracy", "torrent", "downloads"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "piracy_zscore",
    source: Source.Piracy,
    field: "zscore",
    headerText: "Piracy Z-Score",
    description: "The Pantheon-calculated Z-Score of the piracy platform",
    tags: ["piracy", "zscore", "z score", "global"],
    verticals: [Vertical.Movies, Vertical.Series],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "reddit_subreddit",
    source: Source.Reddit,
    field: "subreddit",
    headerText: "Reddit Subreddit",
    description: "The title's subreddit",
    tags: ["reddit", "subreddit"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "urlOrValue",
        setOptions: URL_VALUE_OPTIONS,
        defaultSetOption: "value",
        label: "Display format",
      },
    ],
  },
  {
    id: "reddit_upvotes",
    source: Source.Reddit,
    field: "upvotes",
    headerText: "Reddit Upvotes",
    description: "The number of upvotes that posts within a title's subreddit have received",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
    ],
    tags: ["reddit", "upvotes", "subreddit"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "reddit_comments",
    source: Source.Reddit,
    field: "comments",
    headerText: "Reddit Comments",
    description: "The number of comments that posts within a title's subreddit have received",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
    ],
    tags: ["reddit", "comments", "subreddit"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "reddit_members",
    source: Source.Reddit,
    field: "members",
    headerText: "Reddit Members",
    description: "The number of members that have joined a title's subreddit",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
    ],
    tags: ["reddit", "members", "subreddit"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The day-by-day difference in the number of members that have joined a title's subreddit" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of members that have joined a title's subreddit at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
      },
    ],
  },
  {
    id: "twitter_volume",
    source: Source.Twitter,
    field: "volume",
    headerText: "Twitter Volume",
    description: "The number of tweets that mention the title on X (formerly Twitter)",
    caveats: [
      "Data for this field is only available for tracked titles.",
    ],
    tags: ["twitter", "tweets", "volume", "x"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "ao3_hits",
    source: Source.AO3,
    field: "hits",
    headerText: "AO3 Hits",
    description: "The number of views that stories written about the title have received",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
    ],
    tags: ["ao3", "hits"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The day-by-day difference in the number of views that stories written about the title have received on Archive of Our Own" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of views that stories written about the title have received on Archive of Our Own at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2023-03-14",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2023-03-14",
        },
      },
    ],
  },
  {
    id: "ao3_kudos",
    source: Source.AO3,
    field: "kudos",
    headerText: "AO3 Kudos",
    description: "The number of kudos that stories written about the title have received",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
    ],
    tags: ["ao3", "kudos"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The day-by-day difference in the number of kudos that stories written about the title have received on Archive of Our Own" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of kudos that stories written about the title have received on Archive of Our Own at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2023-03-14",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2023-03-14",
        },
      },
    ],
  },
  {
    id: "ao3_comments",
    source: Source.AO3,
    field: "comments",
    headerText: "AO3 Comments",
    description: "The number of comments that stories written about the title have received",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
    ],
    tags: ["ao3", "comments"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The day-by-day difference in the number of comments that stories written about the title have received on Archive of Our Own" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of comments that stories written about the title have received on Archive of Our Own at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2023-03-14",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2023-03-14",
        },
      },
    ],
  },
  {
    id: "ao3_bookmarks",
    source: Source.AO3,
    field: "bookmarks",
    headerText: "AO3 Bookmarks",
    description: "The number of people who have bookmarked stories written about the title",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
    ],
    tags: ["ao3", "bookmarks"],
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The day-by-day difference in the number of people who have bookmarked stories written about the title on Archive of Our Own" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of people who have bookmarked stories written about the title on Archive of Our Own at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2023-03-14",
        },
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2023-03-14",
        },
      },
    ],
  },
  {
    id: "steam_appid",
    source: Source.Steam,
    field: "id",
    headerText: "Steam App ID",
    description: "The app ID of the game on Steam",
    tags: ["steam", "app", "id", "appid"],
    verticals: [Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "urlOrValue",
        setOptions: URL_VALUE_OPTIONS,
        defaultSetOption: "value",
        label: "Display format",
      },
    ],
  },
  {
    id: "steam_player_count",
    source: Source.Steam,
    field: "player_count",
    headerText: "Steam Player Count",
    description: "The number of concurrent players playing the game on Steam",
    tags: ["steam", "player", "count", "ccu"],
    verticals: [Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "steam_zscore",
    source: Source.Steam,
    field: "zscore",
    headerText: "Steam Z-Score",
    description: "The Pantheon-calculated Z-Score of the Steam platform",
    tags: ["steam", "zscore", "z score", "global"],
    verticals: [Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "twitch_id",
    source: Source.Twitch,
    field: "id",
    headerText: "Twitch Game ID",
    description: "The ID of the game on Twitch",
    tags: ["twitch", "game", "id"],
    verticals: [Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "urlOrValue",
        setOptions: URL_VALUE_OPTIONS,
        defaultSetOption: "value",
        label: "Display format",
      },
    ],
  },
  {
    id: "twitch_viewers",
    source: Source.Twitch,
    field: "viewers",
    headerText: "Twitch Viewers",
    description: "The average number of concurrent viewers watching streamers play the game on Twitch",
    tags: ["twitch", "viewer", "count", "ccu"],
    verticals: [Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "twitch_broadcasters",
    source: Source.Twitch,
    field: "broadcasters",
    headerText: "Twitch Broadcasters",
    description: "The average number of concurrent streamers playing the game on Twitch",
    tags: ["twitch", "streamer", "broadcaster", "count", "ccu"],
    verticals: [Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "twitch_zscore",
    source: Source.Twitch,
    field: "zscore",
    headerText: "Twitch Z-Score",
    description: "The Pantheon-calculated Z-Score of the Twitch platform",
    tags: ["twitch", "zscore", "z score", "global"],
    verticals: [Vertical.Games],
    controlOptions: [
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        label: "Date range",
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
    ],
  },
  {
    id: "goodreads_id",
    source: Source.Goodreads,
    field: "id",
    headerText: "Goodreads ID",
    description: "The ID of the book on Goodreads",
    tags: ["goodreads", "id"],
    verticals: [Vertical.Books],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "urlOrValue",
        setOptions: URL_VALUE_OPTIONS,
        defaultSetOption: "value",
        label: "Display format",
      },
    ],
  },
  {
    id: "goodreads_rating",
    source: Source.Goodreads,
    field: "average_rating",
    headerText: "Goodreads Rating",
    description: "The rating of the title on Goodreads",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
      "Data for this field is collected every Sunday.",
    ],
    tags: ["goodreads", "rating"],
    verticals: [Vertical.Books],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        label: "Date",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
    ],
  },
  {
    id: "goodreads_rating_count",
    source: Source.Goodreads,
    field: "rating_count",
    headerText: "Goodreads Rating Count",
    description: "The number of ratings a book has received on Goodreads",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
      "Data for this field is collected every Sunday.",
    ],
    tags: ["goodreads", "ratings", "star"],
    verticals: [Vertical.Books],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "ratingType",
        setOptions: [
          { label: "All ratings", value: "rating_count" },
          { label: "1 star", value: "1_star_count" },
          { label: "2 star", value: "2_star_count" },
          { label: "3 star", value: "3_star_count" },
          { label: "4 star", value: "4_star_count" },
          { label: "5 star", value: "5_star_count" },
        ],
        defaultSetOption: "rating_count",
        label: "Rating type",
      },
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The week-by-week difference in the number of ratings a book has received on Goodreads" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of ratings a book has received on Goodreads at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
    ],
  },
  {
    id: "goodreads_review_count",
    source: Source.Goodreads,
    field: "review_count",
    headerText: "Goodreads Review Count",
    description: "The number of user-written reviews a book has received on Goodreads",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
      "Data for this field is collected every Sunday.",
    ],
    tags: ["goodreads", "reviews"],
    verticals: [Vertical.Books],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The week-by-week difference in the number of ratings a book has received on Goodreads" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of ratings a book has received on Goodreads at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
    ],
  },
  {
    id: "barnesnoble_sales_rank",
    source: Source.BarnesAndNoble,
    field: "sales_rank",
    headerText: "Barnes & Noble Sales Rank",
    description: "The sales rank of the title on Barnes & Noble",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
      "Data for this field is collected every Sunday.",
    ],
    tags: ["barnes", "noble", "sales", "rank"],
    verticals: [Vertical.Books],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        label: "Date",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
    ],
  },
  {
    id: "barnesnoble_rating",
    source: Source.BarnesAndNoble,
    field: "average_rating",
    headerText: "Barnes & Noble Rating",
    description: "The rating of the title on Barnes & Noble",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
      "Data for this field is collected every Sunday.",
    ],
    tags: ["barnes", "noble", "rating"],
    verticals: [Vertical.Books],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        label: "Date",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
    ],
  },
  {
    id: "barnesnoble_rating_count",
    source: Source.BarnesAndNoble,
    field: "rating_count",
    headerText: "Barnes & Noble Rating Count",
    description: "The number of ratings a book has received on Barnes & Noble",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
      "Data for this field is collected every Sunday.",
    ],
    tags: ["barnes", "noble", "ratings", "star"],
    verticals: [Vertical.Books],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "ratingType",
        setOptions: [
          { label: "All ratings", value: "rating_count" },
          { label: "1 star", value: "1_star_count" },
          { label: "2 star", value: "2_star_count" },
          { label: "3 star", value: "3_star_count" },
          { label: "4 star", value: "4_star_count" },
          { label: "5 star", value: "5_star_count" },
        ],
        defaultSetOption: "rating_count",
        label: "Rating type",
      },
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The week-by-week difference in the number of ratings a book has received on Barnes & Noble" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of ratings a book has received on Barnes & Noble at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
    ],
  },
  {
    id: "amazonbooks_id",
    source: Source.AmazonBooks,
    field: "id",
    headerText: "Amazon Books ASIN",
    description: "The ASIN of the book on Amazon",
    tags: ["amazon", "books", "id", "asin"],
    verticals: [Vertical.Books],
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "urlOrValue",
        setOptions: URL_VALUE_OPTIONS,
        defaultSetOption: "value",
        label: "Display format",
      },
    ],
  },
  {
    id: "amazonbooks_rating",
    source: Source.AmazonBooks,
    field: "average_rating",
    headerText: "Amazon Books Rating",
    description: "The rating of the title on Amazon Books",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
      "Data for this field is collected every Sunday.",
    ],
    tags: ["amazon", "books", "rating"],
    verticals: [Vertical.Books],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        label: "Date",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
    ],
  },
  {
    id: "amazonbooks_rating_count",
    source: Source.AmazonBooks,
    field: "rating_count",
    headerText: "Amazon Books Rating Count",
    description: "The number of ratings a book has received on Amazon Books",
    caveats: [
      "Data for this field is only available for tracked titles.",
      "Data starts at the time the title has been tracked.",
      "Data for this field is collected every Sunday.",
    ],
    tags: ["amazon", "books", "ratings", "star"],
    verticals: [Vertical.Books],
    trackedOnly: true,
    controlOptions: [
      {
        type: ColumnControlType.Set,
        optionField: "ratingType",
        setOptions: [
          { label: "All ratings", value: "rating_count" },
          { label: "1 star", value: "1_star_count" },
          { label: "2 star", value: "2_star_count" },
          { label: "3 star", value: "3_star_count" },
          { label: "4 star", value: "4_star_count" },
          { label: "5 star", value: "5_star_count" },
        ],
        defaultSetOption: "rating_count",
        label: "Rating type",
      },
      {
        type: ColumnControlType.Set,
        optionField: "_controlGroup",
        setOptions: [
          { label: "Cumulative change", value: "cumulative", description: "The week-by-week difference in the number of ratings a book has received on Barnes & Noble" },
          { label: "Point-in-time total", value: "point_in_time", description: "The total number of ratings a book has received on Barnes & Noble at a specific point in time" },
        ],
        defaultSetOption: "cumulative",
        label: "Data type",
      },
      {
        type: ColumnControlType.Range,
        optionField: "dateRange",
        group: "cumulative",
        label: "Date range",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
      {
        type: ColumnControlType.Set,
        optionField: "aggregation",
        setOptions: AGGREGATION_FUNCTION_OPTIONS,
        group: "cumulative",
        defaultSetOption: "sum",
        label: "Aggregation function",
      },
      {
        type: ColumnControlType.Date,
        optionField: "dateRange",
        group: "point_in_time",
        label: "Date",
        bounds: {
          startDate: "2024-10-25",
        },
        isDateEnabled: (date: Date) => date.getDay() === 0,
      },
    ],
  },
];

export type SortBy = {
  column_id: string;
  order: "asc" | "desc";
};

export type ReportDefinition = {
  name: string;
  created_at?: number;
  created_by?: string;
  created_by_organization?: string;
  version: number;
  previous_report_id: string;
  save_list: "personal" | "organization";
  share_with: Array<string>;
  sort_by?: Array<SortBy>;
  limit?: number;
  title_list: {
    name: string;
    scope: "personal" | "organization";
  };
  column_definitions: Array<ColumnDefinition>;
};

export const DEFAULT_TITLE_LIST_NAME = "Untitled Title List";