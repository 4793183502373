import { Box, Modal, SpaceBetween, Button } from "@cloudscape-design/components";
import React, { forwardRef, PropsWithRef, ReactElement, useImperativeHandle, useState } from "react";

export interface MessageBoxOptions {
  headerText?: string;
  messageBody?: any;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
}

export interface MessageBoxHandle {
  open: (options?: MessageBoxOptions) => void;
  close: () => void;
}

interface MessageBoxProps extends MessageBoxOptions {
  modalIsOpen?: boolean;
  setModalIsOpen?: (isOpen: boolean) => void;
}

const defaultOptions: MessageBoxOptions = {
  headerText: "Message",
  messageBody: null,
  primaryButtonText: "Ok",
  secondaryButtonText: "Cancel",
  onPrimaryButtonClick: () => {},
  onSecondaryButtonClick: () => {},
};

export const MessageBox = forwardRef<MessageBoxHandle, MessageBoxProps>(({
  headerText,
  messageBody,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  modalIsOpen=false,
  setModalIsOpen,
}, ref) => {

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<MessageBoxOptions>(defaultOptions);

  const handleOpen = (options: MessageBoxOptions) => {
    setIsOpen(true);
    setModalIsOpen?.(true);
    if (options) {
      setOptions({ ...defaultOptions, ...options });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setModalIsOpen?.(false);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }), []);

  return (
    <Modal
      onDismiss={() => handleClose()}
      visible={modalIsOpen || isOpen}
      header={headerText ?? options.headerText}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => {
              if (onSecondaryButtonClick) {
                onSecondaryButtonClick();
              } else {
                options.onSecondaryButtonClick();
              }
              handleClose();
            }}>{secondaryButtonText ?? options.secondaryButtonText}</Button>
            <Button variant="primary" onClick={() => {
              if (onPrimaryButtonClick) {
                onPrimaryButtonClick();
              } else {
                options.onPrimaryButtonClick();
              }
              handleClose();
            }}>{primaryButtonText ?? options.primaryButtonText}</Button>
          </SpaceBetween>
        </Box>
      }
    >
      {messageBody ?? options.messageBody}
    </Modal>
  );
});