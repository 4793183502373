import { Link } from "@cloudscape-design/components";
import React from "react";
import { VerticalIcon } from "../../../../../../components/VerticalIcon";

export const useSelectedTitlesGridConfig = ({
  selectionEnabled=true,
}) => {

  const defaultColDef = {
    sortable: true,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100,
    suppressMovable: true,
  } as any;

  const columnDefs = [
    {
      field: "ip",
      headerName: "Title",
      flex: 1,
      headerCheckboxSelection: selectionEnabled,
      checkboxSelection: selectionEnabled,
      showDisabledCheckboxes: true,
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => {
        if (!params.value) return null;
        const ipId = params.data.ip_id;
        const release_year = params.data.release_date ? new Date(Date.parse(params.data.release_date)).getFullYear() : "N/A";
        return (
          <div className={`flex gap-2 align-middle ${selectionEnabled ? "mt-0.5" : "mt-1"}`}>
            <span className="text-slate-500 flex justify-center">
              <VerticalIcon vertical={params.data.vertical} />
            </span>
            <Link href={`/item/${ipId}`}>
              <span>{params.data.ip}</span>
            </Link>
            <span className="text-xs text-slate-500 mt-0.5">
              {release_year}
            </span>
          </div>
        );
      },
    },
    /*{
      field: "vertical",
      headerName: "Category",
    },
    {
      field: "release_date",
      headerName: "Release Date",
    },*/
  ] as any;

  return {
    defaultColDef,
    columnDefs,
    rowSelection: "multiple" as "multiple",
  };
};