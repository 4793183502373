import {
  Box,
  Button,
  FormField,
  Modal,
  SpaceBetween,
  Autosuggest,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import { useFranchiseActions } from "../../../hooks/useFranchiseActions";
import { convertData } from "../../../../../components/CommonSearch/CommonSearch";
import { useGetTopSearchDataV2 } from "../../../../../components/CommonSearch/api/hooks/useGetTopSearchDataV2";
import { Vertical } from "../../../../../types/verticalTypes";

export const AddToFranchiseModal = ({
  visible = false,
  itemData,
  onDiscard = () => {},
}) => {
  const { updateFranchiseCards } = useFranchiseActions();
  const [query, setQuery] = useState("");
  const [loadItemsQuery, setLoadItemsQuery] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const { data, isLoading, error } = useGetTopSearchDataV2({
    q: loadItemsQuery,
    verticals: [Vertical.Franchises],
    limit: 50,
  });

  return (
    <Modal
      size="medium"
      visible={visible}
      onDismiss={onDiscard}
      header={"Add Title to Franchise"}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={(e) => {
                e.preventDefault();
                onDiscard();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={selectedOption == null}
              onClick={() => {
                updateFranchiseCards({
                  id: selectedOption.value,
                  name: selectedOption.label,
                  removed: [],
                  added: [itemData?.data?.ip_id],
                });
                onDiscard();
              }}
            >
              Add
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <FormField stretch label="Franchise">
        <Autosuggest
          value={query}
          options={data?.results?.length > 0 ? convertData(data.results) : []}
          onChange={({ detail }) => {
            setQuery(detail.value);
            if (detail.value === "") {
              setLoadItemsQuery(null);
              setSelectedOption(null);
            }
          }}
          onLoadItems={({ detail }) => setLoadItemsQuery(detail.filteringText)}
          onSelect={({ detail }) => {
            if (detail.selectedOption) {
              setSelectedOption(detail.selectedOption);
              setQuery(detail.selectedOption.label);
            } else {
              setQuery(detail.value);
            }
          }}
          placeholder="Search for franchises"
          statusType={isLoading ? "loading" : error ? "error" : "finished"}
        />
      </FormField>
    </Modal>
  );
};
