import { PropertyFilterProps } from "@cloudscape-design/components";
import moment from "moment";
import { Filter, FilterControlOptions, FilterControlType, FilterJoinOperation, FilterObject, FilterOperation, FilterType } from "../../types/filterTypes";

export const getFilteredTokens = (tokens: Array<PropertyFilterProps.Token>) => {
  const include = [];
  const exclude = [];

  const checkForDateString = (str: string) =>
    /^\d{4}-\d{2}-\d{2}$/.test(str) ? new Date(str).getTime() / 1000 : str;

  const formatFilter = (token: PropertyFilterProps.Token) => {
    switch (token.operator) {
      case "=":
        include.push({
          match: {
            [token.propertyKey]: {
              query: token.value,
              auto_generate_synonyms_phrase_query: false,
            },
          },
        });
        break;
      case ":":
        include.push({
          query_string: {
            default_field: token.propertyKey,
            query: `"*${token.value}*"`,
          },
        });
        break;
      case "!=":
        exclude.push({
          query_string: {
            default_field: token.propertyKey,
            query: `*${token.value}*`,
          },
        });
        break;
      case ">=":
        include.push({
          range: {
            [token.propertyKey]: {
              gte: checkForDateString(token.value),
            },
          },
        });
        break;
      case "<=":
        include.push({
          range: {
            [token.propertyKey]: {
              lte: checkForDateString(token.value),
            },
          },
        });
        break;
      case ">":
        include.push({
          range: {
            [token.propertyKey]: {
              gt: checkForDateString(token.value),
            },
          },
        });
        break;
      case "<":
        include.push({
          range: {
            [token.propertyKey]: {
              lt: checkForDateString(token.value),
            },
          },
        });
        break;
      case "blank":
        exclude.push({
          exists: {
            field: token.propertyKey,
          },
        });
        break;
      case "notBlank":
        include.push({
          exists: {
            field: token.propertyKey,
          },
        });
        break;
      default:
        return null;
    }
  };

  tokens.forEach(formatFilter);

  return { include, exclude };
};

export const getFilterConditionsFromFilters = (filters: Array<Filter>) => {
  const conditions = [];

  if (!filters || filters.length === 0) {
    return conditions;
  }

  filters.filter(filter => filter.value).forEach(filter => {
    if (filter.value.length === 1) {
      let value = filter.value[0];
      if (filter.filterType === FilterType.Date || filter.filterType === FilterType.DateString) {
        if (value.startsWith("rel ")) {
          const [_, relativeAmount, relativeUnit] = value.split(" ");
          const relativeDate = moment().subtract(parseInt(relativeAmount), relativeUnit);
          if (filter.filterType === FilterType.DateString) {
            value = relativeDate.format("YYYY-MM-DD");
          } else {
            value = relativeDate.toDate();
          }
        } else if (value.startsWith("rng ")) {
          const [_, startDate, endDate] = value.split(" ");
          let startValue;
          let endValue;
          if (filter.filterType === FilterType.DateString) {
            startValue = moment(startDate).format("YYYY-MM-DD");
            endValue = moment(endDate).format("YYYY-MM-DD");
          } else {
            startValue = moment(startDate).format("YYYY-MM-DD");
            endValue = moment(endDate).format("YYYY-MM-DD");
          }
          conditions.push({
            filterType: "join",
            type: FilterJoinOperation.And,
            conditions: [
              {
                filterType: filter.filterType,
                colId: filter.field,
                type: FilterOperation.GreaterThanOrEqual,
                filter: startValue,
              },
              {
                filterType: filter.filterType,
                colId: filter.field,
                type: FilterOperation.LessThanOrEqual,
                filter: endValue,
              }
            ],
          });
          value = null;
        } else if (filter.filterType === FilterType.DateString) {
          value = moment(value).format("YYYY-MM-DD");
        } else {
          value = new Date(Date.parse(value));
        }
      }
      if (value != null) {
        conditions.push({
          filterType: filter.filterType,
          colId: filter.field,
          type: filter.filterOperation,
          filter: value,
        });
      }
    } else if (filter.value.length > 1) {
      conditions.push({
        filterType: "join",
        type: filter.valueJoinOperation,
        conditions: filter.value.map(value => ({
          filterType: filter.filterType,
          colId: filter.field,
          type: filter.filterOperation,
          filter: value,
        })),
      });
    }
  });

  return conditions;
};

export const getFiltersFromFilterConditions = (filterConditions): Array<Filter> => {
  const filters = [];

  if (!filterConditions || filterConditions.length === 0) {
    return filters;
  }

  filterConditions.forEach(filterCondition => {
    if (filterCondition.filterType === "join") {
      filterCondition.conditions.forEach(condition => {
        filters.push({
          key: condition.colId,
          value: condition.filter,
          filterType: condition.filterType,
          filterOperation: condition.type,
          type: filterCondition.type,
        });
      });
    } else {
      filters.push({
        key: filterCondition.colId,
        value: [filterCondition.filter],
        filterType: filterCondition.filterType,
        filterOperation: filterCondition.type,
      });
    }
  });

  return filters;
};

const urlFilterOperations = {
  [FilterOperation.NotEqual]: "<>",
  [FilterOperation.LessThan]: "<",
  [FilterOperation.LessThanOrEqual]: "<=",
  [FilterOperation.GreaterThan]: ">",
  [FilterOperation.GreaterThanOrEqual]: ">=",
}

export const getFilterObjectFromUrlParams = (urlParams: URLSearchParams, mergeFilterObject: FilterObject): FilterObject => {
  const filters = mergeFilterObject.filters.map(filter => {
    if (urlParams.has(filter.field)) {
      let fieldValue = urlParams.get(filter.field);
      let joinOperation = filter.valueJoinOperation;
      let filterOperation = filter.filterOperation;

      Object.keys(urlFilterOperations).forEach(operation => {
        const urlOperation = urlFilterOperations[operation as FilterOperation];
        if (fieldValue.startsWith(`${urlOperation} `)) {
          filterOperation = operation as FilterOperation;
          fieldValue = fieldValue.replace(`${urlOperation} `, "");
        }
      });
      
      for (const entry in FilterJoinOperation) {
        const operation = FilterJoinOperation[entry as keyof typeof FilterJoinOperation];
        const values = fieldValue.split(` ${operation} `);
        if (values.length > 1) {
          joinOperation = operation as FilterJoinOperation;
          break;
        }
      }

      const value = fieldValue.split(` ${joinOperation} `);
      return {
        ...filter,
        value: value,
        valueJoinOperation: joinOperation,
        filterOperation: filterOperation,
      };
    }
    return filter;
  });
  return sanitizeFilterObject({
    ...mergeFilterObject,
    filters,
  });
};

export const getUrlParamsFromFilterObject = (filterObject: FilterObject): URLSearchParams => {
  const urlParams = new URLSearchParams();
  filterObject.filters.forEach(filter => {
    const joinOperator = ` ${filter.valueJoinOperation} `;
    if (filter.value?.length > 0) {
      let valueString = filter.value.join(joinOperator);
      const urlFilterOperation = urlFilterOperations[filter.filterOperation];
      if (urlFilterOperation) {
        valueString = `${urlFilterOperation} ${valueString}`;
      }
      urlParams.set(filter.field, valueString);
    }
  });
  return urlParams;
};

const SQL_FILTER_OPERATIONS = {
  [FilterOperation.Equals]: "=",
  [FilterOperation.NotEqual]: "<>",
  [FilterOperation.LessThan]: "<",
  [FilterOperation.LessThanOrEqual]: "<=",
  [FilterOperation.GreaterThan]: ">",
  [FilterOperation.GreaterThanOrEqual]: ">=",
  [FilterOperation.Contains]: "LIKE",
  [FilterOperation.NotContains]: "NOT LIKE",
  [FilterOperation.StartsWith]: "LIKE",
  [FilterOperation.EndsWith]: "LIKE",
  [FilterOperation.Blank]: "IS NULL OR ''",
  [FilterOperation.NotBlank]: "IS NOT NULL AND ''",
  [FilterOperation.True]: "= TRUE",
  [FilterOperation.False]: "= FALSE",
};

export const filterObjectToSqlQuery = (filterObject, columnPrefix="") => {
  if (!filterObject || !filterObject.filterType) {
    throw new Error("Can't convert ag-grid filter to SQL: invalid filter object");
  }

  const colId = columnPrefix ? `${columnPrefix}.${filterObject.colId}` : filterObject.colId;

  switch (filterObject.filterType) {
    case FilterType.Join:
      return filterObject.conditions.map(condition => `(${filterObjectToSqlQuery(condition)})`).join(` ${filterObject.type} `);
    case FilterType.Number:
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} ${filterObject.filter}`;
    case FilterType.Text:
      let filterValue = filterObject.filter;
      if (filterObject.type === FilterOperation.StartsWith) {
        filterValue = `${filterObject.filter}%`;
      } else if (filterObject.type === FilterOperation.EndsWith) {
        filterValue = `%${filterObject.filter}`;
      } else if (filterObject.type === FilterOperation.Contains) {
        filterValue = `%${filterObject.filter}%`;
      } else if (filterObject.type === FilterOperation.NotContains) {
        filterValue = `%${filterObject.filter}%`;
      }
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} '${filterValue}'`;
    case FilterType.Boolean:
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]}`;
    case FilterType.Object: // The values of this are an array converted to a comma separated string
      const values = filterObject.filter.replaceAll("\\,", "\\|").split(",").map(value => value.replaceAll("\\|", "\\\\,"));
      if (filterObject.type === FilterOperation.Contains) {
        return values.map(value => `${colId} LIKE '%${value}%'`).join(" AND ");
      } else if (filterObject.type === FilterOperation.NotContains) {
        return values.map(value => `${colId} NOT LIKE '%${value}%'`).join(" AND ");
      } else {
        return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} ('${values.join("','")}')`;
      }
    case FilterType.Date:
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} '${filterObject.filter}'`;
    case FilterType.DateString:
      return `${colId} ${SQL_FILTER_OPERATIONS[filterObject.type]} '${filterObject.filter}'`;
  }
};

export const convertFilterObjectsCompatV1 = (filterObjects, defaultFilterObject) => {
  const defaultFilterIpField = defaultFilterObject.filters.find(filter => filter.field === "ip");
  const convertedFilterObjects = filterObjects.map(f => {
    if ((!f.filters.find(filter => filter.field === "ip" || filter.key === "ip")) && defaultFilterIpField) {
      const newTitleFilter = { ...defaultFilterIpField };
      if (f.titleSearchQuery?.length > 0) {
        newTitleFilter.value = [f.titleSearchQuery];
        delete f.titleSearchQuery;
      }
      f.filters = [newTitleFilter, ...f.filters];
    }
    f.filters = f.filters.map(filter => {
      if (filter.key != null) {
        filter.field = filter.key;
        delete filter.key;
      }
      if (filter.name != null) {
        filter.label = filter.name;
        delete filter.name;
      }
      if (filter.controlOptions == null) {
        const newControlOptions = { type: filter.filterType === FilterType.Object ? FilterControlType.Set : filter.filterType === FilterType.Date ? FilterControlType.Date : FilterControlType.Text } as FilterControlOptions;
        if (filter.autoComplete != null) {
          newControlOptions.autoComplete = filter.autoComplete;
          delete filter.autoComplete;
        }
        if (filter.allowBlank != null) {
          newControlOptions.allowBlank = filter.allowBlank;
          delete filter.allowBlank;
        }
        if (filter.vertical != null) {
          newControlOptions.filterVertical = filter.vertical;
          delete filter.vertical;
        }
        if (newControlOptions.type === FilterControlType.Set) {
          newControlOptions.selectMultiple = true;
        }
        filter.controlOptions = newControlOptions;
      }
      return filter;
    });
    return f;
  });
  return convertedFilterObjects;
};

export const convertFilterObjectsCompat = (filterObjects, defaultFilterObject) => {
  return convertFilterObjectsCompatV1(filterObjects, defaultFilterObject);
};

export const sanitizeFilterItem = (filterListItem: Filter) => {
  let newFilterItem = filterListItem;
  if (filterListItem.filterType === FilterType.Object) {
    if (filterListItem.value?.includes("Is blank")) {
      newFilterItem._origFilterOperation = newFilterItem.filterOperation;
      newFilterItem.filterOperation = FilterOperation.Blank;
    } else if (filterListItem.value?.includes("Is not blank")) {
      newFilterItem._origFilterOperation = newFilterItem.filterOperation;
      newFilterItem.filterOperation = FilterOperation.NotBlank;
    } else if (filterListItem._origFilterOperation && ![FilterOperation.Blank, FilterOperation.NotBlank].includes(filterListItem._origFilterOperation as FilterOperation)) {
      newFilterItem.filterOperation = newFilterItem._origFilterOperation as FilterOperation;
      delete newFilterItem._origFilterOperation;
    }
  } else if (filterListItem.filterType === FilterType.Text) {
    if (filterListItem.value?.[0] === "") {
      delete filterListItem.value;
    }
  }
  if (filterListItem.value?.length === 0) {
    delete newFilterItem.value;
  }
  return newFilterItem;
};

export const sanitizeFilterObject = (filterObject: FilterObject) => {
  const newFilterObject = { ...filterObject };
  if (newFilterObject.hasOwnProperty("advancedFilter") && !newFilterObject.advancedFilter) {
    delete newFilterObject.advancedFilter;
  }
  newFilterObject.filters = newFilterObject.filters.map(sanitizeFilterItem);
  return newFilterObject;
};