import React from "react";
import { Vertical } from "../../../../../types/verticalTypes";
import { formattedTrendBadge } from "../../../../../utils/trendIcon";
import { Link } from "@cloudscape-design/components";
import { VerticalIcon } from "../../../../../components/VerticalIcon";

const verticalRankOptions = {
  [Vertical.Movies]: {
    label: "Movies Rank",
    href: "/dashboards/globalranking/movies",
    icon: (<VerticalIcon vertical={Vertical.Movies} className="w-full h-full" />),
  },
  [Vertical.Series]: {
    label: "Series Rank",
    href: "/dashboards/globalranking/series",
    icon: (<VerticalIcon vertical={Vertical.Series} className="w-full h-full" />),
  },
  [Vertical.Games]: {
    label: "Games Rank",
    href: "/dashboards/globalranking/gaming",
    icon: (<VerticalIcon vertical={Vertical.Games} className="w-full h-full" />),
  },
};

interface GlobalScoreProps {
  rank: number;
  rankChange: number;
  vertical: Vertical;
}

const GlobalScore: React.FC<GlobalScoreProps> = ({
  rank,
  rankChange,
  vertical,
}) => {

  const rankProps = verticalRankOptions[vertical];

  return (
    <div className="flex items-center space-x-2 bg-slate-400/10 shadow-md rounded-md p-2">
      {/*typeof rankProps.icon === "string" ? (
        <img src={rankProps.icon} title={rankProps.label} className="h-16" />
      ) : (
        <div className="w-16 h-16">{rankProps.icon}</div>
      )*/}
      <div className="text-black dark:text-slate-100">
        <div className="flex gap-2 items-center">
          <div className="text-2xl dark:text-slate-50 font-bold leading-[1.5rem]">
            #{!!rank ? rank.toLocaleString() : "N/A"}
          </div>
          {rankChange != null && (
            formattedTrendBadge(rankChange)
          )}
        </div>

        {rankProps.href ? (
          <Link 
            href={rankProps.href}
          >
            <div className="text-xs font-bold">{rankProps.label}</div>
          </Link>
        ) : (
          <div className="text-xs font-bold">{rankProps.label}</div>
        )}

        <div className="text-xs italic dark:text-slate-400">
          Based on the past week
        </div>
      </div>
    </div>
  );
};

export default GlobalScore;