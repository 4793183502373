import moment from "moment";
import { useCallback, useMemo } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../hooks/UseTheme/useGetChartTheme";
import { utcTimestampToLocal } from "../../../../utils/dateFormatter";
import shortenInteger from "../../../../utils/shortenInteger";
import shortenFloat from "../../../../utils/shortenFloat";

const defaultOverrides = {
  overrides: {
    common: {
      axes: {
        log: {
          label: {
            fontFamily: "Open Sans",
          },
          title: {
            fontFamily: "Open Sans",
          },
        },
        number: {
          label: {
            fontFamily: "Open Sans",
          },
          title: {
            fontFamily: "Open Sans",
          },
        },
        time: {
          label: {
            fontFamily: "Open Sans",
          },
          title: {
            fontFamily: "Open Sans",
          },
        },
        category: {
          label: {
            fontFamily: "Open Sans",
          },
          title: {
            fontFamily: "Open Sans",
          },
        },
        "ordinal-time": {
          label: {
            fontFamily: "Open Sans",
          },
          title: {
            fontFamily: "Open Sans",
          },
        },
      },
      series: {
        highlightStyle: {
          series: {
            dimOpacity: 0.2,
            strokeWidth: 2,
          },
        },
      },
      navigator: {
        miniChart: {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
      legend: {
        item: {
          label: {
            fontFamily: "Open Sans",
          },
          line: { strokeWidth: 12 },
        },
      },
      crosshair: {
        label: {
          fontFamily: "Open Sans",
        },
      },
    },
  },
};

const calculateMedian = (data) => {
  const sortedData = data.slice().sort((a, b) => a.dailyRank - b.dailyRank);
  const middle = Math.floor(sortedData.length / 2);

  if (sortedData.length % 2 === 0) {
    return (
      (sortedData[middle - 1].dailyRank + sortedData[middle].dailyRank) / 2
    );
  } else {
    return sortedData[middle].dailyRank;
  }
};

const splitDataByMedian = (data, median) => {
  const aboveMedian = [];
  const belowMedian = [];

  data.forEach((point, index) => {
    if (point.dailyRank >= median) {
      if (index > 0 && data[index - 1].dailyRank < median) {
        const slope =
          (point.dailyRank - data[index - 1].dailyRank) /
          (point.timestamp - data[index - 1].timestamp);
        const yIntercept = point.dailyRank - slope * point.timestamp;
        const medianTimestamp = (median - yIntercept) / slope;
        const medianPoint = {
          ...point,
          timestamp: medianTimestamp,
          dailyRank: median,
        };
        aboveMedian.push(medianPoint);
        belowMedian.push(medianPoint);
      }
      aboveMedian.push(point);
    } else {
      if (index > 0 && data[index - 1].dailyRank >= median) {
        const slope =
          (point.dailyRank - data[index - 1].dailyRank) /
          (point.timestamp - data[index - 1].timestamp);
        const yIntercept = point.dailyRank - slope * point.timestamp;
        const medianTimestamp = (median - yIntercept) / slope;
        const medianPoint = {
          ...point,
          timestamp: medianTimestamp,
          dailyRank: median,
        };
        aboveMedian.push(medianPoint);
        belowMedian.push(medianPoint);
      }
      belowMedian.push(point);
    }
  });

  return { aboveMedian, belowMedian };
};

export const useBasicChartConfig = (data, crosslines = [], itemName) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT, defaultOverrides);

  const hoveredPointData = useMemo(
    () =>
      data.map((point, index) => ({
        ...point,
        previousValue: index === 0 ? null : data[index - 1].dailyRank,
        previousZScoreValue: index === 0 ? null : data[index - 1].zscore,
        timestamp: utcTimestampToLocal(point.timestamp, true, "day"),
      })),
    [data],
  );

  const crosshairTooltipRenderer = useCallback(
    (params) => {
      const hoveredMasterTimestamp = utcTimestampToLocal(moment.utc(params.value).startOf("day").unix() * 1000, true, "day");

      const selectedPoint = hoveredPointData.find(
        (point) => point.timestamp === hoveredMasterTimestamp,
      );

      if (!selectedPoint) return null;

      let rankTrendColor = "text-neutral-300";
      let rankTrendIcon = "&#8210;";
      let rankDiff = 0;

      let zscoreTrendColor = "text-neutral-300";
      let zscoreTrendIcon = "&#8210;";
      let zscoreDiff = 0;

      if (selectedPoint.previousValue === null) {
        rankTrendColor = "text-green-500";
        rankTrendIcon = "&#9650;";
        rankDiff = selectedPoint.dailyRank;
        zscoreTrendColor = "text-green-500";
        zscoreTrendIcon = "&#9650;";
        zscoreDiff = selectedPoint.zscore;
      } else {
        rankDiff = selectedPoint.previousValue - selectedPoint.dailyRank;
        rankTrendIcon = rankDiff > 0 ? "&#9650;" : rankDiff < 0 ? "&#9660;" : "&#8210;";
        rankTrendColor = rankDiff > 0 ? "text-green-500" : rankDiff < 0 ? "text-red-500" : "text-neutral-300";
        zscoreDiff = selectedPoint.previousZScoreValue - selectedPoint.zscore;
        zscoreTrendIcon = zscoreDiff > 0 ? "&#9650;" : zscoreDiff < 0 ? "&#9660;" : "&#8210;";
        zscoreTrendColor = zscoreDiff > 0 ? "text-green-500" : zscoreDiff < 0 ? "text-red-500" : "text-neutral-300";
      }

      return `
      <div class="text-white p-2 rounded-md flex flex-col space-y-4" style="background-color: rgba(51, 65, 85, 0.8); font-family: 'Open Sans';">
        <div>
          <div class="mb-1">
            ${moment.utc(selectedPoint.timestamp).format("LL")}
          </div>
          <div class="w-full flex justify-between">
            <div class="flex gap-1 items-center">
              <div style="background-color: #5090dc; width: 10px; height: 3px; border-radius: 2px;"></div>
              <b>Rank</b>
            </div>
            <div class="flex items-center space-x-2 ml-3">
              <span class="${rankTrendColor}">
                ${rankTrendIcon}
                ${Math.abs(rankDiff).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </span>
              <span>
                ${selectedPoint.dailyRank}
              </span>
            </div>
          </div>
          <div class="w-full flex justify-between">
            <div class="flex gap-1 items-center">
              <div style="background-color: #ffa03a; width: 10px; height: 3px; border-radius: 2px;"></div>
              <b>Z-Score</b>
            </div>
            <div class="flex items-center space-x-2 ml-3">
              <span class="${zscoreTrendColor}">
                ${zscoreTrendIcon}
                ${Math.abs(zscoreDiff).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </span>
              <span>
                ${selectedPoint.zscore.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
      </div>  
    `;
    },
    [hoveredPointData, itemName],
  );

  const getLineChartOptions = useCallback(() => {
    if (!data || data.length === 0) {
      return {};
    }

    const medianRank = calculateMedian(data);
    const { aboveMedian, belowMedian } = splitDataByMedian(data, medianRank);

    return {
      tooltip: { enabled: false },
      padding: { right: 0, bottom: 1, left: 0, top: 25 },
      minWidth: 0,
      minHeight: 0,
      theme: theme,
      legend: { enabled: true, position: "bottom", spacing: 12 },
      series: [
        {
          type: "line",
          xKey: "timestamp",
          yKey: "dailyRank",
          yName: "Rank",
          marker: { enabled: false },
          //stroke: "red",
          //data: aboveMedian,
          data: data,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "zscore",
          yName: "Z-Score",
          marker: { enabled: false },
          //stroke: "red",
          //data: aboveMedian,
          data: data,
        },
        /*{
          type: "line",
          xKey: "timestamp",
          yKey: "dailyRank",
          yName: "Daily Ranking (Below Median)",
          marker: { enabled: false },
          stroke: "green",
          data: belowMedian,
        },*/
      ],
      sync: { enabled: true, axes: "x", nodeInteraction: true },
      axes: [
        {
          type: "time",
          position: "bottom",
          nice: false,
          crossLines: crosslines,
          crosshair: {
            enabled: true,
            label: {
              enabled: true,
              yOffset: -200,
              renderer: crosshairTooltipRenderer,
            },
            snap: false,
          },
        },
        {
          type: "log",
          position: "left",
          reverse: true,
          keys: ["dailyRank"],
          nice: false,
          crosshair: { enabled: false },
          gridLine: {
            enabled: false,
          },
          label: {
            formatter: function (params) {
              return shortenInteger(params.value);
            },
          },
          title: {
            text: "Rank",
            spacing: 12,
          }
          /*crossLines: [
            {
              type: "line",
              value: medianRank,
              stroke: "gray",
              strokeWidth: 2,
              label: {
                text: `Median: ${medianRank}`,
                position: "right",
                color: "grey",
                fontSize: 12,
              },
            },
          ],*/
        },
        {
          type: "number",
          position: "right",
          reverse: false,
          keys: ["zscore"],
          nice: false,
          crosshair: { enabled: false },
          gridLine: {
            enabled: true,
          },
          label: {
            formatter: function (params) {
              return shortenFloat(params.value);
            },
          },
          title: {
            text: "Z-Score",
            spacing: 12,
          }
        },
      ],
    };
  }, [data, theme, crosslines, crosshairTooltipRenderer]);

  return { getLineChartOptions };
};
