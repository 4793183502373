import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getUserData } from "../request";


export const useGetUserData = ({
  username,
}) => {
  const params = {
    username,
  };

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_USER_DATA, { ...params }],
    () => getUserData(params),
  );

  return {
    data: data?.data,
    isLoading,
    error,
  };
};