import moment from "moment";
import axiosInstance from "../../../utils/axios";
import { DateRangeType } from "../pages/components/DateRange";

export const getItemCompareData = (ipId: string, imbdId: string) =>
  axiosInstance.get(`item/compare/${ipId}?imdb_id=${imbdId}`);

export const getTopSearchData = (query: string) =>
  axiosInstance.get(
    `topsearch?keyword=${query}&fields=ip,ip_id,imdb_id,release_date&vertical=Movies`,
  );

export const getItemWikipediaSummaryData = (id: string) =>
  axiosInstance.request({
    url: `/wikipedia/summary?wikidataId=${id}`,
    method: "GET",
  });

export type PostYoutubeVideosParams = {
  videoIds: Array<string>;
  ip: string;
  ipId: string;
};

export const postYoutubeVideos = ({
  videoIds = [],
  ip = "",
  ipId = "",
}: PostYoutubeVideosParams) =>
  axiosInstance.post("youtube/v1/videos", {
    video_ids: videoIds,
    ip,
    ip_id: ipId,
  });

export type DeleteVideosParamsType = { payload: Array<string> };

export const deleteVideos = ({ payload }: DeleteVideosParamsType) =>
  axiosInstance.request({
    url: `/youtube/v1/videos`,
    method: "DELETE",
    headers: {
      "Content-Type": "text/plain",
    },
    data: payload,
  });

export type GetTrendsDataType = {
  ipId: string;
  type: string;
  dateRange: DateRangeType;
  enabled?: boolean;
};

export const getTrendsData = async ({
  ipId,
  type,
  dateRange,
}: GetTrendsDataType) => {
  const startDate = moment(dateRange?.startDate).unix();
  const endDate = moment(dateRange?.endDate).unix();

  return await axiosInstance.get(
    `${type}/${ipId}?gte=${startDate}&lte=${endDate}&fetch=ByID`,
  );
};

export type GetInstagramHashtagsDataType = {
  params: { tags: Array<string> };
};

export const getInstagramHashtagsData = async ({
  params,
}: GetInstagramHashtagsDataType) => {
  return await axiosInstance.request({
    url: `/realtime/instagram`,
    method: `GET`,
    params,
  });
};
