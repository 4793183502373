import React, { useMemo, useState } from "react";
import { useGetUserData } from "../../../api/hooks/useGetUserData";
import { Autosuggest, Container, FormField, Input, Select, SpaceBetween, TokenGroup } from "@cloudscape-design/components";
import { useAuthContext } from "../../../../../../auth/useAuthContext";
import { listOptions } from "../../../constants";
import { useCreateReportContext } from "../hooks/useCreateReportContext";

export const BasicDetails: React.FC = () => {
  const { user } = useAuthContext();

  const {
    reportName,
    setReportName,
    selectedListOption,
    setSelectedListOption,
    shareWithUsers,
    setShareWithUsers,
    verifyErrors,
  } = useCreateReportContext();

  const [userAutoSuggestValue, setUserAutoSuggestValue] = useState("");
  const [userAutoSuggestLoadValue, setUserAutoSuggestLoadValue] = useState("");

  const { data: userData, isLoading: userDataLoading, error: userDataError } = useGetUserData({
    username: userAutoSuggestLoadValue,
  });

  const userAutoSuggestOptions = useMemo(() => {
    if (!userData) return [];

    return [
      {
        value: userData.user_name,
        label: userData.name,
        tags: [userData.email_address, userData.organization],
      },
    ];
  }, [userData]);

  return (
    <Container>
      <SpaceBetween direction="vertical" size="l">
        <FormField label="Name" errorText={(verifyErrors.includes("report_name_empty") && reportName.length === 0) ? "Report name cannot be empty" : null}>
          <Input 
            value={reportName} 
            placeholder="Enter a name for your report" 
            onChange={({ detail }) => setReportName(detail.value)}
          />
        </FormField>
        <FormField label="Save to list">
          <Select
            options={listOptions}
            selectedOption={selectedListOption}
            onChange={({ detail }) => setSelectedListOption(detail.selectedOption)}
          />
        </FormField>
        <FormField label={<span>Share with{" "}<i>- optional</i></span>}>
          <>
            <Autosuggest
              onChange={({ detail }) => setUserAutoSuggestValue(detail.value)}
              value={userAutoSuggestValue}
              onLoadItems={({ detail }) => setUserAutoSuggestLoadValue(detail.filteringText)}
              onSelect={({ detail }) => {
                if (!detail.selectedOption) return;
                const user = detail.selectedOption;
                setShareWithUsers([...shareWithUsers, user]);
                setUserAutoSuggestValue("");
              }}
              options={userAutoSuggestOptions}
              placeholder="Add users by email or username"
              empty="No users found"
              loadingText="Loading users..."
              statusType={userDataLoading ? "loading" : "finished"}
              enteredTextLabel={(value) => `Use: ${value}`}
            />
            <TokenGroup
              items={shareWithUsers.map(u => ({ ...u, disabled: u.value === user.username }))}
              onDismiss={({ detail: { itemIndex } }) => {
                setShareWithUsers([
                  ...shareWithUsers.slice(0, itemIndex),
                  ...shareWithUsers.slice(itemIndex + 1)
                ]);
              }}
            />
          </>
        </FormField>
      </SpaceBetween>
    </Container>
  );
};