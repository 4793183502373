/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, ButtonDropdown, PropertyFilterProps, SpaceBetween } from "@cloudscape-design/components";
import React, { FC, useState } from "react";
import { ApiURLParams } from "../../services/generic_v2/requests";
import { DeleteFilterModalV2 } from "./DeleteFilterModal_V2";
import { SaveOrUpdateFilterModalV2 } from "./SaveOrUpdateFilterModal_V2";
import { FaSave } from "react-icons/fa";
import { FaFilterCircleXmark } from "react-icons/fa6";

export type GridFilterProps = {
  colId: string;
  filter: string | number;
  filterType: string;
  type: string;
};

type AdvancedFiltersProps = {
  apiParams: ApiURLParams;
  filter: PropertyFilterProps.Query | GridFilterProps;
  existingPref: any;
  selectedFilterName: string;
  getPreferencesAfterDelete: (name: string) => void;
  getPreferencesAfterSaveOrUpdate: (
    name: string,
    prevName: string,
    isUpdate: boolean,
  ) => void;
  handleClearFilter: () => void;
  handleFilterItemClick: (id: string, filters: any) => void;
  handleSetFilterName?: (id: string) => void;
  defaultFilters?: any;
  vertical: string;
};

export const AdvancedFiltersV2: FC<AdvancedFiltersProps> = ({
  apiParams,
  filter,
  selectedFilterName,
  existingPref,
  handleClearFilter,
  handleFilterItemClick,
  handleSetFilterName,
  getPreferencesAfterDelete,
  getPreferencesAfterSaveOrUpdate,
  vertical,
  defaultFilters={},
}) => {
  const initialModalStatus = {
    save: false,
    update: false,
    delete: false,
  };
  const currentFilters = existingPref?.data?.[vertical]?.filters ?? {};
  const filters = { ...currentFilters, ...defaultFilters };

  const [modalStatus, setModalstatus] = useState({ ...initialModalStatus });

  const onDismiss = () => setModalstatus({ ...initialModalStatus });

  return (
    <>
      <SpaceBetween direction="horizontal" size="xs">
        <ButtonDropdown
          items={[
            { id: "save", text: "Save filter", disabled: !filter },
            { 
              id: "load", 
              text: "Load filter", 
              items: Object.keys(filters).map((key) => ({
                text: key,
                id: `load_${key}`,
              })),
              disabled: Object.keys(filters).length === 0,
            },
            { 
              id: "delete", 
              text: "Delete filter", 
              items: Object.keys(filters).map((key) => ({
                text: key,
                id: `delete_${key}`,
              })),
              disabled: Object.keys(filters).length === 0,
            },
            { id: "clear", text: "Clear filter", disabled: !filter },
          ]}
          onItemClick={({ detail }) => {
            switch (detail.id) {
              case "save":
                setModalstatus({ ...initialModalStatus, save: true });
                break;
              case "clear":
                handleClearFilter();
                break;
              default:
                if (detail.id.startsWith("load_")) {
                  const filterId = detail.id.replace("load_", "");
                  const filter = filters[filterId];
                  if (filter) {
                    handleFilterItemClick(filterId, filters);
                  }
                } else if (detail.id.startsWith("delete_")) {
                  const filterId = detail.id.replace("delete_", "");
                  const filter = filters[filterId];
                  if (filter) {
                    setModalstatus({ ...initialModalStatus, delete: true });
                  }
                }
            }
          }}
          expandableGroups
        >
          Filters
        </ButtonDropdown>
        {/** 
        <Button
          ariaLabel="Clear Filter"
          iconSvg={<div className="text-current flex items-center justify-center"><FaFilterCircleXmark className="w-full h-full" /></div>}
          disabled={!filter}
          onClick={handleClearFilter}
        />
        <Button
          ariaLabel="Save Filter"
          iconSvg={<div className="text-current flex items-center justify-center"><FaSave className="w-full h-full" /></div>}
          disabled={!filter}
          onClick={() => setModalstatus({ ...initialModalStatus, save: true })}
        />
        <Button
          ariaLabel="Edit Filter"
          iconName="edit"
          disabled={!filter}
          onClick={() => setModalstatus({ ...initialModalStatus, update: true })}
        />
        <Button
          ariaLabel="Delete Filter"
          iconName="remove"
          disabled={!filter}
          onClick={() => setModalstatus({ ...initialModalStatus, delete: true })}
        />
        */}
      </SpaceBetween>
      <SaveOrUpdateFilterModalV2
        title={`${modalStatus.save ? "Save" : "Update"} Filter`}
        visible={modalStatus.save || modalStatus.update}
        isUpdate={modalStatus.update}
        existingPref={existingPref}
        onDismiss={onDismiss}
        apiParams={apiParams}
        filterName={selectedFilterName?.length > 0 ? selectedFilterName : ""}
        getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
        handleSetFilterName={handleSetFilterName}
      />
      <DeleteFilterModalV2
        title="Delete"
        visible={modalStatus.delete}
        existingPref={existingPref}
        onDismiss={onDismiss}
        apiParams={apiParams}
        getPreferencesAfterDelete={getPreferencesAfterDelete}
        name={selectedFilterName}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};
