import moment from "moment";
import { useMemo } from "react";
import { useItemChartConfig } from "../../../../chartConfig";

export const useChartConfig = (data) => {
  const { trackedRedditTrendsChartOption } = useItemChartConfig();

  const redditTrendChartOption = useMemo(() => {
    if (data?.length === 0) return null;

    const dataWithDailyGrowth = data.map((d, index, array) => {
      const previousDaySubscribers =
        index === 0 ? d.subscriber_count : array[index - 1].subscriber_count;
      const previousDayUpvotes = 
        index === 0 ? d.score : array[index - 1].score;
      const previousDayComments = 
        index === 0 ? d.comments : array[index - 1].comments;
      return {
        ...d,
        timestamp: moment(d.timestamp * 1000).format("ll"),
        subscriberGrowth: d.subscriber_count - previousDaySubscribers,
        activeUsers: d.active_user_count,
        comments: d.comments,
        score: d.score,
        commentsGrowth: d.comments - previousDayComments,
        scoreGrowth: d.score - previousDayUpvotes,
      };
    });

    const newDataKeys = Object.keys(dataWithDailyGrowth[0]);
    const newChartOptions = {
      ...trackedRedditTrendsChartOption,
      data: dataWithDailyGrowth,
      series: trackedRedditTrendsChartOption.series
        .filter((x) => newDataKeys.includes(x.yKey)),
    };

    return newChartOptions;
  }, [data, trackedRedditTrendsChartOption]);

  return { redditTrendChartOption };
};
