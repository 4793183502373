import { useCallback } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../../utils/shortenInteger";

const tooltip = {
  renderer: ({ datum }) => {
    const date = new Date(datum.timestamp);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const humanReadableDate = date.toLocaleString("en-US", options);
    const title = `<span style="font-size: 12px">${humanReadableDate}</span>`;

    return {
      title: title,
      content: `
                <b>Peak Player Count:</b> ${shortenInteger(
                  datum.playerCountPeak,
                )} <br />
                <b>Average Player Count:</b> ${shortenInteger(
                  datum.playerCountAvg,
                )}<br/>
                <b>Recommendations:</b> ${shortenInteger(
                  datum.recommendations,
                )}<br/>
                <b>Positive Reviews:</b> ${shortenInteger(
                  datum.positiveReviews,
                )}<br/>
                <b>Negative Reviews:</b> ${shortenInteger(
                  datum.negativeReviews,
                )}<br/>
            `,
    };
  },
};

export const useChartConfig = () => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);

  const getLineChartOptions = useCallback(
    () => ({
      padding: {
        right: 0,
        bottom: 0,
        left: 0,
      },
      minWidth: 0,
      minHeight: 0,
      theme: theme,
      legend: {
        position: "bottom",
        spacing: 12,
      },
      series: [
        {
          type: "line",
          xKey: "timestamp",
          yKey: "playerCountPeak",
          yName: "Peak Player Count",
          marker: { enabled: false },
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "playerCountAvg",
          yName: "Average Player Count",
          marker: { enabled: false },
          tooltip,
        },
        // {
        //   type: "line",
        //   xKey: "timestamp",
        //   yKey: "recommendations",
        //   yName: "Recommendations",
        //   marker: { enabled: false },
        //   visible: false,
        //   tooltip,
        // },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "positiveReviews",
          yName: "Positive Reviews",
          marker: { enabled: false },
          lineDash: [4, 4],
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "negativeReviews",
          yName: "Negative Reviews",
          marker: { enabled: false },
          lineDash: [4, 4],
          tooltip,
        },
      ],
      axes: [
        {
          type: "ordinal-time",
          position: "bottom",
          label: {
            format: "%b %-d, %Y",
          },
          crosshair: {
            label: {
              format: "%b %-d, %Y",
            },
          },
        },
        {
          // type: axisTypeValue || "number",
          type: "number",
          position: "left",
          nice: true,
          keys: [
            "playerCountPeak",
            "playerCountAvg",
          ],
          title: {
            enabled: true,
            text: "Player Count",
            spacing: 12,
          },
          label: {
            formatter: function (params) {
              return shortenInteger(params.value);
            },
          },
        },
        {
          // type: axisTypeValue || "number",
          type: "number",
          position: "right",
          nice: true,
          keys: [
            "positiveReviews",
            "negativeReviews"
          ],
          title: {
            enabled: true,
            text: "Reviews",
            spacing: 12,
          },
          label: {
            formatter: function (params) {
              return shortenInteger(params.value);
            },
          },
        },
      ],
    }),
    [theme],
  );

  return { getLineChartOptions };
};
