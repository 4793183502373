import React, { FC, useEffect, useRef, useState } from 'react';
import { useChartConfig } from './hooks/useChartConfig';
import { AgCharts } from 'ag-charts-react';
import { Header, Multiselect, Select, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { CATEGORIES, Category, COUNTRIES, Country } from '../../constants';
import { getAllCategories } from '../../utils';
import CrosslineNotes from '../../../../../components/datapoint-comparison/components/TimeseriesChart/CrosslineNotes';
import { useGetGoogleSearchData } from '../../api/hooks/useGetGoogleSearchData';
import { useGetTimelineEvents } from '../../../../../hooks/TimelineEvents/useGetTimelineEvents';
import moment from 'moment';

const chartTypeOptions = [
  { value: "line", label: "Line" },
  { value: "area", label: "Distribution" },
];

const granularityOptions = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

interface TimeseriesChartProps {
  selectedTitle: any;
  dateRange: any;
  categories: Category[];
  countries: Country[];
}

export const TimeseriesChart: FC<TimeseriesChartProps> = ({
  selectedTitle,
  dateRange,
  categories,
  countries,
}) => {

  const chartRef = useRef(null);

  const [selectedCountries, setSelectedCountries] = useState<Country[]>([COUNTRIES.UNITED_STATES_OF_AMERICA]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>(getAllCategories(CATEGORIES));
  const [selectedChartType, setSelectedChartType] = useState(chartTypeOptions[0]);
  const [selectedGranularity, setSelectedGranularity] = useState(granularityOptions[0]);

  const [nestedData, setNestedData] = useState<any>({});

  const { data: googleSearchData, isLoading, error } = useGetGoogleSearchData({
    enabled: selectedTitle != null,
    ipIds: [selectedTitle?.ip_id],
    geoNames: countries.map(country => country.value),
    categories: categories.map(category => category.value),
    startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
    endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
  });

  const { data: timelineEvents, isLoading: timelineEventsLoading, error: timelineEventsError } = useGetTimelineEvents({
    enabled: selectedTitle != null,
    titleIds: [selectedTitle?.ip_id],
  });

  const chartOptions = useChartConfig({
    nestedData,
    latestDataDate: googleSearchData?.latest_data_date,
    selectedCategories,
    selectedCountries,
    selectedChartType,
    selectedGranularity,
    timelineEvents,
  });

  const processGoogleSearchData = (data: any) => {
    const keyInfo = data.key_info;
    const unstructuredData = data.data;

    const nestedData = {};
    keyInfo.forEach((item: any, index: number) => {
      const {
        ip_id: titleId,
        category: categoryValue,
        geo_name: countryValue,
        has_data: hasData,
      } = item;
      if (!hasData) return;
      if (!nestedData[titleId]) {
        nestedData[titleId] = {};
      }
      if (!nestedData[titleId][countryValue]) {
        nestedData[titleId][countryValue] = {};
      }
      if (!nestedData[titleId][countryValue][categoryValue]) {
        nestedData[titleId][countryValue][categoryValue] = unstructuredData.map(dataItem => {
          return {
            date: dataItem.date,
            value: dataItem.data[index],
          };
        });
      }
    });

    setNestedData(nestedData);
  };

  useEffect(() => {
    if (!googleSearchData) return;
    processGoogleSearchData(googleSearchData);
  }, [googleSearchData]);

  return (
    <div className="flex flex-col gap-4">
      <Header
        description={googleSearchData?.latest_data_date ? `Last updated on ${moment(googleSearchData?.latest_data_date).format("MMM DD, YYYY")}` : ""}
        actions={
          <SpaceBetween size="s" direction="horizontal">
            <Select
              options={granularityOptions}
              selectedOption={selectedGranularity}
              onChange={({ detail }) => setSelectedGranularity(detail.selectedOption as any)}
            />
            <Select
              options={chartTypeOptions}
              selectedOption={selectedChartType}
              onChange={({ detail }) => setSelectedChartType(detail.selectedOption as any)}
            />
            <Multiselect
              options={categories.filter(cat => googleSearchData?.key_info?.some((keyInfo: any) => keyInfo.category === cat.value && keyInfo.has_data))}
              selectedOptions={selectedCategories}
              onChange={({ detail }) => setSelectedCategories(detail.selectedOptions as any)}
              hideTokens
              placeholder="Select categories"
              disabled={isLoading}
            />
            <Multiselect
              options={countries.filter(cou => googleSearchData?.key_info?.some((keyInfo: any) => keyInfo.geo_name === cou.value && keyInfo.has_data))}
              selectedOptions={selectedCountries}
              onChange={({ detail }) => setSelectedCountries(detail.selectedOptions as any)}
              hideTokens
              placeholder="Select regions"
              disabled={isLoading}
            />
          </SpaceBetween>
        }
      >
        Search interest over time
      </Header>
      <div style={{ width: "100%", height: "500px" }} className="relative">
        {isLoading ? (
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="large" />
          </div>
        ) : (
          <>
            <AgCharts
              ref={chartRef}
              options={chartOptions as any}
            />
            <CrosslineNotes
              chartRef={chartRef}
              crosslines={chartOptions?.crosslines}
            />
          </>
        )}
      </div>
    </div>
  );
};