import {
  Box,
  Button,
  SpaceBetween,
  Header,
  Select,
  Pagination,
  Table,
  FormField,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthContext } from "../../../../../../auth/useAuthContext";
import axiosInstance from "../../../../../../utils/axios";
import createFlashMessage from "../../../../../../utils/createFlashMessage";
import CustomFlashBar from "../../../../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../../../../common/redux/flash-action";
import Filters from "../../../../components/Filters";
import Preferences from "../../../../components/Preferences";
import {
  getFilteredTalentData,
} from "../../redux/talent_action";

import { formatFilter, idMap } from "../../../../utils/filterUtils";
import { columnDefinitions } from "./columnDefinition";

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

const selectRoleOptions = [
  { label: "All roles", value: "all" },
  { label: "Actor", value: "actor" },
  { label: "Actress", value: "actress" },
  { label: "Director", value: "director" },
  { label: "Producer", value: "producer" },
  { label: "Writer", value: "writer" },
];

export const TalentHome = ({ itemData }) => {
  const { user } = useAuthContext();
  const { data, total, status, error } = useSelector(
    (state) => state.talentData,
  );
  const [preferences, setPreferences] = useState({
    wrapLines: true,
    stripedRows: true,
    contentDensity: "comfortable",
    pageSize: 50,
    visibleContent: [
      "name",
      "poster",
      "content_type",
      "searchable",
      "film_count",
      "series_count",
      "game_count",
    ],
  });
  const [sortingColumn, setSortingColumn] = useState({ id: "searchable" });
  const [sortingDescending, setSortingDescending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);
  const [role, setRole] = useState(selectRoleOptions[0]);

  const saveResponse = (pref) => {
    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          explore: {
            talent: {
              preferences: pref,
            },
          },
        }),
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.response?.data?.message,
          }),
        );
        getPref();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            content: e.response?.data?.message || "Failed to save preference ",
          }),
        );
      });
  };

  const getPref = () => {
    axiosInstance
      .get(`/preferences/personal/${user.username}/pantheon`)
      .then((d) => d.data)
      .then((d) => {
        if (d.explore?.talent?.preferences) {
          setPreferences(d.explore?.talent?.preferences);
        }
      });
  };

  const handlePreferencesChange = (newPreferences) => {
    saveResponse(newPreferences);
    setPreferences(newPreferences);
  };

  useEffect(() => {
    const from = (currentPage - 1) * preferences.pageSize;

    const sort = sortingColumn
      ? [
        {
          [`${idMap.get(sortingColumn.id)}`]: sortingDescending
            ? "desc"
            : "asc",
        },
      ]
      : [];
    const include = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "=") {
          return formatFilter(t);
        }
        if (t.operator === ":") {
          return formatFilter(t, "contain");
        }
        return null;
      })
      .filter((e) => e);
    const exclude = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "!=") {
          return formatFilter(t);
        }
        return null;
      })
      .filter((e) => e);
    const body = {
      sort,
      include: [
        ...include,
        ...(role.value !== "all" ? [{ match: { main_category: role.value } }] : []),
      ],
      exclude,
      include_condition: filteringQuery.operation,
    };
    const range = () => {
      if (!filteringQuery.tokens.map((e) => e.propertyKey)) {
        return null;
      }
    };

    getFilteredTalentData(from, preferences.pageSize, range(), body);
  }, [
    currentPage,
    preferences.pageSize,
    sortingColumn,
    sortingDescending,
    filteringQuery,
    role,
  ]);

  useEffect(() => {
    getPref();
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    if (status === "failed" && error) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: error,
        }),
      );
    }
  }, [status, error]);

  const selectFilterStyle = {
    marginLeft: '16px',
    minWidth: '200px'
  };

  return (
    <>
      <Table
        columnDefinitions={columnDefinitions}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        onSortingChange={(event) => {
          setSortingDescending(event.detail.isDescending);
          setSortingColumn(event.detail.sortingColumn);
        }}
        loading={status === "loading"}
        items={data}
        loadingText="Loading resources"
        wrapLines
        stickyHeader
        resizableColumns={true}
        variant="full-page"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No items</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No items to display.
            </Box>
          </Box>
        }
        header={
          <>
            <CustomFlashBar />
            <Header
              variant="h3"
              counter={<span>({total.toLocaleString()})</span>}
            >
              Talent
            </Header>
          </>
        }
        preferences={
          <Preferences
            preferences={preferences}
            handleChange={handlePreferencesChange}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={currentPage}
            pagesCount={Math.ceil(
              (total > 10000 ? 10000 : total) / preferences.pageSize,
            )}
            onChange={(page) => {
              setCurrentPage(page.detail.currentPageIndex);
            }}
          />
        }
        filter={
          <SpaceBetween size="xs" direction="horizontal">
            <Filters
              data={data}
              preferences={preferences}
              query={filteringQuery}
              handleChange={(detail) => {
                setFilteringQuery(detail);
                setCurrentPage(1);
              }}
            />
            <Select
              // inlineLabelText="Filter Role"
              options={selectRoleOptions}
              selectedAriaLabel="Selected"
              selectedOption={role}
              onChange={event => {
                setRole(event.detail.selectedOption);
                setCurrentPage(1);
              }}
              ariaDescribedby={null}
              expandToViewport={true}
            />
          </SpaceBetween>
        }
      />

    </>
  );
};
