import { useQuery } from "react-query";
import { DateRangeType } from "../../../components/DateRange";
import { QUERY_KEYS } from "../constants";
import {
  mapGetPlatformResponse,
  PlatformError,
} from "../mappers/mapGetPlatformResponse";
import { GetSteamDataResponseItem } from "../mappers/mapSteamResponse";
import { GetTwitchDataResponseItem } from "../mappers/mapTwitchResponse";
import { GetYoutubeDataResponseItem } from "../mappers/mapYoutubeResponse";
import { GetImdbDataResponseItem } from "../mappers/mapImdbResponse";
import { GetWikipediaDataResponseItem } from "../mappers/mapWikipediaResponse";
import { getPlatformData } from "../request";
import { GetRtDataResponseItem } from "../mappers/mapRtResponse";

export interface GetPlatformDataResponse {
  data: {
    [ipId: string]: {
      [platform: string]: Array<
        | GetTwitchDataResponseItem
        | GetSteamDataResponseItem
        | GetYoutubeDataResponseItem
        | GetImdbDataResponseItem
        | GetWikipediaDataResponseItem
        | GetRtDataResponseItem
      >;
    };
  };
  errors?: PlatformError;
}

interface UseGetPlatformDataProps {
  ipId: string;
  platform: string;
  dateRange: DateRangeType;
  includeZScore?: boolean;
  enabled?: boolean;
}

export const useGetPlatformData = ({
  ipId = "",
  platform,
  dateRange,
  includeZScore=false,
  enabled=true,
}: UseGetPlatformDataProps) => {
  const params = {
    ipId,
    platform,
    startDate: dateRange?.startDate,
    endDate: dateRange?.endDate,
    includeZScore,
  };
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_PLATFORM_DATA, { ...params }],
    () => getPlatformData(params),
    { enabled },
  );

  return {
    data: mapGetPlatformResponse({
      data: data?.data,
      ipId: params.ipId,
      platform: params.platform,
    }),
    isLoading,
    ...(error as any),
  };
};
