import { Wizard } from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { ColumnDefinition, DEFAULT_TITLE_LIST_NAME, listOptions, ReportDefinition, SortBy } from "../../constants";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { ApiURLParams } from "../../../../../services/generic_v2/requests";
import { SERVICES, TYPES } from "../../../../../config-global";
import { BasicDetails } from "./steps/BasicDetails";
import { SelectTitles } from "./steps/SelectTitles";
import { SelectColumns } from "./steps/SelectColumns";
import { CreateReportContext } from "./hooks/useCreateReportContext";
import { startReport } from "../../api/request";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "../../api/constants";
import { Review } from "./steps/Review";
import { useLayoutContext } from "../../../Layout";

interface CreateReportWizardProps {
  setWizardVisible: (visible: boolean) => void;
  wizardDefaults?: any;
}

export const CreateReportWizard: React.FC<CreateReportWizardProps> = ({
  setWizardVisible,
  wizardDefaults,
}) => {

  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.TOOLS, module: "reportbuilder", tab: "createreport" } as ApiURLParams;

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [isCreatingReport, setIsCreatingReport] = useState<boolean>(false);
  const [selectedTitlesLength, setSelectedTitlesLength] = useState<number>(0);
  const [verifyErrors, setVerifyErrors] = useState<Array<string>>([]);
  const [navigationOriginallyOpen, setNavigationOriginallyOpen] = useState<boolean>(true);
  const [lastDateRangeValue, setLastDateRangeValue] = useState<any>(null);
  const [lastDateValue, setLastDateValue] = useState<any>(null);

  const [reportName, setReportName] = useState<string>(wizardDefaults?.name ?? "");
  const [selectedListOption, setSelectedListOption] = useState<OptionDefinition>(listOptions.find(o => o.value === wizardDefaults?.list) ?? listOptions[0]);
  const [shareWithUsers, setShareWithUsers] = useState(wizardDefaults?.shared_with?.map(u => ({ value: u, label: u })) ?? []);
  const [selectedTitleList, setSelectedTitleList] = useState({ value: wizardDefaults?.report_definition?.title_list?.name ?? DEFAULT_TITLE_LIST_NAME });
  const [columnDefinitions, setColumnDefinitions] = useState<Array<ColumnDefinition>>(wizardDefaults?.report_definition?.column_definitions ?? []);
  const [sortBy, setSortBy] = useState<Array<SortBy>>(wizardDefaults?.report_definition?.sort_by ?? []);
  const [limit, setLimit] = useState<number>(wizardDefaults?.report_definition?.limit ?? null);

  const queryClient = useQueryClient();
  const { navigationOpen, setNavigationOpen } = useLayoutContext();

  const createReportDefinition = (): ReportDefinition => {
    return {
      name: reportName,
      version: 1,
      previous_report_id: null, // TODO: implement this
      save_list: selectedListOption.value as "personal" | "organization",
      share_with: shareWithUsers.map((user) => user.value),
      sort_by: sortBy,
      limit: limit,
      title_list: {
        name: selectedTitleList.value,
        scope: "personal", // TODO: title lists for orgs
      },
      column_definitions: columnDefinitions.map((col) => ({
        id: col.id,
        source: col.source,
        field: col.field,
        headerText: col.headerText,
        ...(col.options ? { options: col.options } : {}),
      })),
    };
  };

  const createReport = (reportDefinition: ReportDefinition) => {
    setIsCreatingReport(true);
    startReport({ reportDefinition }).then(() => {
      setIsCreatingReport(false);
      setWizardVisible(false);
      queryClient.invalidateQueries(QUERY_KEYS.GET_JOB_LIST_DATA);
    }).catch(error => {
      setIsCreatingReport(false);
      console.error(error);
    });
  };

  const verifyStep = (stepIndex: number) => {
    const errors = [];
    switch (stepIndex) {
      case 0:
        if (reportName.length === 0) {
          errors.push("report_name_empty");
        }
        break;
      case 1:
        if (selectedTitlesLength === 0) {
          errors.push("selected_titles_empty");
        }
        break;
      case 2:
        if (columnDefinitions.length === 0) {
          errors.push("column_definitions_empty");
        }
        break;
    }
    setVerifyErrors(errors);
    return errors.length === 0;
  };

  useEffect(() => {
    setNavigationOriginallyOpen(navigationOpen);
    setNavigationOpen(false);
  }, []);

  return (
    <CreateReportContext.Provider
      value={{
        reportName,
        setReportName,
        selectedListOption,
        setSelectedListOption,
        shareWithUsers,
        setShareWithUsers,
        selectedTitleList,
        setSelectedTitleList,
        columnDefinitions,
        setColumnDefinitions,
        sortBy,
        setSortBy,
        limit,
        setLimit,
        verifyErrors,
        selectedTitlesLength,
        setSelectedTitlesLength,
        setActiveStepIndex,
        lastDateRangeValue,
        setLastDateRangeValue,
        lastDateValue,
        setLastDateValue,
      }}
    >
      <Wizard
        i18nStrings={{
          stepNumberLabel: stepNumber =>
            `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) =>
            `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) =>
            `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Create report",
          optional: "optional"
        }}
        onNavigate={({ detail }) => {
          if (detail.requestedStepIndex <= activeStepIndex || verifyStep(activeStepIndex)) {
            setActiveStepIndex(detail.requestedStepIndex);
          }
        }}
        activeStepIndex={activeStepIndex}
        steps={[
          {
            title: "Basic details",
            description: "Enter a name for your report, select a list to save it to, and optionally share it with others",
            content: (
              <BasicDetails />
            ),
          },
          {
            title: "Select titles",
            description: "Filter and select the titles you want to include in your report",
            content: (
              <SelectTitles
                apiParams={apiParams}
              />
            ),
          },
          {
            title: "Select columns",
            description: "Choose the columns you want to include in your report",
            content: (
              <SelectColumns />
            ),
          },
          {
            title: "Review and create",
            description: "Verify the details of your report before creating it",
            content: (
              <Review />
            ),
          },
        ]}
        onCancel={() => {
          setWizardVisible(false);
          setNavigationOpen(navigationOriginallyOpen);
        }}
        onSubmit={() => {
          const reportDefinition = createReportDefinition();
          createReport(reportDefinition);
          setNavigationOpen(navigationOriginallyOpen);
        }}
        isLoadingNextStep={isCreatingReport}
      />
    </CreateReportContext.Provider>
  );
};