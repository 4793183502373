/**
 * useFilterDrawer.tsx
 * 
 * This hook is meant to be used for filtering of ag-grid tables.
 * It provides a filter drawer along with helper functions to interact with it.
 * For client-side filtering, provide a gridRef to automatically manage the advanced filter model.
 * For server-side filtering, use the filterQuery object to filter data in the backend.
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FilterObject, FilterOperation } from "../../types/filterTypes";
import { getFilterObjectFromUrlParams, getUrlParamsFromFilterObject, sanitizeFilterObject } from "../../utils/filters/filterUtils";
import { useSearchParams } from "react-router-dom";
import { useFilterQuery } from "../../modules/ranking/hooks/useFilterQuery";
import { useFilterPreferences } from "../../services/filterPreference/useFilterPreferences";
import { ApiURLParams } from "../../services/generic_v2/requests";
import { AppLayoutProps, Drawer, Icon } from "@cloudscape-design/components";
import { FiltersDrawer } from "../../modules/ranking/components/FiltersDrawer";
import _ from "lodash";

interface UseFilterDrawerProps {
  defaultFilterObject: FilterObject;
  prefsApiParams: ApiURLParams;
  gridRef?: any;
  treatAsDefault?: Record<string, string>;
  ignoreFields?: string[];
  applyOnChange?: boolean;
}

export const useFilterDrawer = ({
  defaultFilterObject,
  prefsApiParams,
  gridRef,
  treatAsDefault={},
  ignoreFields=[],
  applyOnChange,
}: UseFilterDrawerProps) => {
  const filterDrawerRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [filterObject, setFilterObject] = useState<FilterObject>(getFilterObjectFromUrlParams(searchParams, defaultFilterObject));

  const { primaryFilter } = useFilterPreferences({ 
    apiParams: prefsApiParams, 
    defaultFilterObject: defaultFilterObject,
  });

  const filterQuery = useFilterQuery({ filterObject, treatAsDefault });

  const filterDrawerContent = useMemo(() => (
    <FiltersDrawer
      ref={filterDrawerRef}
      prefsApiParams={prefsApiParams}
      filterObject={filterObject}
      setFilterObject={setFilterObject}
      defaultFilterObject={defaultFilterObject}
      applyOnChange={applyOnChange}
    />
  ), [filterObject]);

  const filterDrawerConfig = useMemo<AppLayoutProps.Drawer>(() => (
    {
      id: "filterDrawer",
      content: (
        <Drawer header="Filters">
          {filterDrawerContent}
        </Drawer>
      ),
      trigger: {
        iconName: "filter",
        iconSvg: (<Icon name="filter" />),
      },
      ariaLabels: {
        drawerName: "My Drawer",
        closeButton: "Close",
        triggerButton: "Open",
        resizeHandle: "Resize",
      },
      resizable: true,
      defaultSize: 290,
      badge: !_.isEqual(filterObject, defaultFilterObject),
    }
  ), [filterObject]);

  const setFilterValue = (field, value) => {
    const newFilterObject = sanitizeFilterObject({
      ...filterObject,
      filters: filterObject.filters.map(f => {
        if (f.field === field) {
          return {
            ...f,
            value: [value],
          };
        }
        return f;
      }),
    });
    setFilterObject(newFilterObject);
    filterDrawerRef.current?.setCurrentFilterObject(newFilterObject);
  };

  const setTableFilter = useCallback(() => {
    if (!gridRef?.current) return;

    const newFilterQuery = { ...filterQuery };
    if (newFilterQuery.colId != null && (treatAsDefault[newFilterQuery.colId] === newFilterQuery.filter || ignoreFields.includes(newFilterQuery.colId))) {
      gridRef.current?.api?.setAdvancedFilterModel(null);
      return;
    } else if (newFilterQuery.conditions) {
      newFilterQuery.conditions = newFilterQuery.conditions
        .filter(c => !(c.colId in treatAsDefault && treatAsDefault[c.colId] === c.filter) && !ignoreFields.includes(c.colId))
        .map(c => {
          if ((c.type === FilterOperation.Blank || c.type === FilterOperation.NotBlank) && c.filter != null) {
            delete c.filter;
            return c;
          }
          return c;
        });
    }
    gridRef.current?.api?.setAdvancedFilterModel(newFilterQuery);
  }, [gridRef, filterQuery]);

  useEffect(() => {
    if (!primaryFilter) return;
    // Merge the primary filter with any filters set in the URL params so we don't override them
    const mergedFilter = getFilterObjectFromUrlParams(searchParams, primaryFilter);
    setFilterObject(mergedFilter);
    filterDrawerRef.current?.setCurrentFilterObject(mergedFilter);
  }, [primaryFilter]);

  useEffect(() => {
    const filterUrlParams = getUrlParamsFromFilterObject(filterObject);
    const defaultFilterFields = defaultFilterObject.filters.map(f => f.field);
    setSearchParams(searchParams => {
      defaultFilterFields.forEach((field) => {
        if (searchParams.has(field)) {
          searchParams.delete(field);
        }
        const fieldValue = filterUrlParams.get(field);
        if (fieldValue && treatAsDefault[field] !== fieldValue) {
          searchParams.set(field, fieldValue);
        }
      });
      return searchParams;
    });
  }, [filterObject]);

  useEffect(() => {
    setTableFilter();
  }, [filterQuery]);

  useEffect(() => {
    // Wait for gridRef to be set (useEffect with 0 dependencies) and for grid API to be available (setTimeout with 0 timeout)
    setTimeout(() => {
      setTableFilter();
    }, 0);
  }, []);

  return {
    filterDrawerConfig,
    filterDrawerRef,
    filterDrawerContent,
    filterObject,
    filterQuery,
    setFilterValue,
    setTableFilter,
    setFilterObject,
  };
};