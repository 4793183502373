import { Button, ColumnLayout, Container, FormField, Header, SpaceBetween } from "@cloudscape-design/components";
import React from "react";
import { useCreateReportContext } from "../hooks/useCreateReportContext";
import { PreviewTable } from "../PreviewTable";
import { AgGridReact } from "ag-grid-react";
import { useSelectedTitlesGridConfig } from "../hooks/useSelectedTitlesGridConfig";
import { useGetGridTheme } from "../../../../../../hooks/UseTheme/useGetGridTheme";
import { useFetchServerSideGridData } from "../hooks/useFetchServerSideGridData";
import { getTitleListData } from "../../../api/request";

export const Review = () => {
  const {
    reportName,
    selectedListOption,
    shareWithUsers,
    setActiveStepIndex,
    columnDefinitions,
    selectedTitleList,
    sortBy,
    limit,
  } = useCreateReportContext();

  const { theme } = useGetGridTheme();
  const selectedTitlesGridOptions = useSelectedTitlesGridConfig({ selectionEnabled: false });
  const { dataSource: selectedTitlesDataSource } = useFetchServerSideGridData({ 
    fetchMethod: getTitleListData, 
    requestParams: { list: selectedTitleList.value },
  });

  return (
    <SpaceBetween size="m" direction="vertical">
      <Container
        header={
          <Header
            actions={
              <Button
                onClick={() => setActiveStepIndex(0)}
              >
                Edit
              </Button>
            }
          >
            Basic details
          </Header>
        }
      >
        <ColumnLayout columns={3}>
          <FormField label="Name">
            {reportName}
          </FormField>
          <FormField label="Save to list">
            {selectedListOption.label}
          </FormField>
          <FormField label="Share with">
            {shareWithUsers.length > 0 ? shareWithUsers.map((user) => user.label).join(", ") : "N/A"}
          </FormField>
        </ColumnLayout>
      </Container>
      <Container
        header={
          <Header
            actions={
              <Button
                onClick={() => setActiveStepIndex(1)}
              >
                Edit
              </Button>
            }
          >
            Selected titles
          </Header>
        }
      >
        <div className="flex flex-col gap-4">
          <FormField label="Title list">
            {selectedTitleList.value}
          </FormField>
          <div className={theme + " h-[50vh]"} style={{ "--ag-grid-size": "5px", "--ag-spacing": "5px" } as React.CSSProperties}>
            <AgGridReact
              pagination={true}
              paginationPageSizeSelector={[20, 50, 100]}
              rowModelType={"serverSide"}
              blockLoadDebounceMillis={100}
              serverSideDatasource={selectedTitlesDataSource}
              suppressRowClickSelection={true}
              overlayNoRowsTemplate="<span className='p-4 bold'>No titles selected</span>"
              {...selectedTitlesGridOptions}
            />
          </div>
        </div>
      </Container>
      <Container
        header={
          <Header
            actions={
              <Button
                onClick={() => setActiveStepIndex(2)}
              >
                Edit
              </Button>
            }
          >
            Selected columns
          </Header>
        }
      >
        <SpaceBetween size="m" direction="vertical">
          <ColumnLayout columns={2}>
            <FormField label="Sort by">
              {sortBy.length > 0 ? sortBy.map((sort, index) => (
                <div key={sort.column_id}>
                  {(index+1)}{". "}{columnDefinitions.find((col) => col.id === sort.column_id).headerText}{" "}({sort.order === "asc" ? "Ascending" : "Descending"})
                </div>
              )) : "N/A"}
            </FormField>
            <FormField label="Limit">
              {limit ?? "N/A"}
            </FormField>
          </ColumnLayout>
          <PreviewTable columnDefinitions={columnDefinitions} />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};