import React from "react";
import { FormField } from "@cloudscape-design/components";
import { formattedTrendBadge } from "../../../../../utils/trendIcon";

interface RankInfoProps {
  rankInfo?: any;
}

const RankInfo: React.FC<RankInfoProps> = ({
  rankInfo,
}) => {
  return (
    <div className="m-auto w-fit">
      <div className="flex flex-col sm:flex-row justify-center gap-4 border dark:border-gray-600 border-gray-400 shadow-md rounded-md py-2 px-4">
        <div className="flex justify-center gap-4">
          <FormField description={<b>Latest day</b>}>
            <div className="flex gap-2 items-center">
              <div className="dark:text-slate-50 font-bold leading-[1.5rem] whitespace-nowrap">
                #{rankInfo?.daily_rank?.toLocaleString() ?? "N/A"}
              </div>
              {formattedTrendBadge(rankInfo?.daily_rank_change)}
            </div>
          </FormField>
          <div className="border-0.5 border-l dark:border-gray-600 border-gray-400 hidden sm:block"></div>
          <FormField description={<b>Last 7d</b>}>
            <div className="flex gap-2 items-center">
              <div className="dark:text-slate-50 font-bold leading-[1.5rem] whitespace-nowrap">
                #{rankInfo?.weekly_rank?.toLocaleString() ?? "N/A"}
              </div>
              {formattedTrendBadge(rankInfo?.weekly_rank_change)}
            </div>
          </FormField>
        </div>
        <div className="border-0.5 border-l dark:border-gray-600 border-gray-400 hidden sm:block"></div>
        <div className="flex justify-center gap-4">
          <FormField description={<b>Last 30d</b>}>
            <div className="flex gap-2 items-center">
              <div className="dark:text-slate-50 font-bold leading-[1.5rem] whitespace-nowrap">
                #{rankInfo?.monthly_rank?.toLocaleString() ?? "N/A"}
              </div>
              {formattedTrendBadge(rankInfo?.monthly_rank_change)}
            </div>
          </FormField>
          <div className="border-0.5 border-l dark:border-gray-600 border-gray-400 hidden sm:block"></div>
          <FormField description={<b>All time</b>}>
            <div className="flex gap-2 items-center">
              <div className="dark:text-slate-50 font-bold leading-[1.5rem] whitespace-nowrap">
                #{rankInfo?.rank?.toLocaleString() ?? "N/A"}
              </div>
              {formattedTrendBadge(rankInfo?.rank_change)}
            </div>
          </FormField>
        </div>
      </div>
    </div>
  );
};

export default RankInfo;