import {
  Button,
  Multiselect,
  Select,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { PreferenceItem } from "../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { createOptionsField } from "../../../utils/selectUtils";
import { usePlatformContext } from "../PlatformProvider";
import { getCategories } from "../utils/categoryUtils";
import { PlatformFilters } from "./PlatformFilters";
import moment from "moment";
import axiosInstance from "../../../utils/axios";
import { useGenericPreference_V2 } from "../../../services/generic_v2/hooks/useGenericPreference_V2";

export const PlatformChartHeader = forwardRef(
  (
    {
      defaultColumn,
      additionalPlatformColumns,
      filter,
      selectedVertical,
      setSelectedVertical,
      selectedOptions,
      setSelectedOptions,
      apiParams,
      categoryOptions,
      flashbarRef,
    }: any,
    ref: any,
  ) => {
    const { platformPreference } = usePlatformContext();
    const [options, setOptions] = useState();

    const { preferenceData, savePreference } = useGenericPreference_V2({ apiParams });

    const path = `${apiParams.service}/${apiParams.module}/${apiParams.tab}`;
    const existingPref = platformPreference?.find(
      (item: PreferenceItem) => item.path === path,
    );

    useEffect(() => {
      const newOptions = additionalPlatformColumns
        .filter((key: string) =>
          getCategories(key)?.includes(selectedVertical.value),
        )
        .map(createOptionsField);

      setOptions(newOptions);
    }, [additionalPlatformColumns, selectedOptions.value, selectedVertical]);

    const handleExport = async () => {
      flashbarRef?.current?.setFlashbarMessage(
        "info",
        `${moment().format("YYYY-MM-DD HH:mm:ss")}. Generating report.`,
        null
      );

      try {
        const filepath = `platform/${apiParams.module === "wikipedia" ? "wiki" : apiParams.module === "rottentomatoes" ? "rt" : apiParams.module}_${selectedVertical.value}.parquet`;

        const response = await axiosInstance.post("/export", {
          filepath,
          include: [],
          exclude: [],
        });

        if (response.data) {
          const presignedUrl = response.data;

          const link = document.createElement("a");
          link.href = presignedUrl;
          link.setAttribute(
            "download",
            filepath.split("/").pop() || "export.parquet",
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          flashbarRef?.current?.setFlashbarMessage(
            "success",
            `${moment().format("YYYY-MM-DD HH:mm:ss")}. Report generated successfully.`,
            null,
          );
        }
      } catch (error) {
        console.error("Export failed:", error);
        flashbarRef?.current?.setFlashbarMessage(
          "error",
          `${moment().format("YYYY-MM-DD HH:mm:ss")}. Report generation failed. Please try again.`,
          null,
        );
      }
    };

    return (
      <div className="flex justify-between">
        <div className="flex gap-2">
          <Select
            selectedOption={selectedVertical}
            expandToViewport
            onChange={({ detail }) => {
              setSelectedVertical(detail.selectedOption);
              savePreference({
                category: detail.selectedOption.value,
              });
            }}
            controlId="select-vertical"
            placeholder="Category"
            options={categoryOptions}
          />
          <Multiselect
            selectedOptions={selectedOptions}
            onChange={({ detail }) => {
              setSelectedOptions(detail.selectedOptions);
              savePreference({
                [selectedVertical.value]: {
                  ...preferenceData?.[selectedVertical.value],
                  additionalColumns: detail.selectedOptions.map(
                    (option) => option.label,
                  ),
                },
              });
            }}
            options={options}
            placeholder="Additional columns"
            hideTokens
          />
        </div>
        <div className="flex gap-2">
          <Button iconName="download" onClick={handleExport}>
            Export
          </Button>
          <PlatformFilters
            existingPref={existingPref}
            filter={filter}
            vertical={selectedVertical.value}
            apiParams={apiParams}
            ref={ref}
          />
        </div>
      </div>
    );
  },
);
