import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";

export const PreviewTable = ({
  columnDefinitions=[],
}) => {

  const { theme } = useGetGridTheme();

  const columnDefs = useMemo(() => {
    return columnDefinitions.map((col) => {
      return {
        headerName: col.headerText,
        field: col.id,
        suppressMovable: true,
        sortable: false,
        suppressHeaderMenuButton: true,
        cellRenderer: (params) => {
          return (
            <div className="w-[60%] h-4 dark:bg-gray-600 bg-gray-300 mt-2 rounded-sm"></div>
          );
        },
      };
    });
  }, [columnDefinitions]);

  return (
    <div style={{ width: "100%", height: "100px", maskImage: "linear-gradient(to bottom, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%)" }} className={theme}>
      <AgGridReact
        columnDefs={columnDefs as any}
        rowData={[
          columnDefinitions.reduce((acc, col) => {
            acc[col.id] = col.headerText;
            return acc;
          }, {}),
        ]}
        suppressContextMenu
        suppressRowHoverHighlight
      />
    </div>
  );
};