import {
  AppLayout,
  AppLayoutProps,
  BreadcrumbGroup,
  SideNavigation,
  SideNavigationProps,
} from "@cloudscape-design/components";
import React, { createContext, FC, ReactNode, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  NavItemWithId,
  NavItemsWithId,
  SERVICES_GROUP,
} from "../../layouts/common/menu/side-menu";

type BreadcrumbsItem = {
  text: string;
  href?: string;
};

export const LayoutContext = createContext(null);

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};

interface LayoutProps {
  title: string;
  breadcrumbs: Array<BreadcrumbsItem>;
  navItems: NavItemsWithId;
  content: ReactNode;
  contentType?: AppLayoutProps.ContentType;
}

export const Layout: FC<LayoutProps> = ({
  title,
  breadcrumbs,
  navItems,
  content,
  contentType,
}) => {
  const navigate = useNavigate();

  const onFollowHandler = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const filteredNavItems = navItems.filter(
    (item: NavItemWithId) =>
      item.type === "section-group" && item?.id === SERVICES_GROUP.TOOLS,
  );

  const [navigationOpen, setNavigationOpen] = React.useState(true);

  return (
    <LayoutContext.Provider
      value={{
        navigationOpen,
        setNavigationOpen,
        onFollowHandler,
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        disableContentPaddings={false}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigationOpen={navigationOpen}
        navigationWidth={200}
        navigation={
          <SideNavigation
            onFollow={onFollowHandler}
            activeHref={window.location.pathname}
            items={filteredNavItems}
          />
        }
        onNavigationChange={({ detail: { open } }) => setNavigationOpen(open)}
        content={content}
        contentType={contentType}
        breadcrumbs={
          <BreadcrumbGroup
            onFollow={onFollowHandler}
            items={breadcrumbs as any}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
            onClick={({ detail }) => {
              const { item } = detail as any;
              if (item.onClick) {
                item.onClick();
              }
            }}
          />
        }
      />
    </LayoutContext.Provider>
  );
};
