import {
  Box,
  Container,
  ExpandableSection,
  Header,
  Icon,
  Link,
  Multiselect,
  SpaceBetween,
  Tabs,
  TextFilter
} from "@cloudscape-design/components";
import { AgCharts } from "ag-charts-react";
import { ColDef } from 'ag-grid-community';
import "ag-grid-enterprise";
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useState } from "react";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import { CHART_TYPES, useGetChartTheme } from "../../../../hooks/UseTheme/useGetChartTheme";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import renderTrend from "../../../../utils/trendIcon";
import TagList from "../../../item/pages/components/TagList";
import { Awards } from "../../../item/pages/movie/components/Awards";
import { useGetTalentData } from "../api/hooks/useGetTalentData";
import shortenInteger from "../../../../utils/shortenInteger";
import { addQueryParams, getQueryParams } from "../../../../utils/queryUtils";
import DetailCellRenderer from "./detailCellRenderer";

interface Award {
  awardName: string;
  year: string | number;
  category: string;
  title: Array<{ ip: string, ip_id: string }>;
}

interface FilmographyItem {
  ip: string;
  ip_id: string;
  daily_rank: number;
  weekly_rank: number;
  monthly_rank: number;
  imdb_vote_average?: number;
  imdb_vote_count?: number;
  rtFanSummary?: { score: string, rating: string };
  rtCriticSummary?: { score: string, numReviews: string, rating: string };
  roles: string[];
  billing?: number;
  attributes?: string[];
  category: string;
  type?: string;
  path?: string[];
  score?: number;
  weekly_sum?: number;
  monthly_sum?: number;
  genre?: string;
  distributors?: string;
  production?: string;
  month_score?: string;
  budget?: { amount: number };
  domestic?: number;
  international?: number;
  worldwide?: number;
  grossToDate?: Array<{
    area: string;
    grossToDate: number;
    rank: number;
  }>;
}

interface ColumnOption {
  label: string;
  value: string;
}

const calculateGenreConnections = (filmography: FilmographyItem[]) => {
  const connections: Array<{ from: string; to: string; size: number; titles: string[] }> = [];
  const genreMap = new Map<string, Map<string, Set<string>>>();

  filmography.forEach(item => {
    if (!item.genre) return;

    try {
      const genres = JSON.parse(item.genre.replace(/'/g, '"'));
      for (let i = 0; i < genres.length; i++) {
        for (let j = i + 1; j < genres.length; j++) {
          const from = genres[i];
          const to = genres[j];

          if (!genreMap.has(from)) {
            genreMap.set(from, new Map());
          }
          if (!genreMap.get(from)?.has(to)) {
            genreMap.get(from)?.set(to, new Set());
          }

          genreMap.get(from)?.get(to)?.add(item.ip);
        }
      }
    } catch {
      const genre = item.genre;
      if (!genreMap.has(genre)) {
        genreMap.set(genre, new Map());
      }
    }
  });

  genreMap.forEach((toGenres, fromGenre) => {
    toGenres.forEach((titles, toGenre) => {
      connections.push({
        from: fromGenre,
        to: toGenre,
        size: titles.size,
        titles: Array.from(titles)
      });
    });
  });

  return connections;
};

interface TalentComponentProps {
  id: string;
  onNameLoad: (name: string) => void;
}

export function TalentComponent({ id, onNameLoad }: TalentComponentProps) {
  const { theme: chartTheme } = useGetChartTheme(CHART_TYPES.DEFAULT);
  const { theme } = useGetGridTheme();
  const { data, isLoading, error } = useGetTalentData(id);
  const [selectedCategory, setSelectedCategory] = React.useState<string[]>([]);
  const [selectedMovieColumns, setSelectedMovieColumns] = React.useState<string[]>([]);
  const [selectedSeriesColumns, setSelectedSeriesColumns] = React.useState<string[]>([]);
  const [filterText, setFilterText] = React.useState('');
  const [selectedAwards, setSelectedAwards] = React.useState<string[]>([]);
  const [selectedYears, setSelectedYears] = React.useState<string[]>([]);
  const tabQuery = getQueryParams("tab");
  const detailCellRenderer = useCallback(DetailCellRenderer, []);

  const allowedCategories = ['Actor', 'Actress', 'Producer', 'Writer', 'Director', 'Self'];

  const processFilmographyData = (items: typeof data.filmography) => {
    return items
      .filter(item => !item.ip_id.startsWith('game-'))
      .map(item => ({
        ...item,
        type: item.ip_id.startsWith('film-') ? 'Movies'
          : item.ip_id.startsWith('series-') ? 'Series'
            : 'Other',
        path: [
          item.ip_id.startsWith('film-') ? 'Movies'
            : item.ip_id.startsWith('series-') ? 'Series'
              : 'Other',
          item.ip
        ]
      }))
      .sort((a, b) => (a.weekly_rank || 0) - (b.weekly_rank || 0));
  };

  const filteredFilmographyByCategory = React.useMemo(() => {
    if (!data?.filmography) return {};

    return Object.entries(
      data.filmography.reduce((acc, item) => {
        const category = item.category.charAt(0).toUpperCase() + item.category.slice(1);
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
        return acc;
      }, {} as Record<string, typeof data.filmography>)
    )
      .filter(([category]) => allowedCategories.includes(category))
      .sort(([categoryA], [categoryB]) => {
        if (categoryA === 'Self') return 1;
        if (categoryB === 'Self') return -1;
        return allowedCategories.indexOf(categoryA) - allowedCategories.indexOf(categoryB);
      })
      .reduce((acc, [category, items]) => {
        acc[category] = processFilmographyData(items);
        return acc;
      }, {} as Record<string, typeof data.filmography>);
  }, [data?.filmography, allowedCategories]);

  const [activeTabId, setActiveTabId] = useState('');

  useEffect(() => {
    if (!data) return;

    const validTabs = [
      ...Object.keys(filteredFilmographyByCategory),
      ...(data?.awards?.length > 0 ? ['awards'] : []),
      ...(social_ids?.length > 0 ? ['social-ids'] : [])
    ].map(tab => tab.toLowerCase());

    if (tabQuery && validTabs.includes(tabQuery)) {
      setActiveTabId(tabQuery);
    } else if (validTabs.length > 0 && !activeTabId) {
      setActiveTabId(validTabs[0]);
    }
  }, [data, filteredFilmographyByCategory, tabQuery]);

  const optionalColumns: Record<'Movies' | 'Series', ColumnOption[]> = {
    Movies: [
      { label: 'Genre', value: 'genre' },
      { label: 'Distributor', value: 'distributors' },
      { label: 'Production', value: 'production' },
      { label: 'Billing', value: 'billing' },
      { label: 'Attributes', value: 'attributes' },
      { label: 'Budget', value: 'budget' },
      { label: 'Domestic Gross', value: 'domestic' },
      { label: 'International Gross', value: 'international' },
      { label: 'Worldwide Gross', value: 'worldwide' },
      { label: 'IMDB Votes', value: 'imdb_vote_count' },
      { label: 'Rotten Tomatoes Critic Score', value: 'rtCriticSummary.score' },
      { label: 'Rotten Tomatoes Fan Votes', value: 'rtFanSummary.count' },
      { label: 'Rotten Tomatoes Critic Reviews', value: 'rtCriticSummary.numReviews' }
    ],
    Series: [
      { label: 'Genre', value: 'genre' },
      { label: 'Production', value: 'production' },
      { label: 'IMDB Votes', value: 'imdb_vote_count' },
      { label: 'Rotten Tomatoes Critic Score', value: 'rtCriticSummary.score' },
      { label: 'Rotten Tomatoes Fan Votes', value: 'rtFanSummary.count' },
      { label: 'Rotten Tomatoes Critic Reviews', value: 'rtCriticSummary.numReviews' }
    ]
  };

  const uniqueCategories = React.useMemo(() => {
    if (!data) return [{ label: "All categories", value: "" }];
    const categories = Array.from(new Set(data.awards.map(award => award.category)));
    return [
      { label: "All categories", value: "" },
      ...categories.map(category => ({
        label: category,
        value: category
      }))
    ];
  }, [data]);

  const uniqueAwardNames = React.useMemo(() => {
    if (!data) return [];
    const awards = Array.from(new Set(data.awards.map(award => award.awardName)));
    return awards.map(award => ({
      label: award,
      value: award
    }));
  }, [data]);

  const uniqueYears = React.useMemo(() => {
    if (!data) return [];
    const years = Array.from(new Set(data.awards.map(award => award.year.toString())));
    return years
      .sort((a, b) => Number(b) - Number(a)) // Sort years in descending order
      .map(year => ({
        label: year,
        value: year
      }));
  }, [data]);

  const filteredAndSortedAwards = React.useMemo(() => {
    if (!data) return [];
    let filtered = [...data.awards];
    if (selectedCategory.length > 0) {
      filtered = filtered.filter(award => selectedCategory.includes(award.category));
    }
    if (selectedAwards.length > 0) {
      filtered = filtered.filter(award => selectedAwards.includes(award.awardName));
    }
    if (selectedYears.length > 0) {
      filtered = filtered.filter(award => selectedYears.includes(award.year.toString()));
    }
    return filtered.sort((a, b) => Number(b.year) - Number(a.year));
  }, [data, selectedCategory, selectedAwards, selectedYears]);

  useEffect(() => {
    if (data?.name) {
      onNameLoad(data.name);
    }
  }, [data?.name, onNameLoad]);

  if (isLoading) {
    return <CustomLoadingOverlay />;
  }

  if (error || !data) {
    return (
      <Container>
        <SpaceBetween size="l">
          <Header variant="h2">
            {"Talent not found"}
          </Header>
          <div>Unable to load talent information. The talent you have requested may not exist in our database. <br />
            If you think this is an error, please contact support.
          </div>
        </SpaceBetween>
      </Container>
    );
  }

  const rowHeight = 35;
  const headerHeight = 50;
  const minRows = 1;
  const maxRows = 25;

  const filmographyByCategory = data.filmography?.reduce((acc, item) => {
    const category = item.category.charAt(0).toUpperCase() + item.category.slice(1);
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {} as Record<string, typeof data.filmography>);

  const renderDailyRank = (params: any) => {
    if (!params.data) return null;
    return renderTrend(params, "daily_rank", "daily_rank_change", "daily_rank_trend");
  };

  const renderWeeklyRank = (params: any) => {
    if (!params.data) return null;
    return renderTrend(params, "weekly_rank", "weekly_rank_change", "weekly_rank_trend");
  };

  const renderMonthlyRank = (params: any) => {
    if (!params.data) return null;
    return renderTrend(params, "monthly_rank", "monthly_rank_change", "monthly_rank_trend");
  };

  const defaultColDef = {
    flex: 1,
    sortable: true,
    suppressHeaderMenuButton: true,
    autoHeaderHeight: true,
    wrapHeaderText: true
  };

  // Create helper function for common column definitions
  const createCommonColumn = (
    field: 'genre' | 'production' | 'imdb_vote_count' | 'rtCriticSummary.score' | 'rtFanSummary.count' | 'rtCriticSummary.numReviews',
    headerName: string
  ): ColDef<FilmographyItem> => ({
    field: field as keyof FilmographyItem,
    headerName,
    valueGetter: (params: { data: FilmographyItem }) => {
      if (!params.data) return '';
      
      // Handle nested properties
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        const parentValue = params.data[parent];
        if (!parentValue) return '';
        
        // Handle rtCriticSummary.numReviews which could be numReviews or count
        if (parent === 'rtCriticSummary' && child === 'numReviews') {
          return parentValue.numReviews || parentValue.count || '';
        }
        
        return parentValue[child];
      }
      
      // Handle array properties that need JSON parsing
      if (['genre', 'production'].includes(field)) {
        try {
          const values = JSON.parse(params.data[field].replace(/'/g, '"'));
          return values.join(', ');
        } catch {
          return params.data[field];
        }
      }
      
      // Return direct value for other fields
      return params.data[field];
    },
    flex: 1
  });

  const filmographyColumnDefs = (type: 'Movies' | 'Series'): ColDef<FilmographyItem>[] => [
    {
      field: 'ip',
      headerName: 'Title',
      minWidth: 300,
      flex: 2,
      cellRenderer: type === 'Series' ? "agGroupCellRenderer" : (params: any) => {
        return (
          <div className="pt-2 flex gap-2 items-center">
            <Icon
              name={params.data.ip_id != null ? "status-positive" : "status-negative"}
              variant={params.data.tracked === true ? "success" : "disabled"}
            />
            <Link href={`/item/${params.data.ip_id}`}>{params.data.ip}</Link>
            {params.data.release_date && (
              <span className="text-slate-500 text-xs">
                {new Date(params.data.release_date).getFullYear()}
              </span>
            )}
          </div>
        );
      },
      ...(type === 'Series' && {
        cellRendererParams: {
          innerRenderer: (params: any) => {
            return (
              <div className="pt-2 flex gap-2 items-center">
                <Icon
                  name={params.data.ip_id != null ? "status-positive" : "status-negative"}
                  variant={params.data.tracked === true ? "success" : "disabled"}
                />
                <Link href={`/item/${params.data.ip_id}`}>{params.data.ip}</Link>
                {params.data.release_date && (
                  <span className="text-slate-500 text-xs">
                    {new Date(params.data.release_date).getFullYear()}
                  </span>
                )}
              </div>
            );
          }
        }
      })
    },
    {
      field: "daily_rank",
      headerName: "Daily Rank",
      cellRenderer: (params) => {
        if (!params.node.group) {
          return renderDailyRank(params);
        }
        return <span className="text-blue-500 font-bold text-md">{Math.round(params.value)}</span>;
      },
      aggFunc: (params) => {
        if (!params.values || params.values.length === 0) return 0;
        const sum = params.values.reduce((acc, value) => {
          return acc + (value?.score || 0);
        }, 0);
        return Math.round(sum / params.values.length);
      },
      valueGetter: (params) => {
        if (params.node?.group) {
          return params.data?.score || 0;
        }
        return params.data;
      }
    },
    {
      field: "weekly_rank",
      headerName: "7 Day Rank",
      cellRenderer: (params) => {
        if (!params.node.group) {
          return renderWeeklyRank(params);
        }
        return <span className="text-blue-500 font-bold text-md">{Math.round(params.value)}</span>;
      },
      aggFunc: (params) => {
        if (!params.values || params.values.length === 0) return 0;
        const sum = params.values.reduce((acc, value) => {
          return acc + (value?.weekly_sum || 0);
        }, 0);
        return Math.round(sum / params.values.length);
      },
      valueGetter: (params) => {
        if (params.node?.group) {
          return params.data?.weekly_sum || 0;
        }
        return params.data;
      }
    },
    {
      field: "monthly_rank",
      headerName: "30 Day Rank",
      cellRenderer: (params) => {
        if (!params.node.group) {
          return renderMonthlyRank(params);
        }
        return <span className="text-blue-500 font-bold text-md">{Math.round(params.value)}</span>;
      },
      aggFunc: (params) => {
        if (!params.values || params.values.length === 0) return 0;
        const sum = params.values.reduce((acc, value) => {
          return acc + (value?.monthly_sum || 0);
        }, 0);
        return Math.round(sum / params.values.length);
      },
      valueGetter: (params) => {
        if (params.node?.group) {
          return params.data?.monthly_sum || 0;
        }
        return params.data;
      }
    },
    {
      field: "imdb_vote_average",
      headerName: "IMDB Rating",
      flex: 1
    },
    {
      field: "rtFanSummary.score",
      headerName: "Rotten Tomatoes Fan Score",
      flex: 1
    },
    ...(type === 'Movies' ? [
      ...(selectedMovieColumns.includes('genre') ? [createCommonColumn('genre', 'Genre')] : []),
      ...(selectedMovieColumns.includes('production') ? [createCommonColumn('production', 'Production')] : []),
      ...(selectedMovieColumns.includes('imdb_vote_count') ? [createCommonColumn('imdb_vote_count', 'IMDB Votes')] : []),
      ...(selectedMovieColumns.includes('rtCriticSummary.score') ? [createCommonColumn('rtCriticSummary.score', 'Rotten Tomatoes Critic Score')] : []),
      ...(selectedMovieColumns.includes('rtFanSummary.count') ? [createCommonColumn('rtFanSummary.count', 'Rotten Tomatoes Fan Votes')] : []),
      ...(selectedMovieColumns.includes('rtCriticSummary.numReviews') ? [createCommonColumn('rtCriticSummary.numReviews', 'Rotten Tomatoes Critic Reviews')] : []),
      ...(selectedMovieColumns.includes('distributors') ? [{
        field: 'distributors' as keyof FilmographyItem,
        headerName: 'Distributor',
        valueGetter: (params: { data: FilmographyItem }) => {
          if (!params.data?.distributors) return '';
          try {
            const distributors = JSON.parse(params.data.distributors.replace(/'/g, '"'));
            return distributors.join(', ');
          } catch {
            return params.data.distributors;
          }
        },
        flex: 1
      }] : []),
      ...(selectedMovieColumns.includes('budget') ? [{
        field: 'budget.amount' as keyof FilmographyItem,
        headerName: 'Budget',
        valueGetter: (params: { data: FilmographyItem }) => params.data?.budget?.amount || '',
        cellRenderer: (params: { data: FilmographyItem }) => {
          if (!params.data?.budget) return '';
          return `$${shortenInteger(params.data.budget.amount)}`;
        },
        flex: 1
      }] : []),
      ...(selectedMovieColumns.includes('domestic') ? [{
        field: 'domestic' as keyof FilmographyItem,
        headerName: 'Domestic Gross',
        valueGetter: (params: { data: FilmographyItem }) => params.data?.grossToDate?.find(g => g.area === 'XDOM')?.grossToDate || '',
        cellRenderer: (params: { data: FilmographyItem }) => {
          if (!params.data?.grossToDate) return '';
          const domestic = params.data.grossToDate.find(g => g.area === 'XDOM');
          return domestic ? `$${shortenInteger(domestic.grossToDate)}` : '';
        },
        flex: 1
      }] : []),
      ...(selectedMovieColumns.includes('international') ? [{
        field: 'international' as keyof FilmographyItem,
        headerName: 'International Gross',
        valueGetter: (params: { data: FilmographyItem }) => params.data?.grossToDate?.find(g => g.area === 'XNDOM')?.grossToDate || '',
        cellRenderer: (params: { data: FilmographyItem }) => {
          if (!params.data?.grossToDate) return '';
          const international = params.data.grossToDate.find(g => g.area === 'XNDOM');
          return international ? `$${shortenInteger(international.grossToDate)}` : '';
        },
        flex: 1
      }] : []),
      ...(selectedMovieColumns.includes('worldwide') ? [{
        field: 'worldwide' as keyof FilmographyItem,
        headerName: 'Worldwide Gross',
        valueGetter: (params: { data: FilmographyItem }) => params.data?.grossToDate?.find(g => g.area === 'XWW')?.grossToDate || '',
        cellRenderer: (params: { data: FilmographyItem }) => {
          if (!params.data?.grossToDate) return '';
          const worldwide = params.data.grossToDate.find(g => g.area === 'XWW');
          return worldwide ? `$${shortenInteger(worldwide.grossToDate)}` : '';
        },
        flex: 1
      }] : []),
      ...(selectedMovieColumns.includes('billing') ? [{
        field: 'billing' as keyof FilmographyItem,
        headerName: 'Billing',
        flex: 1
      }] : []),
      ...(selectedMovieColumns.includes('attributes') ? [{
        field: 'attributes' as keyof FilmographyItem,
        headerName: 'Attributes',
        valueGetter: (params: { data: FilmographyItem }) => params.data?.attributes?.join(', ') || '',
        flex: 1
      }] : []),
    ] : []),
    ...(type === 'Series' ? [
      ...(selectedSeriesColumns.includes('genre') ? [createCommonColumn('genre', 'Genre')] : []),
      ...(selectedSeriesColumns.includes('production') ? [createCommonColumn('production', 'Production')] : []),
      ...(selectedSeriesColumns.includes('imdb_vote_count') ? [createCommonColumn('imdb_vote_count', 'IMDB Votes')] : []),
      ...(selectedSeriesColumns.includes('rtCriticSummary.score') ? [createCommonColumn('rtCriticSummary.score', 'Rotten Tomatoes Critic Score')] : []),
      ...(selectedSeriesColumns.includes('rtFanSummary.count') ? [createCommonColumn('rtFanSummary.count', 'Rotten Tomatoes Fan Votes')] : []),
      ...(selectedSeriesColumns.includes('rtCriticSummary.numReviews') ? [createCommonColumn('rtCriticSummary.numReviews', 'Rotten Tomatoes Critic Reviews')] : []),
    ] : []),
    {
      field: "month_score",
      headerName: "Rank Trend",
      cellRenderer: "agSparklineCellRenderer",
      sortable: false,
      valueGetter: (params) => {
        if (!params.data?.month_score) return [];
        try {
          return JSON.parse(params.data.month_score);
        } catch {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: "column",
          fill: "#1976d2",
          stroke: "#1976d2",
          paddingInner: 0.3,
          axis: { stroke: "#e0e0e0" },
          yAxis: { type: "number", min: 0 },
          padding: { top: 5, bottom: 5 },
        },
      },
      minWidth: 150,
    },
  ];

  const calculateTableHeight = (data: FilmographyItem[] | Award[] | any[], type?: 'Movies' | 'Series') => {
    let dataLength: number;
    
    if (type) {
      dataLength = data.filter(item => item.type === type).length;
    } else {
      dataLength = data.length;
    }
    
    const rows = Math.min(maxRows, Math.max(minRows, dataLength));
    return (rows * rowHeight) + headerHeight;
  };

  const awardsColumnDefs: ColDef<Award>[] = [
    { field: 'awardName' as keyof Award, headerName: 'Award', flex: 1 },
    { field: 'year' as keyof Award, headerName: 'Year', width: 100 },
    { field: 'category' as keyof Award, headerName: 'Category', flex: 1 },
    {
      field: 'title' as keyof Award,
      headerName: 'Title',
      valueGetter: (params: { data: Award }) => params.data.title?.[0]?.ip || '',
      cellRenderer: (params: { data: Award }) => {
        return <Link href={`/item/${params.data.title?.[0]?.ip_id}`}>{params.data.title?.[0]?.ip}</Link>;
      },
      flex: 1
    },
  ];

  const renderGenreAnalytics = (category: string, items: FilmographyItem[]) => {
    const movies = items.filter(item => item.ip_id.startsWith('film-'));
    const series = items.filter(item => item.ip_id.startsWith('series-'));

    const movieConnections = calculateGenreConnections(movies);
    const seriesConnections = calculateGenreConnections(series);
    if (movieConnections.length === 0 && seriesConnections.length === 0) {
      return null;
    }

    const createChartOptions = (connections: any[], title: string) => {
      const chartData = connections.map(conn => ({
        from: conn.from,
        to: conn.to,
        size: conn.size,
        linkTitle: `<ul>
          ${conn?.titles?.map(title => `<li style="list-style-type: disc; margin-left: 10px;">${title}</li>`).join('')}
        </ul>` || '',
      }));

      return {
        theme: chartTheme,
        width: 500,
        height: 500,
        series: [
          {
            type: 'chord',
            data: chartData,
            fromKey: 'from',
            toKey: 'to',
            sizeKey: 'size',
            tooltip: {
              renderer: (params: any) => {
                return {
                  title: `${params.datum.from} → ${params.datum.to} (${params.datum.size})`,
                  content: params.datum.linkTitle || 'No titles'
                };
              }
            },
            node: {
              strokeWidth: 2,
            },
            link: {
              tension: 0.5,
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
        ],
      } as unknown;
    };

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {movieConnections.length > 0 && (
          <Container
            disableContentPaddings
            disableHeaderPaddings
            header={<div className="font-bold px-4 pt-2 flex justify-center">Movies</div>}
          >
            <div className="w-full h-5400px] flex justify-center items-center">
              <AgCharts options={createChartOptions(movieConnections, 'Movies')} />
            </div>
          </Container>
        )}
        {seriesConnections.length > 0 && (
          <Container
            disableContentPaddings
            disableHeaderPaddings
            header={<div className="font-bold px-4 pt-2 flex justify-center">Series</div>}
          >
            <div className="w-full h-[500px] flex justify-center items-center">
              <AgCharts options={createChartOptions(seriesConnections, 'Series')} />
            </div>
          </Container>
        )}
      </div>
    );
  };

  const getFilteredCount = (items: FilmographyItem[], filterText: string) => {
    if (!filterText) return items.length;
    const searchText = filterText.toLowerCase();
    return items.filter(item =>
      item.ip.toLowerCase().includes(searchText) ||
      (item.genre?.toLowerCase().includes(searchText))
    ).length;
  };

  const social_ids = [
    { platform: 'Facebook', id: data.facebook, url: data.facebook ? `https://facebook.com/${data.facebook}` : null },
    { platform: 'Instagram', id: data.instagram, url: data.instagram ? `https://instagram.com/${data.instagram}` : null },
    { platform: 'Wikidata', id: data.wikidata_id, url: data.wikidata_id ? `https://www.wikidata.org/wiki/${data.wikidata_id}` : null },
    { platform: 'Wikipedia', id: data.wikipedia, url: data.wikipedia ? `https://en.wikipedia.org/wiki/${data.wikipedia}` : null },
    { platform: 'X (Twitter)', id: data.x, url: data.x ? `https://x.com/${data.x}` : null },
    { platform: 'YouTube', id: data.youtube, url: data.youtube ? `https://youtube.com/channel/${data.youtube}` : null }
  ].filter(item => item.id);

  const socialIdsColumnDefs: ColDef[] = [
    { field: 'platform', headerName: 'Platform', flex: 1 },
    {
      field: 'url',
      headerName: 'ID/URL',
      flex: 2,
      cellRenderer: (params: any) => {
        if (!params.value) return '';
        return <Link href={params.value} external>{params.data.id}</Link>;
      }
    }
  ];

  const getUniqueContributionCounts = (filmography) => {
    const counts = {
      actor: { movies: new Set(), series: new Set(), episodes: 0, domestic: 0, international: 0, worldwide: 0 },
      actress: { movies: new Set(), series: new Set(), episodes: 0, domestic: 0, international: 0, worldwide: 0 },
      writer: { movies: new Set(), series: new Set(), episodes: 0, domestic: 0, international: 0, worldwide: 0 },
      director: { movies: new Set(), series: new Set(), episodes: 0, domestic: 0, international: 0, worldwide: 0 },
      producer: { movies: new Set(), series: new Set(), episodes: 0, domestic: 0, international: 0, worldwide: 0 }
    };

    filmography.forEach(item => {
      const isMovie = item.ip_id.startsWith('film-');
      const isSeries = item.ip_id.startsWith('series-');

      if (counts[item.category]) {
        if (isMovie) {
          counts[item.category].movies.add(item.ip_id);
          if (item.grossToDate) {
            counts[item.category].domestic += item.grossToDate.find(g => g.area === 'XDOM')?.grossToDate || 0;
            counts[item.category].international += item.grossToDate.find(g => g.area === 'XNDOM')?.grossToDate || 0;
            counts[item.category].worldwide += item.grossToDate.find(g => g.area === 'XWW')?.grossToDate || 0;
          }
        } else if (isSeries) {
          counts[item.category].series.add(item.ip_id);
          if (item.episodes) {
            counts[item.category].episodes += item.episodes.length;
          }
        }
      }
    });

    return Object.fromEntries(
      ['actor', 'actress', 'writer', 'director', 'producer'].map(category => [
        category,
        {
          movies: counts[category].movies.size,
          series: counts[category].series.size,
          episodes: counts[category].episodes,
          domestic: counts[category].domestic,
          international: counts[category].international,
          worldwide: counts[category].worldwide
        }
      ])
    );
  };

  const handleTabChange = ({ detail }) => {
    setActiveTabId(detail.activeTabId);
    addQueryParams({ tab: detail.activeTabId });
  };

  return (
    <SpaceBetween size="l">
      <Header
        variant="h2"
        description={
          <TagList
            title=""
            tagData={data.category.map(cat => ({
              label: cat.charAt(0).toUpperCase() + cat.slice(1).toLowerCase(),
              color: "red",
              href: undefined
            }))}
          />
        }
      >
        {data.name}
      </Header>
      <Container
        media={{
          content: (
            <img
              src={`https://images.searchpantheon.com/talent_images/${data.nameId}.jpg`}
              alt={data.name}
              className="shadow-lg object-cover overflow-hidden"
              onError={(e) => {
                e.currentTarget.style.display = 'none';
                const fallback = document.createElement('div');
                fallback.className = 'w-full h-full bg-gray-200 shadow-lg flex items-center justify-center';
                const initials = document.createElement('span');
                initials.className = 'text-4xl text-gray-600';
                initials.textContent = data.name
                  .split(' ')
                  .map(word => word[0])
                  .join('')
                  .slice(0, 2)
                  .toUpperCase();
                fallback.appendChild(initials);
                e.currentTarget.parentNode.appendChild(fallback);
              }}
            />
          ),
          position: "side",
          width: "200px"
        }}
      >
        <SpaceBetween direction="vertical" size="l">
          {/* Basic Info Section */}
          <div className="grid grid-cols-2 gap-2">
            <div className="flex flex-col gap-2">
              <SpaceBetween direction="vertical" size="xxs">
                {data.dob && (
                  <>
                    <div>
                      <div className="text-sm text-gray-500 font-bold">Date of Birth</div>
                      <div>
                        {new Date(data.dob).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </div>
                    </div>

                    {data.dod ? (
                      <>
                        <div>
                          <div className="text-sm text-gray-500 font-bold">Date of Death</div>
                          <div>
                            {new Date(data.dod).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </div>
                        </div>
                        <div>
                          <div className="text-sm text-gray-500 font-bold">Age at Death</div>
                          <div>
                            {Math.floor((new Date(data.dod).getTime() - new Date(data.dob).getTime()) / (365.25 * 24 * 60 * 60 * 1000))} years
                          </div>
                        </div>
                      </>
                    ) : (
                      <div>
                        <div className="text-sm text-gray-500 font-bold">Age</div>
                        <div>
                          {Math.floor((new Date().getTime() - new Date(data.dob).getTime()) / (365.25 * 24 * 60 * 60 * 1000))} years
                        </div>
                      </div>
                    )}
                  </>
                )}
              </SpaceBetween>
            </div>

            <div className="flex flex-col gap-2">
              <SpaceBetween direction="vertical" size="xxs">
                {data?.gender && (
                  <div>
                    <div className="text-sm text-gray-500 font-bold">Gender</div>
                    <div className="capitalize">{data.gender}</div>
                  </div>
                )}

                {data?.citizenships && (
                  <div>
                    <div className="text-sm text-gray-500 font-bold">Citizenships</div>
                    <div>{data.citizenships}</div>
                  </div>
                )}
              </SpaceBetween>
            </div>
          </div>

          {/* Career Summary Section */}
          <div>
            <div className="font-bold text-blue-500 pb-2">Career Summary</div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxxl:grid-cols-4 gap-2">
              {Object.entries(getUniqueContributionCounts(data.filmography))
                .filter(([category]) => data.category.includes(category))
                .filter(([_, counts]) => counts.movies > 0 || counts.series > 0)
                .map(([category, counts]) => (
                  <div key={category} className="flex flex-col">
                    <div className="text-gray-500 font-bold text-sm capitalize">{category}</div>
                    <div className="text-sm">
                      {counts.movies > 0 && <><strong>{counts.movies}</strong> movies</>}
                      {counts.series > 0 && (counts.movies > 0 ? `, ` : '')}{counts.series > 0 && <><strong>{counts.series}</strong> TV series</>}
                      {counts.episodes > 0 && <span className="text-slate-500"> (<strong>{counts.episodes}</strong> episodes)</span>}
                    </div>
                    <div className="flex flex-wrap gap-1 mt-1">
                      {['domestic', 'international', 'worldwide'].map(metric =>
                        counts[metric] > 0 && (
                          <span
                            key={metric}
                            className="py-1 px-2 text-xs rounded-md bg-blue-200 dark:bg-blue-950 ring-1 ring-inset ring-blue-500 text-blue-900 dark:text-blue-200 whitespace-nowrap"
                          >
                            {metric.charAt(0).toUpperCase() + metric.slice(1)}: <strong>{shortenInteger(counts[metric])}</strong>
                          </span>
                        )
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </SpaceBetween>
      </Container>
      <Tabs
        onChange={handleTabChange}
        activeTabId={activeTabId}
        tabs={[
          ...Object.entries(filteredFilmographyByCategory).map(([category, items]) => ({
            label: category,
            id: category.toLowerCase(),
            content: (
              <SpaceBetween size="l">
                {renderGenreAnalytics(category, items) && (
                  <ExpandableSection headerText={`${category} Genre Connections`} defaultExpanded={true}>
                    {renderGenreAnalytics(category, items)}
                  </ExpandableSection>
                )}
                <ExpandableSection headerText={`${category} Filmography`} defaultExpanded={true}>
                  <SpaceBetween direction="vertical" size="l">
                    <Header
                      variant="h3"
                      counter={`( ${items.filter(item => item.type === 'Movies').length} )`}
                      actions={
                        <SpaceBetween direction="horizontal" size="l">
                          <TextFilter
                            filteringText={filterText}
                            onChange={({ detail }) => setFilterText(detail.filteringText)}
                            countText={`${getFilteredCount(items, filterText)} matches`}
                            filteringPlaceholder="Find titles"
                          />
                          <Multiselect
                            selectedOptions={optionalColumns['Movies'].filter(col => selectedMovieColumns.includes(col.value))}
                            onChange={({ detail }) =>
                              setSelectedMovieColumns(detail.selectedOptions.map(option => option.value))
                            }
                            options={optionalColumns['Movies']}
                            hideTokens
                            placeholder="Select additional columns"
                            filteringType="auto"
                          />
                        </SpaceBetween>
                      }
                    >
                      Movies
                    </Header>
                    <div
                      className={theme}
                      style={{ height: `${calculateTableHeight(items.filter(item => item.type === 'Movies'), 'Movies')}px` }}
                    >
                      <AgGridReact
                        rowData={items.filter(item => item.type === 'Movies')}
                        columnDefs={filmographyColumnDefs('Movies')}
                        rowHeight={rowHeight}
                        groupDefaultExpanded={0}
                        suppressAggFuncInHeader={true}
                        defaultColDef={defaultColDef}
                        loading={isLoading}
                        loadingOverlayComponent={CustomLoadingOverlay}
                        quickFilterText={filterText}
                        // headerHeight={70}
                      />
                    </div>
                    <hr className="border-slate-300 dark:border-slate-700"/>
                    <Header
                      variant="h3"
                      counter={`( ${items.filter(item => item.type === 'Series').length} )`}
                      actions={
                        <SpaceBetween direction="horizontal" size="l">
                          <TextFilter
                            filteringText={filterText}
                            onChange={({ detail }) => setFilterText(detail.filteringText)}
                            countText={`${getFilteredCount(items, filterText)} matches`}
                            filteringPlaceholder="Find titles"
                          />
                          <Multiselect
                            selectedOptions={optionalColumns['Series'].filter(col => selectedSeriesColumns.includes(col.value))}
                            onChange={({ detail }) =>
                              setSelectedSeriesColumns(detail.selectedOptions.map(option => option.value))
                            }
                            options={optionalColumns['Series']}
                            hideTokens
                            placeholder="Select additional columns"
                            filteringType="auto"
                          />
                        </SpaceBetween>
                      }
                    >
                      Series
                    </Header>
                    <div
                      className={theme}
                      style={{ 
                        height: `${Math.max(
                          calculateTableHeight(items.filter(item => item.type === 'Series'), 'Series'),
                          items.filter(item => item.type === 'Series').length < 5 ? 300 : 0
                        )}px` 
                      }}
                    >
                      <AgGridReact
                        rowData={items.filter(item => item.type === 'Series')}
                        columnDefs={filmographyColumnDefs('Series')}
                        rowHeight={rowHeight}
                        groupDefaultExpanded={0}
                        suppressAggFuncInHeader={true}
                        defaultColDef={defaultColDef}
                        loading={isLoading}
                        loadingOverlayComponent={CustomLoadingOverlay}
                        quickFilterText={filterText}
                        masterDetail={true}
                        detailCellRenderer={detailCellRenderer}
                        detailRowAutoHeight={true}
                        detailCellRendererParams={{
                          getDetailRowData: params => params.successCallback({ episodes: params.data.episodes })
                        }}
                      />
                    </div>
                  </SpaceBetween>
                </ExpandableSection>
              </SpaceBetween>
            ),
          })),
          ...(data?.awards?.length > 0 ? [{
            label: "Awards",
            id: "awards",
            content: (
              <SpaceBetween size="l">
                <Awards itemData={data} table={false} />
                <Box>
                  <SpaceBetween direction="vertical" size="l">

                    <Header
                      counter={`(${filteredAndSortedAwards.length})`}
                      actions={
                        <div className="flex gap-4 w-full">
                          <div className="flex-[2]">
                            <Multiselect
                              selectedOptions={uniqueCategories.filter(cat => selectedCategory.includes(cat.value))}
                              onChange={({ detail }) =>
                                setSelectedCategory(detail.selectedOptions.map(option => option.value))
                              }
                              options={uniqueCategories.filter(cat => cat.value !== "")}
                              placeholder="Filter by category"
                              filteringType="auto"
                            />
                          </div>
                          <div className="flex-[2]">
                            <Multiselect
                              selectedOptions={uniqueAwardNames.filter(award => selectedAwards.includes(award.value))}
                              onChange={({ detail }) =>
                                setSelectedAwards(detail.selectedOptions.map(option => option.value))
                              }
                              options={uniqueAwardNames}
                              placeholder="Filter by award"
                              filteringType="auto"
                            />
                          </div>
                          <div className="flex-1">
                            <Multiselect
                              selectedOptions={uniqueYears.filter(year => selectedYears.includes(year.value))}
                              onChange={({ detail }) =>
                                setSelectedYears(detail.selectedOptions.map(option => option.value))
                              }
                              options={uniqueYears}
                              placeholder="Filter by year"
                              filteringType="auto"
                            />
                          </div>
                        </div>
                      }
                    >
                      Awards
                    </Header>
                    <div
                      className={theme}
                      style={{ height: `${calculateTableHeight(filteredAndSortedAwards)}px` }}
                    >
                      <AgGridReact
                        rowData={filteredAndSortedAwards}
                        defaultColDef={defaultColDef}
                        columnDefs={awardsColumnDefs}
                        rowHeight={rowHeight}
                        loading={isLoading}
                        loadingOverlayComponent={CustomLoadingOverlay}
                      />
                    </div>
                  </SpaceBetween>
                </Box>
              </SpaceBetween>
            ),
          }] : []),
          ...(social_ids?.length > 0 ? [{
            label: "Social Id's",
            id: "social-ids",
            content: (
                <div className={theme} style={{ height: `${calculateTableHeight(social_ids)}px` }}>
                  <AgGridReact
                    rowData={social_ids}
                    columnDefs={socialIdsColumnDefs}
                    defaultColDef={defaultColDef}
                    rowHeight={rowHeight}
                    loading={isLoading}
                    loadingOverlayComponent={CustomLoadingOverlay}
                  />
                </div>
            ),
          }] : [])
        ]}
      />
    </SpaceBetween>
  );
};
