import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTrendsData, GetTrendsDataType } from "../request";

type UseGetTrackedRedditDataProps = Omit<GetTrendsDataType, "type">;

export const useGetTrackedRedditData = ({
  ipId,
  dateRange,
  enabled=true,
}: UseGetTrackedRedditDataProps) => {
  const { isLoading, data, error } = useQuery(
    [
      QUERY_KEYS.GET_TRACKED_REDDIT_DATA,
      ipId,
      dateRange.startDate,
      dateRange.endDate,
    ],
    () => getTrendsData({ ipId, dateRange, type: "reddit" }),
    { enabled },
  );

  return {
    data: data?.data,
    isLoading,
    error,
  };
};
