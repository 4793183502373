import {
  useGetChartTheme,
  CHART_TYPES,
} from "../../hooks/UseTheme/useGetChartTheme";

export const useItemChartConfig = () => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT, {
    overrides: {
      line: {
        series: {
          highlightStyle: {
            series: {
              dimOpacity: 0.2,
              strokeWidth: 2,
            },
          },
        },
      },
      bar: {
        series: {
          highlightStyle: {
            series: {
              dimOpacity: 0.2,
              strokeWidth: 2,
            },
          },
        },
      },
    },
  });

  return {
    trackedWikiTrendsChartOption: getTrackedWikiTrendsChartOption(theme),
    baseTwitchChartOptions: getBaseTwitchChartOptions(theme),
    baseSteamChartOptions: getBaseSteamChartOptions(theme),
    trackedRedditTrendsChartOption: getTrackedRedditTrendsChartOption(theme),
    timelineTheme,
    timelineClasses,
  };
};

export const getTrackedWikiTrendsChartOption = (theme) => ({
  theme: theme,
  series: [
    {
      type: "bar",
      xKey: "timestamp",
      xName: "Date",
      yKey: "views_user",
      yName: "Total",
      tooltip: {
        renderer: (params) => {
          return {
            title: "Total Views",
            content: `${
              params.datum.timestamp
            } : ${params.datum.views_user.toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        fontFamily: "Open Sans",
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      min: 0,
      interval: {
        minSpacing: 10,
      },
      keys: ["views_user"],
    },
  ],
});

const timelineTheme = {
  cardBgColor: "#161D26",
  cardDetailsBackGround: "#000716",
  cardDetailsColor: "#ffffff",
  cardMediaBgColor: "#2f2f2f",
  cardSubtitleColor: "#94a3b8",
  cardTitleColor: "#539fe5",
  detailsColor: "#94a3b8",
  iconBackgroundColor: "#539fe5",
  nestedCardBgColor: "#333333",
  nestedCardDetailsBackGround: "#333333",
  nestedCardDetailsColor: "#94a3b8",
  nestedCardSubtitleColor: "#94a3b8",
  nestedCardTitleColor: "#94a3b8",
  primary: "#539fe5",
  secondary: "#ffffff",
  titleColor: "#539fe5",
  titleColorActive: "#161D26",
};

const timelineClasses = {
  card: "timeline-card",
  cardMedia: "timeline-card-media",
  cardSubTitle: "timeline-card-subtitle",
  cardText: "timeline-card-text",
  cardTitle: "timeline-card-title",
  controls: "timeline-controls",
  title: "timeline-title",
};

export const getBaseTwitchChartOptions = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: true,
    position: "right",
  },
  series: [
    {
      type: "line",
      xKey: "time",
      yKey: "average_viewers",
      yName: "Average Viewers",
      marker: { enabled: false },
      //fill: "#5090dc",
      //stroke: "#2d588a",
      //strokeWidth: 2,
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Average Twitch Viewers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.average_viewers,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "time",
      yKey: "peak_viewers",
      yName: "Peak Viewers",
      marker: { enabled: false },
      //fill: "#5090dc",
      //stroke: "#2d588a",
      //strokeWidth: 2,
      stroke: "#ffa03a",
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Peak Twitch Viewers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.peak_viewers,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "time",
      yKey: "average_streamers",
      yName: "Average Streamers",
      marker: { enabled: false },
      //stroke: "#ffa03a",
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Average Twitch Streamers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.average_streamers,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "time",
      yKey: "peak_streamers",
      yName: "Peak Streamers",
      marker: { enabled: false },
      stroke: "#ffa03a",
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Peak Twitch Streamers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.peak_streamers,
            ).toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return dateFormatter.format(date);
        },
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      interval: {
        minSpacing: 20,
      },
      min: 0,
    },
  ],
  padding: {
    right: 0,
  },
  data: [],
});

export const getBaseSteamChartOptions = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: true,
    position: "right",
  },
  series: [
    {
      type: "line",
      xKey: "time",
      yKey: "ccu",
      yName: "Concurrent Users",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Steam Concurrent Users",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.ccu,
            ).toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return dateFormatter.format(date);
        },
        avoidCollisions: true,
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      interval: {
        minSpacing: 20,
      },
      min: 0,
      keys: ["ccu"],
    },
  ],
  padding: {
    right: 0,
  },
  data: [],
});

export const getTrackedRedditTrendsChartOption = (theme) => ({
  theme: theme,
  padding: { right: 0, bottom: 0, left: 0 },
  legend: {
    position: "bottom",
    spacing: 12,
  },
  series: [
    {
      type: "line",
      xKey: "timestamp",
      yKey: "subscriberGrowth",
      yName: "Subscribers",
      marker: { enabled: false },
      visible: true,
      tooltip: {
        renderer: (params) => {
          return {
            title: "New Subscribers",
            content: `${params.datum.timestamp} : ${params.datum.subscriberGrowth}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "score",
      yName: "Upvotes (Top hot posts)",
      marker: { enabled: false },
      visible: true,
      tooltip: {
        renderer: (params) => {
          return {
            title: "Upvotes (Top hot posts)",
            content: `${params.datum.timestamp} : ${params.datum.score}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "comments",
      yName: "Comments (Top hot posts)",
      marker: { enabled: false },
      visible: true,
      tooltip: {
        renderer: (params) => {
          return {
            title: "Comments (Top hot posts)",
            content: `${params.datum.timestamp} : ${params.datum.comments}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "activeUsers",
      yName: "Active Users",
      marker: { enabled: false },
      visible: true,
      tooltip: {
        renderer: (params) => {
          return {
            title: "Active Users",
            content: `${params.datum.timestamp} : ${parseInt(
              params.datum.activeUsers,
            )}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        format: "%b %-d, %Y",
        autoRotate: false,
      },
      crosshair: {
        label: {
          format: "%b %-d, %Y",
        },
      },
    },
    {
      type: "number",
      position: "left",
      label: {
        format: "%b %-d, %Y",
      },
      crosshair: {
        label: {
          format: "%b %-d, %Y",
        },
      },
      keys: ["subscriberGrowth", "activeUsers"],
      title: {
        text: "Active Users & Subscribers",
        spacing: 12,
      },
    },
    {
      type: "number",
      position: "right",
      label: {
        format: "%b %-d, %Y",
      },
      crosshair: {
        label: {
          format: "%b %-d, %Y",
        },
      },
      keys: ["commentsGrowth", "scoreGrowth", "comments", "score"],
      title: {
        text: "Upvotes & Comments",
        spacing: 12,
      },
    },
  ],
});
