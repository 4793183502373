import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { listReports } from "../request";


export const useGetJobListData = (parameters) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_JOB_LIST_DATA, { ...parameters }],
    () => listReports(parameters),
  );

  return {
    data: data?.data,
    isLoading,
    error,
  };
};