import { Badge, Container, SpaceBetween } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import classnames from "classnames";
import _ from "lodash";
import React, { FC, useEffect, useRef, useState } from "react";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { EmptyGrid } from "../../../components/grid/EmptyGrid";
import TableLegend from "../../../components/table/TableLegend";
import { useGetGridTheme } from "../../../hooks/UseTheme/useGetGridTheme";
import { useSetGridAdvancedFilterTheme } from "../../../hooks/UseTheme/useSetGridAdvancedFilterTheme";
import { PreferenceItem } from "../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { ApiURLParams } from "../../../services/generic_v2/requests";
import { CategoryItem, Catogories } from "../../../types/optionTypes";
import { usePlatformContext } from "../PlatformProvider";
import { PlatformChartHeader } from "../components/PlatformChartHeader";
import { useFetchServerSideGridData } from "../hooks/useFetchServerSideGridData";
import { useGridConfig } from "../hooks/useGridConfig";
import { GetPlatformTrendsDataProps } from "../types";
import CustomFlashBar from "../../common/CustomFlashBar";
import { useGenericPreference_V2 } from "../../../services/generic_v2/hooks/useGenericPreference_V2";
import { createOptionsField } from "../../../utils/selectUtils";

interface PlatformTrendsChartsProps {
  apiParams: ApiURLParams;
  defaultColumn: string;
  defaultCategory: string;
  categoryOptions: Catogories;
  corePlatformColumns: Array<string>;
  fetchMethod: (params: GetPlatformTrendsDataProps) => void;
  additionalPlatformColumns: Array<string>;
  selectedVertical?: CategoryItem;
  setSelectedVertical?: (category: CategoryItem) => void;
}

export const PlatformTrendsCharts: FC<PlatformTrendsChartsProps> = ({
  apiParams,
  defaultColumn,
  defaultCategory,
  categoryOptions,
  corePlatformColumns,
  fetchMethod,
  additionalPlatformColumns,
  selectedVertical: externalSelectedVertical,
  setSelectedVertical: externalSetSelectedVertical,
}) => {
  const gridRef = useRef(null);
  const flashbarRef = useRef(null);
  const { platformPreference, hasApiCompleted, isLoading, platformFilterName } =
    usePlatformContext();
  const path = `${apiParams.service}/${apiParams.module}/${apiParams.tab}`;
  const existingPref = platformPreference?.find(
    (item: PreferenceItem) => item.path === path,
  );

  const handlePreferenceFirstLoad = (preferenceData) => {
    let currSelectedVertical = selectedVertical;
    if (preferenceData?.category) {
      const category = preferenceData.category
        ? categoryOptions.find(
          ({ value }: { value: string }) =>
            value === preferenceData.category,
        )
        : { label: _.startCase(defaultCategory), value: defaultCategory };

      setSelectedVertical(category);
      currSelectedVertical = category;

      if (externalSetSelectedVertical) {
        externalSetSelectedVertical(category);
      }
    }

    if (preferenceData?.[currSelectedVertical.value]?.additionalColumns) {
      setSelectedOptions(
        preferenceData[currSelectedVertical.value].additionalColumns.map(createOptionsField),
      );
    } else {
      setSelectedOptions([createOptionsField(defaultColumn)]);
    }
  };

  useGenericPreference_V2({ apiParams, onPreferenceFirstLoad: handlePreferenceFirstLoad });

  const [selectedVertical, setSelectedVertical] = useState<CategoryItem>(
    externalSelectedVertical || {
      label: _.startCase(defaultCategory),
      value: defaultCategory,
    }
  );

  const [filter, setFilter] = useState(null);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(20);
  const [selectedOptions, setSelectedOptions] = useState<Catogories>([
    {
      label: defaultColumn,
      value: defaultColumn,
    },
  ]);

  useEffect(() => {
    if (externalSelectedVertical) {
      setSelectedVertical(externalSelectedVertical);
    }
  }, [externalSelectedVertical]);

  const { theme } = useGetGridTheme();
  useSetGridAdvancedFilterTheme();
  const { defaultColDef, columnDefs, onGridReady } = useGridConfig(
    selectedOptions,
    corePlatformColumns,
    additionalPlatformColumns,
    selectedVertical.value,
  );

  useFetchServerSideGridData({
    filter,
    vertical: selectedVertical.value,
    gridRef,
    fetchMethod,
    disabled: !(hasApiCompleted || !isLoading),
  });

  return (
    <SpaceBetween direction="vertical" size="m">
      <CustomFlashBar ref={flashbarRef} />
      <div className="flex flex-col gap-2">
        <PlatformChartHeader
          filter={filter}
          ref={gridRef}
          defaultColumn={defaultColumn}
          additionalPlatformColumns={additionalPlatformColumns}
          selectedVertical={selectedVertical}
          setSelectedVertical={(category) => {
            setSelectedVertical(category);
            if (externalSetSelectedVertical) {
              externalSetSelectedVertical(category);
            }
          }}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          apiParams={apiParams}
          categoryOptions={categoryOptions}
          flashbarRef={flashbarRef}
        />
        <div
          className={classnames("", {
            "grid grid-cols-[1fr_auto] gap-5": platformFilterName,
          })}
        >
          <div id="advancedFilterParent"></div>
          {platformFilterName && (
            <Badge color="blue"><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', height: '100%' }}>
              {platformFilterName}
            </div></Badge>
          )}
        </div>
      </div>
      
      <SpaceBetween direction="vertical" size="m">
        <div style={{ height: "70vh" }} className={theme}>
          <AgGridReact
            ref={gridRef}
            paginationPageSize={paginationPageSize}
            pagination={true}
            paginationPageSizeSelector={[20, 50, 100]}
            onPaginationChanged={(params) => {
              const size = params.api.paginationGetPageSize();
              if (size !== paginationPageSize) setPaginationPageSize(size);
            }}
            defaultColDef={defaultColDef}
            loadingOverlayComponent={CustomLoadingOverlay}
            noRowsOverlayComponent={EmptyGrid}
            suppressDragLeaveHidesColumns={false}
            enableAdvancedFilter={true}
            tooltipShowDelay={100}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onFilterChanged={(params) => {
              const f = params?.api?.getAdvancedFilterModel();
              setFilter(f);
            }}
            rowModelType={"serverSide"}
            blockLoadDebounceMillis={100}
          />
        </div>
        <TableLegend />
      </SpaceBetween>
    </SpaceBetween>
  );
};