import { Button, ButtonDropdown, Container, FormField, Grid, Header, Icon, Input, SpaceBetween } from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import { useGetGridTheme } from "../../../../../../hooks/UseTheme/useGetGridTheme";
import { useQueryClient } from "react-query";
import { useGetSavedTitleListsData } from "../../../api/hooks/useGetSavedTitleListsData";
import { useFilterDrawer } from "../../../../../../hooks/FilterDrawer/useFilterDrawer";
import { DEFAULT_REPORT_BUILDER_TITLE_FILTERS, DEFAULT_TITLE_LIST_NAME } from "../../../constants";
import { deleteTitleListData, getTitleListData, getTitlesData, modifyTitleListData, saveTitleListData } from "../../../api/request";
import { useFetchServerSideGridData } from "../hooks/useFetchServerSideGridData";
import { useAddTitlesGridConfig } from "../hooks/useAddTitlesGridConfig";
import { useSelectedTitlesGridConfig } from "../hooks/useSelectedTitlesGridConfig";
import { QUERY_KEYS } from "../../../api/constants";
import { MessageBox } from "../../../../../../components/MessageBox";
import { useCreateReportContext } from "../hooks/useCreateReportContext";
import { FaSliders } from "react-icons/fa6";

const s3Pattern = /^[a-zA-Z0-9!\-_.\*'()\s]*$/;

export const SelectTitles = ({
  apiParams,
}) => {

  const { 
    selectedTitleList, 
    setSelectedTitleList, 
    verifyErrors, 
    selectedTitlesLength, 
    setSelectedTitlesLength,
  } = useCreateReportContext();

  const { theme } = useGetGridTheme();
  const queryClient = useQueryClient();

  const gridRef = useRef(null);
  const selectedTitlesGridRef = useRef(null);
  const messageBoxRef = useRef(null);

  const [selectionState, setSelectionState] = useState({ selectAll: false, toggledNodes: [], rowCount: 0 });
  const [titleListSelectionState, setTitleListSelectionState] = useState({ selectAll: false, toggledNodes: [], rowCount: 0 });
  const [titleListIsLoading, setTitleListIsLoading] = useState(false);
  const [saveListName, setSaveListName] = useState("");
  const [saveListModalOpen, setSaveListModalOpen] = useState(false);
  const [createListModalOpen, setCreateListModalOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const { data: savedListsData, isLoading: savedListsLoading, error: savedListsError } = useGetSavedTitleListsData();

  const { filterDrawerContent, filterQuery, filterObject, setFilterObject, setFilterValue } = useFilterDrawer({
    defaultFilterObject: DEFAULT_REPORT_BUILDER_TITLE_FILTERS,
    prefsApiParams: apiParams,
    treatAsDefault: { vertical: "all" },
    applyOnChange: true,
  });

  const { dataSource: addTitlesDataSource } = useFetchServerSideGridData({ 
    fetchMethod: getTitlesData, 
    requestParams: { filter: filterQuery } 
  });
  const { dataSource: selectedTitlesDataSource } = useFetchServerSideGridData({ 
    fetchMethod: getTitleListData, 
    requestParams: { list: selectedTitleList.value },
    setIsLoading: setTitleListIsLoading,
    gridRef: selectedTitlesGridRef,
  });

  const addTitlesGridOptions = useAddTitlesGridConfig();
  const selectedTitlesGridOptions = useSelectedTitlesGridConfig({ selectionEnabled: true });

  const modifyTitleList = ({ action, source, selectionState }) => {
    setTitleListIsLoading(true);
    modifyTitleListData({
      list: selectedTitleList.value,
      source,
      action,
      filter: filterQuery,
      selectAll: selectionState.selectAll,
      toggledIndices: selectionState.toggledNodes,
    }).then(() => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.GET_TITLE_LIST_DATA }).then(() => {
        selectedTitlesGridRef.current.api.refreshServerSide({ purge: true });
      });
    });
  };

  const handleSaveTitleList = () => {
    setSaveListModalOpen(false);
    saveTitleListData({
      list: saveListName,
      oldList: selectedTitleList.value,
    }).then(() => {
      setSelectedTitleList({ value: saveListName });
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.GET_SAVED_TITLE_LISTS_DATA });
    });
  };

  const handleCreateTitleList = () => {
    setCreateListModalOpen(false);
    saveTitleListData({
      list: saveListName,
      oldList: "###new###",
    }).then(() => {
      setSelectedTitleList({ value: saveListName });
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.GET_SAVED_TITLE_LISTS_DATA });
    });
  };

  const handleDeleteTitleList = (listToDelete) => {
    if (listToDelete === selectedTitleList.value) {
      setSelectedTitleList({ value: DEFAULT_TITLE_LIST_NAME });
    }
    deleteTitleListData({ list: listToDelete }).then(() => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.GET_SAVED_TITLE_LISTS_DATA });
    });
  };

  useEffect(() => {
    setFilterObject(DEFAULT_REPORT_BUILDER_TITLE_FILTERS);
  }, []);

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 12, m: 7 } },
        { colspan: { default: 12, m: 5 } },
      ]}
    >
      <div>
        <Container
          fitHeight
          header={
            <Header
              actions={
                <div className="flex justify-end gap-2">
                  <Button
                    iconName="add-plus"
                    ariaLabel="Add selected titles"
                    disabled={(selectionState.selectAll && selectionState.toggledNodes.length === selectionState.rowCount) || (!selectionState.selectAll && selectionState.toggledNodes.length === 0)}
                    onClick={() => {
                      modifyTitleList({ action: "add", source: "title_search", selectionState });
                    }}
                    loading={titleListIsLoading}
                  ></Button>
                  <Button
                    iconName="subtract-minus"
                    ariaLabel="Remove selected titles"
                    disabled={(selectionState.selectAll && selectionState.toggledNodes.length === selectionState.rowCount) || (!selectionState.selectAll && selectionState.toggledNodes.length === 0)}
                    onClick={() => {
                      modifyTitleList({ action: "remove", source: "title_search", selectionState });
                    }}
                    loading={titleListIsLoading}
                  ></Button>
                  <Button
                    ariaLabel="Clear selection"
                    disabled={(selectionState.selectAll && selectionState.toggledNodes.length === selectionState.rowCount) || (!selectionState.selectAll && selectionState.toggledNodes.length === 0)}
                    onClick={() => {
                      gridRef.current.api.deselectAll();
                      setSelectionState({ selectAll: false, toggledNodes: [], rowCount: 0 });
                    }}
                  >
                    Clear selection
                  </Button>
                </div>
              }
              description={<div className="text-xs">Utilize the filters on the right to find titles and add them to your selected title list</div>}
            >
              Search titles
            </Header>
          }
        >
          <div className="flex w-full gap-4 h-[75vh] relative overflow-hidden">
            <div className={`flex flex-col gap-4 grow h-full ${filtersOpen ? "pr-[265px]" : "pr-[40px]"}`}>
              <div className={theme + " h-full flex flex-col gap-4"} style={{ "--ag-grid-size": "5px", "--ag-spacing": "5px" } as React.CSSProperties}>
                <Input
                  value={filterObject?.filters?.find(f => f.field === "ip").value?.[0] || ""}
                  onChange={({ detail }) => setFilterValue("ip", detail.value)}
                  placeholder="Search"
                  type="search"
                />
                <AgGridReact
                  ref={gridRef}
                  pagination={true}
                  paginationPageSizeSelector={false}
                  rowModelType={"serverSide"}
                  blockLoadDebounceMillis={100}
                  serverSideDatasource={addTitlesDataSource}
                  suppressRowClickSelection={true}
                  onSelectionChanged={(event) => {
                    const newSelectionState = event.api.getServerSideSelectionState() as any;
                    const rowCount = event.api.getDisplayedRowCount();
                    setSelectionState({
                      selectAll: newSelectionState.selectAll,
                      toggledNodes: newSelectionState.toggledNodes.map((nodeId) => event.api.getRowNode(nodeId).rowIndex),
                      rowCount,
                    });
                  }}
                  {...addTitlesGridOptions}
                />
              </div>
            </div>
            <div className={`absolute top-0 h-full flex z-10 ${filtersOpen ? "right-[0px]" : "right-[-240px]"} transition-all ease-out duration-100`}>
              <div className={`h-full ${filtersOpen ? "w-[20px]" : "w-[40px]"}`}>
                <Button
                  variant="icon"
                  iconSvg={
                    <div className="h-full w-full flex items-center text-center text-nowrap" title={filtersOpen ? "Close filters" : "Open filters"}>
                      {filtersOpen ? (
                        <>
                          <div className="h-fit">
                            <Icon name="angle-right" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="h-fit ml-[0.5rem]">
                            <Icon name="angle-left" />
                          </div>
                          <div className="h-fit w-fit leading-none rotate-90 ml-[-0.75rem]">Filters</div>
                        </>
                      )}
                    </div>
                  }
                  className="filter-button"
                  onClick={() => setFiltersOpen(!filtersOpen)}
                />
                <style>{`
                .filter-button, .filter-button span {
                  padding: 0 !important;
                  border: none !important;
                  margin: 0 !important;
                  height: 100% !important;
                  width: 100% !important;
                }
                `}</style>
              </div>
              <div className={`w-[240px] h-full overflow-x-hidden pr-2 bg-white dark:bg-[#161d26] ${filtersOpen ? "overflow-y-auto" : "overflow-y-hidden"}`}>
                {filterDrawerContent}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container
          header={
            <Header
              actions={
                <div className="flex gap-2">
                  <Button
                    iconName="subtract-minus"
                    ariaLabel="Remove selected titles"
                    disabled={(titleListSelectionState.selectAll && titleListSelectionState.toggledNodes.length === titleListSelectionState.rowCount) || (!titleListSelectionState.selectAll && titleListSelectionState.toggledNodes.length === 0)}
                    onClick={() => {
                      modifyTitleList({ action: "remove", source: "title_list", selectionState: titleListSelectionState });
                      setTitleListSelectionState({ selectAll: false, toggledNodes: [], rowCount: 0 });
                      selectedTitlesGridRef.current.api.deselectAll();
                    }}
                    loading={titleListIsLoading}
                  ></Button>
                  <ButtonDropdown
                    items={[
                      { text: "Create new list", id: "create_list" },
                      { text: "Save list as", id: "save_list" },
                      { 
                        text: "Load list", 
                        id: "load_list", 
                        items: savedListsData?.results?.map((list) => ({ text: list, id: `load_${list}` })), 
                        disabled: (savedListsData?.results?.length === 0 || savedListsLoading),
                      },
                      { 
                        text: "Delete list", 
                        id: "delete_list", 
                        items: savedListsData?.results?.map((list) => ({ text: list, id: `delete_${list}` })), 
                        disabled: (savedListsData?.results?.length === 0 || savedListsLoading),
                      },
                    ]}
                    onItemClick={({ detail }) => {
                      switch (detail.id) {
                        case "create_list":
                          setSaveListName("");
                          setCreateListModalOpen(true);
                          break;
                        case "save_list":
                          setSaveListName(selectedTitleList.value);
                          setSaveListModalOpen(true);
                          break;
                        default:
                          if (detail.id.startsWith("load_")) {
                            setSelectedTitleList({ value: detail.id.replace("load_", "") });
                          } else if (detail.id.startsWith("delete_")) {
                            const list = detail.id.replace("delete_", "");
                            messageBoxRef.current.open({
                              headerText: "Are you sure?",
                              messageBody: (<span>Are you sure you want to delete the list <b>{list}</b>?</span>),
                              primaryButtonText: "Yes",
                              secondaryButtonText: "No",
                              onPrimaryButtonClick: () => {
                                handleDeleteTitleList(list);
                              },
                              onSecondaryButtonClick: () => {},
                            });
                          }
                          break;
                      }
                    }}
                    expandableGroups
                  >
                    Actions
                  </ButtonDropdown>
                </div>
              }
              description={<div className="text-xs">Currently editing - <b>{selectedTitleList.value}</b></div>}
            >
              Selected titles
            </Header>
          }
          fitHeight
        >
          <FormField stretch errorText={(verifyErrors.includes("selected_titles_empty") && selectedTitlesLength === 0) ? "Selected titles cannot be empty" : null}>
            <div className={theme + " h-[75vh]"} style={{ "--ag-grid-size": "5px", "--ag-spacing": "5px" } as React.CSSProperties}>
              <AgGridReact
                ref={selectedTitlesGridRef}
                pagination={true}
                paginationPageSizeSelector={false}
                rowModelType={"serverSide"}
                blockLoadDebounceMillis={100}
                serverSideDatasource={selectedTitlesDataSource}
                suppressRowClickSelection={true}
                onSelectionChanged={(event) => {
                  const newSelectionState = event.api.getServerSideSelectionState() as any;
                  const rowCount = event.api.getDisplayedRowCount();
                  setTitleListSelectionState({
                    selectAll: newSelectionState.selectAll,
                    toggledNodes: newSelectionState.toggledNodes.map((nodeId) => event.api.getRowNode(nodeId)?.rowIndex),
                    rowCount,
                  });
                }}
                onPaginationChanged={(event) => {
                  setSelectedTitlesLength(event.api.getDisplayedRowCount());
                  if (event.api.getDisplayedRowCount() === 0) {
                    event.api.showNoRowsOverlay();
                  }
                }}
                overlayNoRowsTemplate="<span className='p-4 bold'>No titles selected</span>"
                {...selectedTitlesGridOptions}
              />
            </div>
          </FormField>
        </Container>
      </div>
      <MessageBox ref={messageBoxRef} />
      <MessageBox
        modalIsOpen={saveListModalOpen}
        setModalIsOpen={setSaveListModalOpen}
        headerText="Save list as"
        messageBody={
          <FormField label="Name">
            <Input
              placeholder="Enter a name for the list"
              value={saveListName}
              onChange={({ detail }) => setSaveListName(detail.value)}
              invalid={!s3Pattern.test(saveListName)}
            />
          </FormField>
        }
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={handleSaveTitleList}
      />
      <MessageBox
        modalIsOpen={createListModalOpen}
        setModalIsOpen={setCreateListModalOpen}
        headerText="Create new list"
        messageBody={
          <FormField label="Name">
            <Input
              placeholder="Enter a name for the list"
              value={saveListName}
              onChange={({ detail }) => setSaveListName(detail.value)}
              invalid={!s3Pattern.test(saveListName)}
            />
          </FormField>
        }
        primaryButtonText="Create"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={handleCreateTitleList}
      />
    </Grid>
  );
};