import { Autosuggest, Box, Button, Input, Modal, SpaceBetween, TokenGroup } from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { useGetTagsSearch } from "./api/hooks/useGetTagsSearch";

export const EditBlockedTagsModal = ({
  visible,
  setVisible,
  preferenceData,
  savePreference,
}) => {

  const [tags, setTags] = useState([]);
  const [tagInputValue, setTagInputValue] = useState("");
  const [tagLoadInputValue, setTagLoadInputValue] = useState("");

  const { data, isLoading, error } = useGetTagsSearch({ q: tagLoadInputValue, limit: 10 });

  const saveBlockedTags = () => {
    savePreference({ blocked_tags: tags });
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      setTagInputValue("");
      setTags(preferenceData?.blocked_tags ?? []);
    }
  }, [visible]);

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
            <Button variant="primary" onClick={saveBlockedTags}>Save</Button>
          </SpaceBetween>
        </Box>
      }
      header="Edit Blocked Tags"
    >
      <div className="flex gap-2 items-center">
        <div className="grow">
          <Autosuggest
            onChange={({ detail }) => setTagInputValue(detail.value)}
            value={tagInputValue}
            placeholder="Search for tags"
            empty="No tags found"
            enteredTextLabel={(value) => `Use "${value}"`}
            options={data?.data?.results?.map((x) => ({ label: x, value: x })) ?? []}
            onSelect={({ detail }) => {
              let tag = null;
              if (detail.selectedOption) {
                tag = detail.selectedOption.value;
              } else {
                tag = detail.value;
              }
              if (!tag) return;
              const newTags = [...tags, tag];
              setTags(newTags.filter((x, i) => x && newTags.indexOf(x) === i));
              setTagInputValue("");
            }}
            onLoadItems={({ detail }) => {
              setTagLoadInputValue(detail.filteringText);
            }}
            statusType={isLoading ? "loading" : "finished"}
          />
        </div>
      </div>
      <TokenGroup
        items={tags.map((x) => ({ label: x })) ?? []}
        onDismiss={({ detail: { itemIndex } }) => {
          const selectedTag = tags[itemIndex];
          setTags(tags.filter((x) => x !== selectedTag));
        }}
      />
    </Modal>
  );
};