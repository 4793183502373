import { Autosuggest } from "@cloudscape-design/components";
import React, { FC, useContext, useEffect } from "react";
import "./AutoSuggestEditor.css";
import { YoutubeVideosContext } from "../../Layout";
import { Vertical } from "../../../../types/verticalTypes";
import { useGetTopSearchDataV2 } from "../../../../components/CommonSearch/api/hooks/useGetTopSearchDataV2";
import { convertData } from "../../../../components/CommonSearch/CommonSearch";

type AutoSuggestEditorProps = {
  videoId: string;
};

export const AutoSuggestEditor: FC<AutoSuggestEditorProps> = ({ videoId }) => {
  const [value, setValue] = React.useState("");
  const { modifiedData, setModifiedData } = useContext(YoutubeVideosContext);
  const { data: topSearchData, isLoading, error } = useGetTopSearchDataV2({ 
    q: value, 
    verticals: [Vertical.Games, Vertical.Movies, Vertical.Series],
  } as any);

  useEffect(() => {
    if (Object.keys(modifiedData).length === 0) {
      setValue("");
    } else {
      const modifiedValue = modifiedData[videoId];
      if (modifiedValue) {
        setValue(modifiedValue.label);
      }
    }
  }, [modifiedData]);

  const getStatusType = () => {
    if (error) return "error";
    if (isLoading) return "loading";
    if (topSearchData && topSearchData.results?.length > 0) return "finished";

    return "pending";
  };

  return (
    <Autosuggest
      onChange={({ detail }) => {
        setValue(detail.value);

        if (detail.value === "") {
          delete modifiedData[videoId];
          setModifiedData({ ...modifiedData });
        }
      }}
      onSelect={({ detail }) => {
        if (detail.selectedOption) {
          const label = detail.selectedOption.label;
          const value = detail.selectedOption.value;
          const newModifiedValue = {
            ...modifiedData,
            [videoId]: {
              label,
              value,
            },
          };

          setModifiedData(newModifiedValue);
        }
      }}
      value={value}
      options={(topSearchData && topSearchData.results?.length > 0) ? convertData(topSearchData.results) : []}
      ariaLabel="Autosuggest example with suggestions"
      placeholder="Search for a title"
      empty="No matches found"
      expandToViewport
      statusType={getStatusType()}
    />
  );
};
