import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchItemData = createAsyncThunk(
  "itemData/fetchItemData",
  async ({ id, params }) => {
    const idPrefix = id.split("-")[0];
    let path = "";
    switch (idPrefix) {
      case "series":
        path = "series";
        break;
      case "film":
        path = "movies";
        break;
      case "game":
        path = "games";
        break;
      case "franchise":
        path = "franchise";
        break;
      case "companies":
        path = "company";
        break;
      case "book":
        path = "books";
        break;
    }
   
    // Determine the URL based on whether the path is for a game
    // let url = `/${path}/search${idPrefix === "game" ? "" : "/v2"}?ip_id=${idPrefix === "company" ? id.replace("companies-", "") : id}`;
    const baseUrl = `/${path}/search`
    const apiVersionPath = idPrefix === "series" ? "/v3" : ""
    const idParam = idPrefix === "companies" ? id.replace("companies-", "") : id
    let url = `${baseUrl}${apiVersionPath}?ip_id=${idParam}`;

    const response = await axiosInstance.request({
      url: url,
      method: "GET",
      params: params,
    });

    return response;
  }
);



const itemDataSlice = createSlice({
  name: "itemData",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchItemData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchItemData.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
        if (state.data?.tiktok) {
          state.data.tiktok = state.data.tiktok.split(",").map((x) => x.trim());
        }
        if (state.data?.instagram) {
          state.data.instagram = state.data.instagram
            .split(",")
            .map((x) => x.trim());
        }
      })
      .addCase(fetchItemData.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const itemDataReducer = itemDataSlice.reducer;

export default itemDataReducer;

export const itemDataActions = itemDataSlice.actions;
