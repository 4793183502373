import React, { useEffect, useState } from "react";
import { FormField, Select } from "@cloudscape-design/components";
import { Filter } from "../../../types/filterTypes";

const options = [
  { value: "undefined", label: "True/False" },
  { value: "true", label: "True" },
  { value: "false", label: "False" },
];

interface BoolFilterProps {
  filterObject: Filter;
  setFilterObject: (filterObject: Filter) => void;
}

export const BoolFilter: React.FC<BoolFilterProps> = ({
  filterObject,
  setFilterObject,
}) => {

  const [value, setValue] = useState<string>(options[0].value);

  useEffect(() => {
    if (filterObject.value != null) {
      setValue(filterObject.value[0].toString());
    } else {
      setValue(options[0].value);
    }
  }, [filterObject]);

  return (
    <FormField label={
      <div className="flex justify-between">
        <div>{filterObject.label}</div>
      </div>
    }>
      <Select
        options={options}
        selectedOption={options.find((option) => option.value === value.toString())}
        onChange={({ detail }) => {
          setValue(detail.selectedOption.value);
          const newFilterObject = {
            ...filterObject,
            value: [detail.selectedOption.value === "true"],
          };
          if (detail.selectedOption.value === "undefined") {
            delete newFilterObject.value;
          }
          setFilterObject(newFilterObject);
        }}
      />
    </FormField>
  );
};