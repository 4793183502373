import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getSavedTitleListsData } from "../request";


export const useGetSavedTitleListsData = () => {

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_SAVED_TITLE_LISTS_DATA],
    () => getSavedTitleListsData(),
  );

  return {
    data: data?.data,
    isLoading,
    error,
  };
};