import { Icon, Link } from "@cloudscape-design/components";
import RatingPercent from "./RatingPercent";
import shortenInteger from "../../../../../utils/shortenInteger";

export const SteamReviews = ({
  positiveReviews,
  negativeReviews,
  ratingDescription,
  appid,
}) => (
  <div>
    <Link 
      href={`https://store.steampowered.com/app/${appid}/reviews/?browsefilter=toprated`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <div className="font-bold">Steam Reviews{" "}<Icon name="external" size="inherit" /></div>
    </Link>
    <div className="flex">
      <span className="flex">
        <Icon name="thumbs-up" variant="success" />
        <span className="ml-1">{shortenInteger(positiveReviews)}</span>
      </span>
      <span className="ml-2" />
      <span className="flex">
        <Icon name="thumbs-down" variant="error" />
        <span className="ml-1">{shortenInteger(negativeReviews)}</span>
      </span>
    </div>
    <div className="italic dark:text-slate-400 text-xs">
      {ratingDescription}
    </div>
  </div>
);

export const MetacriticScore = ({ score, votes, slug }) => (
  <div className="flex space-x-2 items-center">
    <div
      className="rounded-lg w-12 h-12 flex items-center"
      style={{
        backgroundColor:
          score >= 75 ? "green" : score >= 50 && score < 75 ? "#fc3" : "red",
      }}
    >
      <div className="m-auto text-2xl font-bold" style={{ color: "white" }}>
        {score}
      </div>
    </div>
    <div className="flex flex-col">
      <Link 
        href={`https://www.metacritic.com/game/${slug}/critic-reviews/`} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <div className="whitespace-pre-line font-bold text-left">
          <div>
            Metacritic
            <br />
            Score{" "}
            <Icon name="external" size="inherit" />
          </div>
        </div>
      </Link>
      <div className="whitespace-pre-line italic dark:text-slate-400 text-xs">
        {`${(votes ?? 0).toLocaleString()} reviews`}
      </div>
    </div>
  </div>
);

export const MetacriticUserScore = ({ score, votes, slug }) => (
  <RatingPercent
    percent={score * 10}
    title={
      <Link 
        href={`https://www.metacritic.com/game/${slug}/user-reviews/`} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <div className="whitespace-pre-line font-bold text-left">
          <div>
            Metacritic
            <br />
            User Score{" "}
            <Icon name="external" size="inherit" />
          </div>
        </div>
      </Link>
    }
    subtitle={`${votes?.toLocaleString() ?? "N/A"} reviews`}
  />
);
