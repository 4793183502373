export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || "";
export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const IP_LIST = [
  { label: "Pantheon", value: "Pantheon" },
  { label: "NBCU", value: "nbcu" },
  { label: "DreamWorks", value: "DreamWorks" },
];

export const TAGS_LIST = [
  { label: "Wikipedia", value: "wikipedia" },
  { label: "Google Trends", value: "trends" },
  { label: "YouTube", value: "youtube" },
  { label: "YouTube Channel", value: "youtubeChannel" },
  { label: "AO3", value: "a03" },
  { label: "Instagram", value: "instagram" },
  { label: "Wattpad", value: "wattpad" },
  { label: "Reddit", value: "subreddit" },
  { label: "Parent Company", value: "parentCompany" },
  { label: "Twitter", value: "twitter" },
  { label: "TikTok", value: "tiktok" },
  { label: "Fandom", value: "fandom" },
  { label: "TMS ID", value: "tmsId" },
  { label: "EMS ID", value: "emsId" },
  { label: "Rotten Tomatoes", value: "rtUrl" },
  { label: "Twitch", value: "twitch" },
  { label: "Discord", value: "discord" },
  { label: "Steam", value: "steam" },
  { label: "Wikidata ID", value: "wikidata_id" },
  { label: "IMDb ID", value: "imdb_id" },
  { label: "Custom Tags", value: "cTags" },
];

export const COMMON_BOX_OFFICE_REGIONS = {
  XDOM: "Domestic",
  XWW: "WorldWide",
  XNDOM: "International",
  ES: "Spain",
  GB: "United Kingdom",
  AU: "Australia",
  DE: "Germany",
  IT: "Italy",
  FR: "France",
  MX: "Mexico",
  KR: "South Korea",
  BR: "Brazil",
  JP: "Japan",
  TH: "Thailand",
  IN: "India",
  CA: "Canada",
};

export const regionGroups = {
  Regions: ["XDOM", "XWW", "XNDOM"],
  Countries: [
    "ES",
    "GB",
    "AU",
    "DE",
    "IT",
    "FR",
    "MX",
    "KR",
    "BR",
    "JP",
    "TH",
    "IN",
    "CA",
  ],
};

export const formattedBoxOfficeRegions = Object.entries(regionGroups).map(
  ([groupLabel, regionKeys]) => {
    const options = regionKeys.map((key) => ({
      label: COMMON_BOX_OFFICE_REGIONS[key],
      value: key,
      tags: key === "XDOM" ? ["US & Canada"] : [],
    }));
    return { label: groupLabel, options };
  },
);

export const occassion = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Weekend", value: "weekend" },
];

export const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

export const TYPES = {
  PERSONAL: "personal",
};

export const SERVICES = {
  PLATFORMS: "platforms",
  FAVORITES: "favorites",
  RANKING: "ranking",
  EXPLORE: "explore",
  DASHBOARDS: "dashboards",
  TOOLS: "tools",
};

export const PLATFORMS = {
  WIKIPEDIA: {
    name: "Wikipedia",
    key: "wikipedia",
  },
  TWITTER: {
    name: "Twitter",
    key: "twitter",
  },
  GOOGLE_TRENDS: {
    name: "Google Trends",
    key: "googletrends",
  },
  REDDIT: {
    name: "Reddit",
    key: "reddit",
  },
  AO3: {
    name: "AO3",
    key: "ao3",
  },
  FANDOM: {
    name: "Fandom",
    key: "fandom",
  },
  WATTPAD: {
    name: "Wattpad",
    key: "wattpad",
  },
  YOUTUBE: {
    name: "YouTube",
    key: "youtube",
  },
  STEAM: {
    name: "Steam",
    key: "steam",
  },
  TWITCH: {
    name: "Twitch",
    key: "twitch",
  },
  PIRACY: {
    name: "Piracy",
    key: "piracy",
  },
  ROTTEN_TOMATOES: {
    name: "Rotten Tomatoes",
    key: "rottentomatoes",
  },
  IMDB: {
    name: "IMDb",
    key: "imdb",
  },
  PANTHEON_GLOBAL: {
    name: "Global",
    key: "pantheon_global",
  },
  GLOBAL_WIKIPEDIA: {
    name: "Wikipedia (Global)",
    key: "global_wikipedia",
  },
  GLOBAL_STEAM: {
    name: "Steam (Global)",
    key: "global_steam",
  },
  GLOBAL_TWITCH: {
    name: "Twitch (Global)",
    key: "global_twitch",
  },
  GLOBAL_IMDB: {
    name: "IMDb (Global)",
    key: "global_imdb",
  },
  GLOBAL_ROTTEN_TOMATOES: {
    name: "Rotten Tomatoes (Global)",
    key: "global_rottentomatoes",
  },
  GLOBAL_YOUTUBE: {
    name: "YouTube (Global)",
    key: "global_youtube",
  },
  GOOGLE_SEARCH: {
    name: "Google Search",
    key: "google",
  },
  BOX_OFFICE: {
    name: "Box Office",
    key: "boxoffice",
  },
};

export const DATAPOINTS = {
  WIKIPEDIA: {
    PAGE_VIEWS: {
      name: "Page Views",
      key: "page_views",
    },
    RANK: {
      name: "Rank",
      key: "rank",
    },
    ZSCORE: {
      name: "Z-Score",
      key: "wikipedia",
    },
  },
  GOOGLE_TRENDS: {
    WORLDWIDE: {
      name: "Worldwide",
      key: "score",
    },
  },
  TWITTER: {
    TWEET_COUNT: {
      name: "Tweets",
      key: "tweets",
    },
  },
  REDDIT: {
    UPVOTES: {
      name: "Upvotes",
      key: "upvotes",
    },
    COMMENTS: {
      name: "Comments",
      key: "comments",
    },
    SUBREDDIT_MEMBERS: {
      name: "Members",
      key: "subscribers",
    },
  },
  STEAM: {
    PLAYER_COUNT: {
      name: "Players",
      key: "player_count",
    },
    RANK: {
      name: "Rank",
      key: "rank",
    },
    ZSCORE: {
      name: "Z-Score",
      key: "steam",
    },
  },
  TWITCH: {
    VIEWER_COUNT: {
      name: "Viewers",
      key: "viewers",
    },
    STREAMER_COUNT: {
      name: "Streamers",
      key: "broadcasters",
    },
    RANK: {
      name: "Rank",
      key: "rank",
    },
    ZSCORE: {
      name: "Z-Score",
      key: "twitch",
    },
  },
  YOUTUBE: {
    VIEWS: {
      name: "Views",
      key: "views",
    },
    COMMENTS: {
      name: "Comments",
      key: "comments",
    },
    LIKES: {
      name: "Likes",
      key: "likes",
    },
    RANK: {
      name: "Rank",
      key: "rank",
    },
    ZSCORE: {
      name: "Z-Score",
      key: "youtube",
    },
  },
  WATTPAD: {
    VIEWS: {
      name: "Views",
      key: "views",
    },
    COMMENTS: {
      name: "Comments",
      key: "comments",
    },
    STARS: {
      name: "Stars",
      key: "stars",
    },
  },
  AO3: {
    HITS: {
      name: "Hits",
      key: "hits",
    },
    COMMENTS: {
      name: "Comments",
      key: "comments",
    },
    KUDOS: {
      name: "Kudos",
      key: "kudos",
    },
  },
  PIRACY: {
    DOWNLOADS: {
      name: "Downloads",
      key: "downloads",
    },
    ZSCORE: {
      name: "Z-Score",
      key: "piracy",
    },
  },
  IMDB: {
    VOTES: {
      name: "Votes",
      key: "votes",
    },
    RATING: {
      name: "Rating",
      key: "rating",
    },
    RANK: {
      name: "Rank",
      key: "rank",
    },
    ZSCORE: {
      name: "Z-Score",
      key: "imdb",
    },
  },
  ROTTEN_TOMATOES: {
    AUDIENCE_VOTES: {
      name: "Audience Votes",
      key: "audience_votes",
    },
    AUDIENCE_RATING: {
      name: "Audience Rating",
      key: "audience_rating",
    },
    CRITIC_VOTES: {
      name: "Critic Votes",
      key: "critic_votes",
    },
    CRITIC_RATING: {
      name: "Critic Rating",
      key: "critic_votes",
    },
    RANK: {
      name: "Rank",
      key: "rank",
    },
    ZSCORE: {
      name: "Z-Score",
      key: "rotten_tomatoes",
    },
  },
  GOOGLE_SEARCH: {
    VOLUME_GENERAL_CATEGORY: {
      name: "Interest (Title & Category)",
      key: "general.category",
    },
    VOLUME_GENERAL_RELEASE_YEAR: {
      name: "Interest (Title & Release Year)",
      key: "general.release_year",
    },
    VOLUME_INTENT_TO_WATCH_WHERE_TO_WATCH: {
      name: "Interest (Intent to Watch - Where to Watch)",
      key: "intent_to_watch.where_to_watch",
    },
    VOLUME_INTENT_TO_WATCH_NETFLIX: {
      name: "Interest (Intent to Watch - Netflix)",
      key: "intent_to_watch.ott.netflix",
    },
    VOLUME_INTENT_TO_WATCH_DISNEY_PLUS: {
      name: "Interest (Intent to Watch - Disney+)",
      key: "intent_to_watch.ott.disneyplus",
    },
    VOLUME_INTENT_TO_WATCH_HBO_MAX: {
      name: "Interest (Intent to Watch - HBO Max)",
      key: "intent_to_watch.ott.hbomax",
    },
    VOLUME_INTENT_TO_WATCH_HULU: {
      name: "Interest (Intent to Watch - Hulu)",
      key: "intent_to_watch.ott.hulu",
    },
    VOLUME_INTENT_TO_WATCH_AMAZON_PRIME_VIDEO: {
      name: "Interest (Intent to Watch - Amazon Prime Video)",
      key: "intent_to_watch.ott.amazonprimevideo",
    },
    VOLUME_INTENT_TO_WATCH_APPLE_TV_PLUS: {
      name: "Interest (Intent to Watch - Apple TV+)",
      key: "intent_to_watch.ott.appletvplus",
    },
    VOLUME_INTENT_TO_WATCH_PEACOCK: {
      name: "Interest (Intent to Watch - Peacock)",
      key: "intent_to_watch.ott.peacock",
    },
    VOLUME_INTENT_TO_WATCH_PARAMOUNT_PLUS: {
      name: "Interest (Intent to Watch - Paramount+)",
      key: "intent_to_watch.ott.paramountplus",
    },
    VOLUME_INTENT_TO_WATCH_CABLE: {
      name: "Interest (Intent to Watch - Cable)",
      key: "intent_to_watch.cable",
    },
    VOLUME_INTENT_TO_WATCH_PHYSICAL: {
      name: "Interest (Intent to Watch - Physical)",
      key: "intent_to_watch.physical",
    },
    VOLUME_INTENT_TO_WATCH_THEATRICAL: {
      name: "Interest (Intent to Watch - Theatrical)",
      key: "intent_to_watch.theatrical",
    },
    VOLUME_INTENT_TO_WATCH_PIRACY: {
      name: "Interest (Intent to Watch - Piracy)",
      key: "intent_to_watch.piracy",
    },
    VOLUME_INTENT_TO_WATCH_LATEST_SEASON: {
      name: "Interest (Intent to Watch - Latest Season)",
      key: "intent_to_watch.latest_season",
    },
    VOLUME_INTENT_TO_PLAY_WHERE_TO_PLAY: {
      name: "Interest (Intent to Play - Where to Play)",
      key: "intent_to_play.where_to_play",
    },
    VOLUME_INTENT_TO_PLAY_STEAM: {
      name: "Interest (Intent to Play - Steam)",
      key: "intent_to_play.digital.steam",
    },
    VOLUME_INTENT_TO_PLAY_EPIC_GAMES: {
      name: "Interest (Intent to Play - Epic Games Store)",
      key: "intent_to_play.digital.epicgames",
    },
    VOLUME_INTENT_TO_PLAY_GOG: {
      name: "Interest (Intent to Play - GOG)",
      key: "intent_to_play.digital.gog",
    },
    VOLUME_INTENT_TO_PLAY_PLAYSTATION: {
      name: "Interest (Intent to Play - PlayStation)",
      key: "intent_to_play.digital.playstation",
    },
    VOLUME_INTENT_TO_PLAY_XBOX: {
      name: "Interest (Intent to Play - Xbox)",
      key: "intent_to_play.digital.xbox",
    },
    VOLUME_INTENT_TO_PLAY_CONSOLE: {
      name: "Interest (Intent to Play - Console)",
      key: "intent_to_play.physical.console",
    },
    VOLUME_INTENT_TO_PLAY_PC: {
      name: "Interest (Intent to Play - PC)",
      key: "intent_to_play.physical.pc",
    },
    VOLUME_LEARN_ABOUT_GENERAL: {
      name: "Interest (Learn About - General)",
      key: "learn_about.general",
    },
    VOLUME_LEARN_ABOUT_NEWS: {
      name: "Interest (Learn About - News)",
      key: "learn_about.news",
    },
    VOLUME_LEARN_ABOUT_SOCIAL: {
      name: "Interest (Learn About - Social)",
      key: "learn_about.social",
    },
    VOLUME_LEARN_ABOUT_AWARDS: {
      name: "Interest (Learn About - Awards)",
      key: "learn_about.awards",
    },
    VOLUME_LEARN_ABOUT_REVIEWS: {
      name: "Interest (Learn About - Reviews)",
      key: "reviews",
    },
    VOLUME_LEARN_ABOUT_TRAILERS: {
      name: "Interest (Learn About - Trailers)",
      key: "learn_about.trailers",
    },
  },
  BOX_OFFICE: {
    GROSS_DOMESTIC: {
      name: "Domestic Gross",
      key: "gross",
    },
  },
};

export const YOUTUBE_CATEGORIES = [
  { label: "All", value: "All" },
  { label: "Gaming", value: "Gaming" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Sports", value: "Sports" },
  { label: "Music", value: "Music" },
  { label: "People & Blogs", value: "People & Blogs" },
  { label: "Film & Animation", value: "Film & Animation" },
  { label: "News & Politics", value: "News & Politics" },
  { label: "Comedy", value: "Comedy" },
  { label: "Education", value: "Education" },
  { label: "Autos & Vehicles", value: "Autos & Vehicles" },
  { label: "Howto & Style", value: "Howto & Style" },
  { label: "Science & Technology", value: "Science & Technology" },
  { label: "Travel & Events", value: "Travel & Events" },
  { label: "Pets & Animals", value: "Pets & Animals" },
  { label: "Nonprofits & Activism", value: "Nonprofits & Activism" },
];
