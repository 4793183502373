import React, { useState } from "react";
import { FrequencyChart } from "../../../dashboards/news-dashboard/components/FrequencyChart";
import NewsRiver from "../../../../components/news-river/NewsRiver";
import { DEFAULT_DARK_STROKES } from "../../../../components/datapoint-comparison/constants";

interface NewsProps {
  ip: string;
  ip_id: string;
}

export const News: React.FC<NewsProps> = ({
  ip,
  ip_id,
}) => {

  const [timeseriesData, setTimeseriesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalArticles, setTotalArticles] = useState<number>(0);

  const onNewsResponseChange = ({ timeseriesData, isLoading, totalArticles }) => {
    setIsLoading(isLoading);
    setTotalArticles(totalArticles);
    const item = timeseriesData?.find(item => item.filter === ip_id);
    const color = DEFAULT_DARK_STROKES[0];
    if (item) {
      setTimeseriesData([{ ...item, color, filterOption: { label: ip, value: ip_id } }]);
    } else {
      setTimeseriesData([]);
    }
  };

  return (
    <div>
      {!isLoading && totalArticles > 0 && (
        <div style={{ width: "100%", height: "150px" }} className="mb-2">
          <FrequencyChart
            timeseriesData={timeseriesData}
          />
        </div>
      )}
      <NewsRiver
        pageSize={50}
        ipIds={[ip_id]}
        useFullPageScrollTarget={true}
        onResponseChange={onNewsResponseChange}
      />
    </div>
  );
};