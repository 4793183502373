import { ButtonDropdown, ContentLayout, Header, Icon, Multiselect, Popover, Select, SpaceBetween, StatusIndicator, Tabs } from "@cloudscape-design/components";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { RankingRawDataV2 } from "./components/RankingRawDataV2";
import { BasicParameters } from "../../global-ranking/pantheon-ranking/components/BasicParameters";
import moment from "moment";
import { TabActions } from "../../../../components/TabActions";
import { IP_LIST, SERVICES, TYPES } from "../../../../config-global";
import { Vertical, verticalOptionsWithAll } from "../../../../types/verticalTypes";
import { DEFAULT_TRENDING_FILTERS, DEFAULT_TRENDING_WEIGHTS, platforms } from "../constants";
import _ from "lodash";
import { useGetTrendingRanking } from "./api/hooks/useGetTrendingRankingData";
import { RankingTileView } from "./components/RankingTileView";
import { useSearchParams } from "react-router-dom";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { additionalColumns } from "../../global-ranking/pantheon-ranking/GRPantheonRankingIndex";
import { useFilterDrawer } from "../../../../hooks/FilterDrawer/useFilterDrawer";
import { useWeightDrawer } from "../../../../hooks/WeightDrawer/useWeightDrawer";
import { TrendingInfoDrawer } from "./components/TrendingInfoDrawer";
import { useGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGenericPreference_V2";

export const RankingRawDataPageIndex = () => {

  const title = "Tracked Titles";
  const breadcrumbs = [
    { text: "Rankings" },
    { text: title },
  ];
  const apiParams = {
    type: TYPES.PERSONAL,
    service: SERVICES.RANKING,
    module: "trending",
  };

  const handlePreferenceFirstLoad = (preferenceData) => {
    if (preferenceData) {
      if (preferenceData.primaryTab && !searchParams.get("tab")) {
        setActiveTabId(preferenceData.primaryTab);
      }
      if (preferenceData.selectedAdditionalColumns) {
        setSelectedAdditionalColumns(additionalColumnOptions.filter(o => preferenceData.selectedAdditionalColumns.includes(o.value)));
      }
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const { preferenceData, savePreference } = useGenericPreference_V2({ apiParams, onPreferenceFirstLoad: handlePreferenceFirstLoad });

  const gridRef = useRef(null);

  const [dateRange, setDateRange] = useState({
    type: "relative",
    amount: 7,
    unit: "day",
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [activeTabId, setActiveTabId] = useState(searchParams.get("tab") ?? preferenceData?.primaryTab ?? "tile");
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState<Array<OptionDefinition>>([]);
  const [filteredData, setFilteredData] = useState([]);

  const {
    filterDrawerConfig,
    filterObject,
    setFilterValue,
  } = useFilterDrawer({ 
    defaultFilterObject: DEFAULT_TRENDING_FILTERS, 
    prefsApiParams: apiParams, 
    gridRef,
    treatAsDefault: { vertical: "all", ip_list: "Pantheon" },
  });

  const {
    weightDrawerConfig,
    weightsObject,
  } = useWeightDrawer({
    defaultWeightObject: DEFAULT_TRENDING_WEIGHTS,
    prefsApiParams: apiParams,
  });


  const selectedVertical = useMemo(() => verticalOptionsWithAll.find(o => o.value === (filterObject.filters.find(f => f.field === "vertical")?.value?.[0] || "all")), [filterObject]);
  const selectedIpList = useMemo(() => IP_LIST.find(o => o.value === (filterObject.filters.find(f => f.field === "ip_list")?.value?.[0] || "all")), [filterObject]);
  const titleSearchQuery = useMemo(() => filterObject.filters.find(f => f.field === "ip")?.value?.[0] || "", [filterObject]);

  const additionalColumnOptions = useMemo<Array<OptionDefinition>>(() => {
    const additionalColumnKeys = selectedVertical.value === "all" ? Array.from(new Set(Object.values(additionalColumns).flat())) : additionalColumns[selectedVertical.value];
    return additionalColumnKeys.filter(key => key !== "Score Change").map(key => ({ label: key, value: key }));
  }, [selectedVertical, additionalColumns]);

  const { data, isLoading, error } = useGetTrendingRanking({
    startTs: moment.utc(dateRange.startDate).startOf("day").unix(),
    endTs: moment.utc(dateRange.endDate).startOf("day").unix(),
  });

  const processedData = useMemo(() => {
    if (!data || !data?.data?.data) {
      return [];
    }

    const allDatapoints = platforms.map(platform => platform.key);
    const weights = weightsObject.weights.map(w => ({ ...w, weight: w.enabled ? w.weight : 0 }))
      .reduce((prev, curr) => ({ ...prev, [curr.field]: curr }), {});

    const filteredData = data.data.data
      .filter((x) => (selectedVertical.value === "all" || x.vertical === selectedVertical.value) && x.vertical !== Vertical.Franchises)
      .filter((x) => x.ip_list && x.ip_list.includes(selectedIpList.value));

    const rowData = filteredData.map(item => {
      let weightedScoreTotal = 0;
      let weightTotal = 0;
      allDatapoints.forEach(datapoint => {
        if (!item.curr_period[datapoint as string]) {
          return;
        }
        const score = item.curr_period[datapoint as string];
        const weight = weights[datapoint as string]?.weight ?? 1.0;
        weightedScoreTotal += score * weight;
        weightTotal += weight;
      });
      const score = weightedScoreTotal / weightTotal;

      weightedScoreTotal = 0;
      weightTotal = 0;
      allDatapoints.forEach(datapoint => {
        if (!item.prev_period[datapoint as string]) {
          return;
        }
        const score = item.prev_period[datapoint as string];
        const weight = weights[datapoint as string]?.weight ?? 1.0;
        weightedScoreTotal += score * weight;
        weightTotal += weight;
      });
      const prevScore = weightedScoreTotal / weightTotal;

      let newItem = {
        ...item,
        ...item.curr_period,
        score,
        score_prev: prevScore,
      };
      delete newItem.curr_period;
      delete newItem.prev_period;

      return newItem;
    })
      .sort((a, b) => b.score_prev - a.score_prev)
      .map((item, index) => ({ ...item, rank_prev: index + 1 }))
      .sort((a, b) => b.score - a.score)
      .map((item, index) => ({ ...item, rank: index + 1, rank_change: item.rank_prev - (index + 1) }));

    const filteredRowData = rowData.filter((x) => titleSearchQuery?.length === 0 || x.ip.toLowerCase().includes(titleSearchQuery.toLowerCase()));

    return filteredRowData;
  }, [data, weightsObject, selectedVertical, selectedIpList, titleSearchQuery]);

  const getTab = ({ id, label }) => {
    return {
      label: label,
      id: id,
      action: <TabActions tabId={id} preferencesApiParams={apiParams} />,
    };
  };

  const infoDrawerConfig = {
    id: "infoDrawer",
    content: (
      <TrendingInfoDrawer />
    ),
    trigger: {
      iconName: "status-info",
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
  };

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      drawers={[infoDrawerConfig, filterDrawerConfig, weightDrawerConfig] as any}
      contentType="table"
      headerSelector="#header"
      content={
        <ContentLayout
          disableOverlap
          header={
            <Header
              variant="h2"
              description={
                <div>
                  View titles that are
                  {" "}
                  <Popover
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="text"
                    content={
                      <StatusIndicator type="info">
                        <span className="break-normal">A <b>tracked</b> title is one for which data is collected across all platforms available on Pantheon</span>
                      </StatusIndicator>
                    }
                  >
                    tracked
                  </Popover>
                  {" "}
                  and ranked based on an extended set of weighted platform metrics
                </div>
              }
            >
              {title}
            </Header>
          }
        >
          <div className="flex flex-col gap-4">
            <Tabs
              disableContentPaddings
              onChange={({ detail }) => {
                setActiveTabId(detail.activeTabId);
                setSearchParams({ tab: detail.activeTabId });
              }}
              activeTabId={activeTabId}
              tabs={[
                getTab({ id: "tile", label: "Tile View" }),
                getTab({ id: "table", label: "Table View" }),
              ]}
            />
            <BasicParameters
              dateRange={dateRange}
              setDateRange={setDateRange}
              titleSearchQuery={titleSearchQuery}
              setTitleSearchQuery={(query) => {
                setFilterValue("ip", query);
              }}
              showAdvancedFilter={false}
              additionalFilters={
                <>
                  <ButtonDropdown
                    items={[
                      { text: "Data as CSV", id: "csv" },
                      { text: "Data as XLSX", id: "xlsx" },
                    ]}
                    onItemClick={({ detail }) => {
                      const baseName = `${selectedIpList?.label}_trending_${selectedVertical?.label}_${dateRange.startDate}_${dateRange.endDate}`.toLowerCase().replaceAll(" ", "_");
                      switch (detail.id) {
                        case "csv":
                          gridRef.current?.api?.exportDataAsCsv({
                            fileName: `${baseName}.csv`,
                          });
                          break;
                        case "xlsx":
                          gridRef.current?.api?.exportDataAsExcel({
                            fileName: `${baseName}.xlsx`,
                          });
                          break;
                      }
                    }}  
                  >
                    Export
                  </ButtonDropdown>
                  {activeTabId === "table" && (
                    <Multiselect
                      options={additionalColumnOptions}
                      selectedOptions={selectedAdditionalColumns}
                      onChange={({ detail }) => {
                        setSelectedAdditionalColumns(detail.selectedOptions as Array<OptionDefinition>);
                        savePreference({
                          selectedAdditionalColumns: detail.selectedOptions.map(o => o.value),
                        });
                      }}
                      placeholder="Additional columns"
                      hideTokens
                    />
                  )}
                  <Select
                    options={verticalOptionsWithAll}
                    selectedOption={selectedVertical}
                    onChange={({ detail }) => {
                      setFilterValue("vertical", detail.selectedOption.value);
                    }}
                  />
                  <Select
                    options={IP_LIST}
                    selectedOption={selectedIpList}
                    onChange={({ detail }) => {
                      setFilterValue("ip_list", detail.selectedOption.value);
                    }}
                  />
                </>
              }
            />
            {activeTabId === "tile" && (
              <RankingTileView
                data={filteredData}
                isLoading={isLoading}
                enabledWeightColumns={weightsObject.weights.filter(w => w.enabled).map(w => w.field)}
              />
            )}
            {
            // Tile view uses data that's been filtered and sorted by ag-grid (filteredData)
            // which is why we have to keep the table in the render cycle even if its tab isn't active
            }
            <div className={activeTabId === "table" ? "" : "hidden"}>
              <RankingRawDataV2
                gridRef={gridRef}
                data={processedData}
                isLoading={isLoading}
                enabledWeightColumns={weightsObject.weights.filter(w => w.enabled).map(w => w.field)}
                additionalColumnOptions={additionalColumnOptions}
                selectedAdditionalColumns={selectedAdditionalColumns}
                setFilteredData={setFilteredData}
              />
            </div>
          </div>
        </ContentLayout>
      }
    />
  );
};