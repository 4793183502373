import moment from "moment";
import { useMemo } from "react";
import { CHART_TYPES, useGetChartTheme } from "../../../../../hooks/UseTheme/useGetChartTheme";
import { useNewsRiverDashboardContext } from "../../hooks/useNewsRiverDashboardContext";
import _ from "lodash";

const defaultOverrides = {
  overrides: {
    common: {
      axes: {
        number: {
          label: {
            fontFamily: "Open Sans",
            fontSize: 10,
          },
        },
        category: {
          label: {
            fontFamily: "Open Sans",
            fontSize: 10,
          },
        },
      },
      legend: {
        item: {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
    },
    bar: {
      series: {
        label: {
          fontFamily: "Open Sans",
          fontSize: 12,
        },
      },
    },
  },
};

export const useChartConfig = (timeseriesData: any, setDateRange?: (dateRange: DateRange) => void) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT, defaultOverrides);

  const options = useMemo(() => {
    const data = [];

    if (timeseriesData?.length > 0) {
      const allTimestamps = timeseriesData.map((data: any) => data.data.map((d: any) => d.timestamp)).flat();
      const minTimestamp = Math.min(...allTimestamps);
      const maxTimestamp = Math.max(...allTimestamps);
      
      let timestamp = minTimestamp;
      while (timestamp <= maxTimestamp) {
        const dataAtTimestamp = timeseriesData.map((data: any) => {
          const dataAtTimestamp = data.data.find((d: any) => d.timestamp === timestamp);
          return {
            timestamp,
            [data.filterOption.value]: dataAtTimestamp?.value ?? 0,
          };
        }).reduce((acc, curr) => ({ ...acc, ...curr }), {});
        data.push({ timestamp, ...dataAtTimestamp });
        timestamp += 86400;
      }
    }

    return {
      data: data,
      series: timeseriesData?.map((data: any) => (
        {
          type: "bar",
          xKey: "timestamp",
          yKey: data.filterOption.value,
          yName: data.filterOption.label,
          fill: data.color,
          listeners: {
            nodeClick: (event: any) => {
              const clickedTimestamp = event.datum.timestamp;
              setDateRange?.({
                type: "absolute",
                startDate: moment.utc(clickedTimestamp * 1000).toISOString(),
                endDate: moment.utc(clickedTimestamp * 1000).add(1, "day").toISOString(),
              });
            },
          },
          tooltip: {
            renderer: (params: any) => {
              console.log(params)
              return {
                title: moment.utc(params.datum.timestamp * 1000).format("MMM D, YYYY"),
                content: `<b>${data.filterOption.label}</b>: ${params.datum[data.filterOption.value]} articles`,
              };
            },
          },
          label: {
            enabled: true,
            formatter: (params: any) => Math.round(params.value).toLocaleString(),
            placement: "outside",
            color: "white",
          },
        }
      )) ?? [],
      axes: [
        {
          type: "category",
          position: "bottom",
          label: {
            formatter: (params: any) => {
              return moment.utc(params.value * 1000).format("MMM D");
            },
          },
        },
        {
          type: "number",
          position: "left",
          label: {
            formatter: (params: any) => {
              return params.value;
            },
          },
          interval: {
            minSpacing: 30,
          },
          crosshair: { enabled: false },
        },
      ],
      padding: {
        top: 13,
        right: 0,
        bottom: 1,
        left: 0,
      },
      legend: {
        enabled: false
      },
      theme: theme as any,
      height: 150,
    };
  }, [timeseriesData, theme]);

  return options;
};