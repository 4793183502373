import {
  Box,
  Button,
  Grid,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { useDeleteVideos } from "../../../api/hooks/useDeleteVideos";
import { DeleteTrailerConfirmationModal } from "../modals/DeleteTrailerConfirmationModal";
import { fetchTrailersData } from "../../../redux/actions/item-actions";
import { TrailerCard } from "./TrailerCard";
import { columnDefs } from "./gridColumnDefinitions";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";

const TrailerGrid = ({
  id,
  videoId,
  triggerTrailersApi,
  setTriggerTrailersApi,
  setShowAddYoutubeTrailerModal,
}) => {
  const { theme } = useGetGridTheme();
  const isLoading = useSelector((state) => state?.itemTrailerData?.loading);
  const itemTrailerData = useSelector(
    (state) => state?.itemTrailerData?.data || [],
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (id && triggerTrailersApi) {
      fetchTrailersData({ id });
      setTriggerTrailersApi(false);
    }
  }, [id, setTriggerTrailersApi, triggerTrailersApi]);

  const sortedData = [...(itemTrailerData?.data ?? [])]
    .map((item, index) => ({ ...item, originalIndex: index }))
    .sort((a, b) => {
      return (b.official ? 1 : 0) - (a.official ? 1 : 0);
    })
    .map((item) => {
      const { originalIndex, ...rest } = item;
      return rest;
    });

  const filteredData = sortedData.filter((item) => item.videoId !== videoId);
  const gridDefinitions = filteredData?.map(() => ({
    colspan: { xxs: 12, s: 6, l: 4 },
  }));

  const { mutateAsync: deleteVideos, isLoading: isDeleteLoading } =
    useDeleteVideos();

  const handleOnDelete = () => {
    if (selectedItems.length === 0) return;

    const videoIds = selectedItems.map(({ videoId }) => videoId);
    const payload = { videoIds };

    deleteVideos({ payload }).then(() => {
      setTriggerTrailersApi(true);
      setSelectedItems([]);
      setShowDeleteModal(false);
    });
  };

  if (!id) return null;

  return (
    <div className="grid">
      <div className="flex flex-1 justify-end">
        <div className="my-1">
          <Button
            variant="normal"
            iconName="add-plus"
            onClick={() => setShowAddYoutubeTrailerModal(true)}
          >
            Add
          </Button>
        </div>
        <div className="my-1 ml-2">
          <Button
            iconName="remove"
            onClick={() => setShowDeleteModal(true)}
            disabled={selectedItems.length <= 0}
          >
            Delete
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Box textAlign="center" padding="l">
          <CustomLoadingOverlay />
        </Box>
      ) : filteredData?.length === 0 ? (
        <Box textAlign="center" padding="xxl">
          No trailers found
        </Box>
      ) : (
        <SpaceBetween direction="vertical" size="l">
          <div className={`${theme} compact`}>
            <Table
              columnDefinitions={columnDefs}
              trackBy="videoId"
              loading={isLoading}
              items={filteredData}
              loadingText="Loading resources"
              resizableColumns={true}
              variant="full-page"
              selectionType="multi"
              selectedItems={selectedItems}
              onSelectionChange={({ detail }) =>
                setSelectedItems(detail.selectedItems)
              }
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No items</b>
                  <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                    No items to display.
                  </Box>
                </Box>
              }
              error={
                <Box textAlign="center" color="inherit">
                  <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                    Error displaying the data.
                  </Box>
                </Box>
              }
            />
          </div>
          <Grid gridDefinition={gridDefinitions}>
            {filteredData?.map((video) => (
              <TrailerCard video={video} key={video.videoId} showItemPageLink={false} />
            ))}
          </Grid>
        </SpaceBetween>
      )}
      <DeleteTrailerConfirmationModal
        visible={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        onDelete={() => handleOnDelete()}
        isLoading={isDeleteLoading}
      />
    </div>
  );
};

export default TrailerGrid;
