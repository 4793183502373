import axiosInstance from "../../../../../utils/axios";

interface GetTagsSearchProps {
  q: string;
  limit?: number;
}

export const getTagsSearch = ({
  q,
  limit,
}: GetTagsSearchProps) => {

  const params = {};
  if (q) params["q"] = q;
  if (limit) params["limit"] = limit;

  return axiosInstance.request({
    url: `/news/tags`,
    method: "GET",
    params: params,
  });
};