import React, { forwardRef, useState } from "react";
import { AdvancedFiltersV2 } from "../../../components/filters/AdvancedFilters_V2";
import { FiltersDropdownV2 } from "../../../components/filters/FiltersDropdown_V2";
import { Preferences } from "../../../services/generic_v2/Preferences";
import { usePlatformContext } from "../PlatformProvider";

export const PlatformFilters = forwardRef(
  ({ existingPref, filter, vertical, apiParams }: any, ref: any) => {
    const { setPlatformFilterName } = usePlatformContext();
    const [selectedFilterName, setSelectedFilterName] = useState(null);
    const { getPreferencesAfterDelete, getPreferencesAfterSaveOrUpdate } =
      new Preferences(existingPref, vertical, filter);

    const handleFilterItemClick = (id: string, filters: Object) => {
      const filter = filters[id];

      ref.current.api.setAdvancedFilterModel(filter);
      setSelectedFilterName(id);
      setPlatformFilterName(id);
    };

    const handleSetFilterName = (id: string) => {
      setSelectedFilterName(id);
      setPlatformFilterName(id);
    };

    const handleClearFilter = () => {
      ref.current.api.setAdvancedFilterModel(null);
      setPlatformFilterName(null);
    };

    return (
      <div className="flex gap-2 items-center">
        {/*
        <FiltersDropdownV2
          handleFilterItemClick={handleFilterItemClick}
          existingPref={existingPref}
          vertical={vertical}
        />
        */}
        <AdvancedFiltersV2
          handleFilterItemClick={handleFilterItemClick}
          handleClearFilter={handleClearFilter}
          handleSetFilterName={handleSetFilterName}
          selectedFilterName={selectedFilterName}
          filter={filter}
          existingPref={existingPref}
          apiParams={apiParams}
          getPreferencesAfterDelete={getPreferencesAfterDelete}
          getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
          vertical={vertical}
        />
      </div>
    );
  },
);
