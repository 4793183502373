import { Button, Spinner } from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import React, { FC, useEffect, useMemo, useState, forwardRef, useImperativeHandle } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import {
  MoviesType,
  useGetPantheonRanking,
} from "../api/hooks/useGetPantheonRankingData";
import {
  combineMappedResponses,
  mapGetPantheonResponse,
} from "../api/mappers/mapGetPantheonResponse";
import { Vertical } from "../../../../../types/verticalTypes";
import TitleTiles from "../../../../../components/title-tiles/TitleTiles";
import { TileData } from "../../../../../components/title-tiles/types";
import CompareIcon from "../../../../../assets/images/CompareIcon";
import { FaImdb, FaSteam, FaTwitch, FaWikipediaW, FaYoutube } from "react-icons/fa6";
import { GiPirateFlag } from "react-icons/gi";
import { SiRottentomatoes } from "react-icons/si";

const LIMIT = 100;

const verticalMap = {
  [Vertical.Movies]: "movies",
  [Vertical.Series]: "series",
  [Vertical.Games]: "gaming",
};

const platformIconMap = {
  wiki: <FaWikipediaW className="h-4 w-4" />,
  youtube: <FaYoutube className="h-4 w-4" />,
  twitch: <FaTwitch className="h-4 w-4" />,
  steam: <FaSteam className="h-4 w-4" />,
  piracy: <GiPirateFlag className="h-4 w-4" />,
  imdb: <FaImdb className="h-4 w-4" />,
  rt: <SiRottentomatoes className="h-4 w-4" />,
};

interface GRPantheonRankingProps {
  selectedIpIds: Array<any>;
  setSelectedIpIds: (selectedIpIds: any) => void;
  setSplitPanelStatus: (status: boolean) => void;
  filterQuery: any;
  vertical: Vertical;
  weightageValues: any;
  dateRange: any;
  useInfiniteScroll?: boolean;
  onItemCountSet?: (count: number) => void;
  sortField?: string;
  sortOrder?: string;
}

export const GRPantheonRanking: FC<GRPantheonRankingProps> = forwardRef(({
  selectedIpIds = [],
  setSelectedIpIds = null,
  setSplitPanelStatus,
  filterQuery,
  vertical,
  weightageValues,
  dateRange,
  useInfiniteScroll = true,
  onItemCountSet = null,
  sortField = "score",
  sortOrder = "desc",
}, ref) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [listData, setListData] = useState(null);
  const [innerSelectedIpIds, setInnerSelectedIpIds] = useState(selectedIpIds);

  const limit = useMemo(
    () => (useInfiniteScroll === true ? LIMIT : 1000),
    [useInfiniteScroll],
  );

  const {
    error: pantheonDataError,
    data: pantheonData,
    isLoading: pantheonDataLoading,
  } = useGetPantheonRanking({
    filterQuery,
    sort: sortField,
    vertical: verticalMap[vertical] as MoviesType,
    sortOrder: sortOrder,
    offset: currentPageIndex * limit,
    paginationPageSize: limit,
    startTs: dateRange?.startDate ? moment.utc(dateRange.startDate).startOf("day").unix() : null,
    endTs: dateRange?.endDate ? moment.utc(dateRange.endDate).startOf("day").unix() : null,
    weightageValues,
  });

  const tileItemData: Array<TileData> = useMemo(() => {
    if (!listData) return [];

    return listData.data.map(item => (
      {
        ipId: item.ip_id,
        title: item.ip,
        vertical: item.vertical,
        imageUrl: item.image_url,
        releaseDate: item.release_date,
        rank: item.rank,
        rankChange: item.rank_change,
        alwaysShowAction: innerSelectedIpIds.includes(item.ip_id),
        action: (
          <Button
            iconSvg={<CompareIcon />}
            onClick={() => {
              const isSelected = innerSelectedIpIds.includes(item.ip_id);
              if (isSelected) {
                handleSetSelectedIpIds(innerSelectedIpIds.filter((x) => x !== item.ip_id));
              } else {
                handleSetSelectedIpIds([...innerSelectedIpIds, item.ip_id]);
                setSplitPanelStatus(true);
              }
            }}
            variant={innerSelectedIpIds.includes(item.ip_id) ? "primary" : "normal"}
          />
        ),
        content: (
          <div className="flex flex-col space-y-2 w-full">
            <div className={`rounded-md px-2 py-1 text-sm font-semibold border-2 border-slate-600 text-slate-200 flex justify-center gap-1`}>
              Score: <span className="font-bold text-yellow-300">{parseFloat(item.score).toFixed(1)}</span>
            </div>
            <div className="flex flex-col gap-y-1 text-slate-200">
              {Object.keys(platformIconMap).map(platform => (
                item[platform] != null && item[platform] > 0 && (
                  <div className="text-xs flex items-center rounded-md w-full">
                    <span className="p-1 rounded-l-md text-slate-400" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>{platformIconMap[platform]}</span>
                    <span className="p-1 rounded-r-md font-bold text-slate-400 w-full" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>{`${parseFloat(item[platform]).toFixed(1)}`}</span>
                  </div>
                )
              ))}
            </div>
          </div>
        ),
      } as TileData
    ));
  }, [listData, innerSelectedIpIds]);

  const fetchMoreData = () => {
    if (!pantheonDataLoading && hasMore) {
      setCurrentPageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleSetSelectedIpIds = (selectedIpIds) => {
    setInnerSelectedIpIds(selectedIpIds);
    setSelectedIpIds(selectedIpIds);
  };

  useImperativeHandle(ref, () => ({ setInnerSelectedIpIds }));

  useEffect(() => {
    if (!pantheonData) return;
    const mappedData = mapGetPantheonResponse(pantheonData);
    if (currentPageIndex === 0) {
      setListData(mappedData);
    } else {
      setListData((prevItems) => combineMappedResponses(prevItems, mappedData));
    }
    setHasMore(pantheonData.data.total >= limit);
    if (onItemCountSet) {
      onItemCountSet(pantheonData.data.total);
    }
  }, [pantheonData]);

  useEffect(() => {
    setCurrentPageIndex(0);
    setHasMore(true);
  }, [dateRange, sortField, sortOrder]);

  useEffect(() => {
    if (onItemCountSet && pantheonDataLoading) {
      onItemCountSet(-1);
    }
  }, [pantheonDataLoading]);

  useEffect(() => {
    if (!_.isEqual(selectedIpIds, innerSelectedIpIds)) {
      setInnerSelectedIpIds(selectedIpIds);
    }
  }, [selectedIpIds]);

  return (
    <div>
      {pantheonDataLoading && currentPageIndex === 0 && (
        <div className="flex flex-row justify-center h-48">
          <CustomLoadingOverlay />
        </div>
      )}
      {pantheonDataError && (
        <div className="flex justify-center my-20">
          <div>
            {`Error loading data: 
            ${pantheonDataError?.message}`}
          </div>
        </div>
      )}
      {!(pantheonDataLoading && currentPageIndex === 0) &&
        !pantheonDataError &&
        (useInfiniteScroll ? (
          <InfiniteScroll
            style={{ overflow: "100%" }}
            dataLength={listData?.data?.length || 0}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className="flex justify-center my-10">
                <Spinner size="large" />
              </div>
            }
            endMessage={<p></p>}
          >
            <TitleTiles
              variant="grid"
              itemData={tileItemData.map(item => ({ ...item, vertical }))} 
            />
          </InfiniteScroll>
        ) : (
          <TitleTiles
            variant="grid"
            itemData={tileItemData.map(item => ({ ...item, vertical }))} 
          />
        ))}
    </div>
  );
});
