import { useCallback, useMemo } from "react";

interface UseFetchServerSideGridDataProps {
  fetchMethod: any;
  requestParams?: any;
  setIsLoading?: any;
  gridRef?: any;
}

export const useFetchServerSideGridData = ({
  fetchMethod,
  requestParams={},
  setIsLoading,
  gridRef,
}: UseFetchServerSideGridDataProps) => {  

  const dependencyList = JSON.stringify(requestParams);

  const createServer = useCallback(() => {
    return {
      getData: async (request) => {
        const offset = request.startRow;
        const paginationPageSize = request.endRow - request.startRow;

        setIsLoading?.(true);
        try {
          const requestedRows = await fetchMethod({
            offset,
            limit: paginationPageSize,
            enabled: true,
            ...requestParams,
          });
          setIsLoading?.(false);

          return {
            success: true,
            rows: requestedRows?.data?.results ?? [],
            total: requestedRows?.data?.total ?? 0,
          };
        } catch (error) {
          setIsLoading?.(false);
          return {
            success: false,
            rows: [],
            total: 0,
          };
        }
      },
    };
  }, [dependencyList]);

  const createServerSideDatasource = (server) => {
    return {
      getRows: async (params) => {
        const response = await server.getData(params.request);

        if (response.success) {
          if (gridRef?.current) {
            setTimeout(() => {
              response.total === 0 ? gridRef.current?.api?.showNoRowsOverlay() : gridRef.current?.api?.hideOverlay();
            }, 100);
          }

          params.success({
            rowData: response.rows,
            rowCount: response.total,
          });
        } else {
          params.fail();
        }
      },
    };
  };

  const dataSource = useMemo(() => {
    const server = createServer();
    return createServerSideDatasource(server);
  }, [createServer]);

  return {
    dataSource,
  };
};
