import { useCallback } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../../utils/shortenInteger";

const tooltip = {
  renderer: ({ datum }) => {
    const date = new Date(datum.timestamp);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const humanReadableDate = date.toLocaleString("en-US", options);
    const title = `<span style="font-size: 12px">${humanReadableDate}</span>`;

    return {
      title: title,
      content: `
                <b>Votes:</b> ${shortenInteger(datum.dailyVotes)} <br />
                <b>Votes (All time):</b> ${shortenInteger(
                  datum.allTimeVotes,
                )}<br/>
                <b>Rating (All time):</b> ${datum.allTimeRating}<br/>
            `,
    };
  },
};

export const useChartConfig = () => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);

  const getLineChartOptions = useCallback(() => {
    return {
      padding: { right: 0, bottom: 0, left: 0 },
      minWidth: 0,
      minHeight: 0,
      theme: theme,
      legend: {
        position: "bottom",
        spacing: 12,
      },
      series: [
        {
          type: "line",
          xKey: "timestamp",
          yKey: "dailyVotes",
          yName: "Votes",
          marker: { enabled: false },
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "allTimeVotes",
          yName: "Votes (All time)",
          marker: { enabled: false },
          visible: false,
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "allTimeRating",
          yName: "Rating (All time)",
          marker: { enabled: false },
          visible: false,
          tooltip,
        },
      ],
      axes: [
        {
          type: "ordinal-time",
          position: "bottom",
          label: {
            format: "%b %-d, %Y",
          },
          crosshair: {
            label: {
              format: "%b %-d, %Y",
            },
          },
        },
        {
          // type: axisTypeValue || "number",
          type: "number",
          position: "left",
          nice: true,
          keys: ["dailyVotes", "allTimeVotes", "allTimeRating", "allTimeRank"],
          title: {
            enabled: false,
            text: "Votes & Ratings",
            fontSize: 10,
            color: "rgb(100 116 139)",
            spacing: 4,
          },
          label: {
            formatter: function (params) {
              return shortenInteger(params.value);
            },
          },
        },
      ],
    };
  }, [theme]);

  return { getLineChartOptions };
};
