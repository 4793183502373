import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Button, Icon } from '@cloudscape-design/components';
import Tag from './Tag';
import { Vertical } from '../../types/verticalTypes';
import { VerticalIcon } from '../VerticalIcon';

const prefixMap = {
  "film": Vertical.Movies,
  "series": Vertical.Series,
  "game": Vertical.Games,
  "franchise": Vertical.Franchises,
};

const getRelativeTimeLabel = (timestamp: number) => {
  const now = moment();
  const then = moment(timestamp * 1000);
  const diff = now.diff(then, 'days');
  if (diff === 0) {
    return then.fromNow();
  } else if (diff === 1) {
    return `Yesterday at ${then.format('h:mm A')}`;
  } else {
    return `${then.format('MMM D')} at ${then.format('h:mm A')}`;
  }
}

const TimeLabel = ({ timestamp }) => {
  const [relativeTime, setRelativeTime] = useState<string>(getRelativeTimeLabel(timestamp));

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLabel = getRelativeTimeLabel(timestamp);
      setRelativeTime(newTimeLabel);
      if (!newTimeLabel.includes("ago")) {
        clearInterval(interval);
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [timestamp]);

  return (
    <span title={moment(timestamp * 1000).format("LLL")}>{relativeTime}</span>
  );
};

const HighlightedText = ({ text, highlights }) => {
  if (!highlights?.length || !text) return text;

  const parts = text.split(new RegExp(`(${highlights.join("|")})`, "gi"));
  return (
    <span>
      {parts.map((part, index) => (
        highlights.includes(part.toLowerCase()) ? <span key={index} className="dark:bg-slate-400/15 bg-slate-400/25 dark:text-sky-300 text-sky-600 font-bold">{part}</span> : part
      ))}
    </span>
  );
};

interface NewsItemProps {
  title: string;
  description: string;
  publishedTimestamp: number;
  link: string;
  sourceName: string;
  sourceUrl: string;
  tags: Array<string>;
  linkedIps: Array<{ ip: string, ip_id: string }>;
  highlights?: Array<string>;
}

const NewsItem: React.FC<NewsItemProps> = ({
  title,
  description,
  publishedTimestamp,
  link,
  sourceName,
  sourceUrl,
  tags=[],
  linkedIps=[],
  highlights=[],
}) => {
  const tagContainerRef = useRef<HTMLDivElement>(null);

  const [isWrapped, setIsWrapped] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    const container = tagContainerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      const childElements = Array.from(container.children) as HTMLElement[];
      if (childElements.length < 2) return;
      const firstOffsetTop = childElements[0].offsetTop;
      const wrapped = childElements.some(child => child.offsetTop !== firstOffsetTop);
      setIsWrapped(wrapped);
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, [linkedIps, tags]);

  return (
    <div className="flex gap-2 flex-nowrap">
      <div className="w-1 min-w-1 h-auto bg-blue-500"></div>
      <div className="w-full flex flex-col">
        <div className="dark:text-slate-400 text-slate-600 line-clamp-2 text-xs italic">
          <a href={sourceUrl} target="_blank" className="dark:text-blue-400 text-blue-600">{sourceName}</a>
          {" "}&bull;{" "}
          <TimeLabel timestamp={publishedTimestamp} />
        </div>
        <div className="font-bold">
          <a href={link} target="_blank">
            <HighlightedText
              text={title}
              highlights={highlights}
            />
            {" "}
            <Icon name="external" />
          </a>
        </div>
        <div className="dark:text-slate-400 text-slate-600 line-clamp-2 text-xs">
          <HighlightedText
            text={description}
            highlights={highlights}
          />
        </div>
        {(linkedIps?.length > 0 || tags?.length > 0) && (
          <>
            <div ref={tagContainerRef} className={`mt-2 flex flex-wrap gap-2 w-full overflow-hidden ${isExpanded ? "" : "h-7"}`}>
              {linkedIps?.map(ip => (
                <Tag key={ip.ip_id} href={`/item/${ip.ip_id}`} color="blue">
                  <div className="flex gap-1 items-center">
                    <VerticalIcon vertical={prefixMap[ip.ip_id.split("-")[0]]} />
                    <HighlightedText
                      text={ip.ip}
                      highlights={highlights}
                    />
                  </div>
                </Tag>
              ))}
              {tags?.filter(tag => !linkedIps?.map(x => x.ip).includes(tag)).map(tag => (
                <Tag key={tag} color="blue">
                  <HighlightedText
                    text={tag}
                    highlights={highlights}
                  />
                </Tag>
              ))}
            </div>
            {isWrapped && (
              <Button
                variant="inline-link"
                onClick={() => setIsExpanded(prev => !prev)}
              >
                <span className="text-xs">{isExpanded ? "show less" : "show more"}</span>
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NewsItem;