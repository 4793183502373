export interface GetRtDataResponseItem {
  timestamp: number;
  audience_rating: number;
  audience_votes: number;
  critic_rating: number;
  critic_votes: number;
  daily_audience_votes: number;
  daily_rank: number;
  rank: number;
}

export interface MappedGetRtResponseItem {
  dailyRank: number;
  timestamp: number;
  dailyAudienceVotes: number;
  allTimeAudienceVotes: number;
  allTimeAudienceRating: number;
  allTimeCriticVotes: number;
  allTimeCriticRating: number;
  allTimeRank: number;
}

export const mapRtResponse = (item: GetRtDataResponseItem) => ({
  dailyRank: item.daily_rank,
  timestamp: item.timestamp * 1000,
  dailyAudienceVotes: item.daily_audience_votes,
  allTimeAudienceVotes: item.audience_votes,
  allTimeAudienceRating: item.audience_rating,
  allTimeCriticVotes: item.critic_votes,
  allTimeCriticRating: item.critic_rating,
  allTimeRank: item.rank,
});

export const RT_GRID_HEADER_MAP = {
  dailyRank: "Daily rank",
  dailyAudienceVotes: "Daily audience votes",
  allTimeAudienceVotes: "All time audience votes",
  allTimeAudienceRating: "All time audience rating",
  allTimeCriticVotes: "All time critic votes",
  allTimeCriticRating: "All time critic rating",
  allTimeRank: "All time rank",
};
