import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../../Layout";
import { navItems, NavItemsWithId } from "../../../../../layouts/common/menu/side-menu";
import { useParams } from "react-router";
import { useGetReportData } from "../../api/hooks/useGetReportData";
import { Button, ButtonDropdown, ColumnLayout, Container, FormField, Header, Popover, SpaceBetween, Spinner, StatusIndicator } from "@cloudscape-design/components";
import { getReportResults } from "../../api/request";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import { AgGridReact } from "ag-grid-react";
import { useGridConfig } from "./hooks/useGridConfig";
import { useFetchServerSideGridData } from "../CreateReportWizard/hooks/useFetchServerSideGridData";

export const ReportResults = () => {

  const title = "Report Results";
  const breadcrumbs = [
    { text: "Tools" },
    { text: "Report Builder", href: "/tools/report-builder" },
  ];

  const { id } = useParams();
  const { theme } = useGetGridTheme();
  const gridRef = useRef(null);

  const [isExporting, setIsExporting] = useState(false);

  const { data: reportData, isLoading: reportDataLoading, error: reportDataError } = useGetReportData({ id });
  const gridOptions = useGridConfig({ columnDefinitions: reportData?.report_definition?.column_definitions, sortBy: reportData?.report_definition?.sort_by });
  const { dataSource } = useFetchServerSideGridData({ 
    fetchMethod: getReportResults, 
    requestParams: { id }
  });

  const exportData = (format) => {
    setIsExporting(true);

    getReportResults({
      id,
      download: format,
    }).then(data => {
      const url = data.data;
      window.location.href = url;
      setIsExporting(false);
    });
  };

  const getDuration = (item) => {
    let updatedTime = item.updated_at;
    if (item.status === "RUNNING") {
      updatedTime = Math.floor(Date.now() / 1000);
    }
    const duration = updatedTime - item.created_at;
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
    return hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : `${minutes}m ${seconds}s`;
  };

  return (
    <Layout
      contentType="table"
      title={reportData?.name || title}
      navItems={navItems as NavItemsWithId}
      breadcrumbs={[...breadcrumbs, { text: title }]}
      content={
        <Container
          variant={"borderless" as any}
          disableContentPaddings
          disableHeaderPaddings
          header={
            <Header
              actions={
                <div>
                  <ButtonDropdown
                    items={[
                      { id: "csv", text: "Report as .CSV" },
                      { id: "xlsx", text: "Report as .XLSX" },
                    ]}
                    onItemClick={({ detail }) => {
                      switch (detail.id) {
                        case "csv":
                          exportData("csv");
                          break;
                        case "xlsx":
                          exportData("xlsx");
                          break;
                      }
                    }}
                    loading={isExporting}
                  >
                    Export
                  </ButtonDropdown>
                </div>
              }
            >
              <div className="flex gap-2 items-center">
                <div>{reportData?.name}</div>
                {reportData?.shared_with?.length > 0 && (
                  <Popover
                    dismissButton={false}
                    position="right"
                    triggerType="custom"
                    size="large"
                    content={
                      <div className="space-y-2">
                        <p>
                          Shared with: <b>{reportData.shared_with.join(", ")}</b>
                        </p>
                      </div>
                    }
                  >
                    <Button iconName="group-active" variant="inline-icon" />
                  </Popover>
                )}
              </div>
            </Header>
          }
        >
          <div className="mt-4 flex flex-col gap-4">
            <Container>
              <ColumnLayout columns={2} borders="vertical">
                <SpaceBetween direction="vertical" size="m">
                  <FormField label="Status">
                    {reportDataLoading ? (
                      <Spinner />
                    ) : (
                      reportData.status === "RUNNING" ? (
                        <StatusIndicator type="pending">
                          Running
                        </StatusIndicator>
                      ) : reportData.status === "FINISHED" ? (
                        <StatusIndicator>
                          Finished
                        </StatusIndicator>
                      ) : reportData.status === "FAILED" ? (
                        <StatusIndicator type="error">
                          Failed
                        </StatusIndicator>
                      ) : null
                    )}
                  </FormField>
                  <FormField label="Created by">
                    {reportDataLoading ? (
                      <Spinner />
                    ) : (
                      reportData.created_by
                    )}
                  </FormField>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="m">
                  <FormField label="Created at">
                    {reportDataLoading ? (
                      <Spinner />
                    ) : (
                      new Date(reportData?.created_at * 1000).toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true })
                    )}
                  </FormField>
                  <FormField label="Duration">
                    {reportDataLoading ? (
                      <Spinner />
                    ) : (
                      getDuration(reportData)
                    )}
                  </FormField>
                </SpaceBetween>
              </ColumnLayout>
            </Container>
            <div className={theme} style={{ height: "80vh", "--ag-grid-size": "5px", "--ag-spacing": "5px" } as React.CSSProperties}>
              <AgGridReact
                ref={gridRef}
                pagination={true}
                paginationPageSizeSelector={[20, 50, 100]}
                rowModelType={"serverSide"}
                blockLoadDebounceMillis={100}
                serverSideDatasource={dataSource}
                suppressRowClickSelection={true}
                suppressCsvExport
                suppressExcelExport
                autoSizeStrategy={{
                  type: "fitCellContents",
                  skipHeader: false,
                }}
                {...gridOptions}
              />
            </div>
          </div>
        </Container>
      }
    />
  );
};