import { LuBookOpen, LuNetwork, LuTv2 } from "react-icons/lu";
import { LuClapperboard } from "react-icons/lu";
import { LuGamepad2 } from "react-icons/lu";
import { Vertical } from "../types/verticalTypes";

const verticalLabelMap = {
  [Vertical.Movies]: "Movies",
  [Vertical.Series]: "Series",
  [Vertical.Games]: "Video Games",
};

const verticalColorMap = {
  [Vertical.Movies]: {
    border: "border-red-600 dark:border-red-400",
    text: "text-red-600 dark:text-red-400",
  },
  [Vertical.Series]: {
    border: "border-green-600 dark:border-green-400",
    text: "text-green-600 dark:text-green-400",
  },
  [Vertical.Games]: {
    border: "border-blue-600 dark:border-blue-400",
    text: "text-blue-600 dark:text-blue-400",
  },
  default: {
    border: "",
    text: "",
  },
};



export const VerticalIcon = ({ vertical, titleCount=null, useColors=false, ...props }) => {
  if (!vertical) return null;
  const colorVertical = useColors ? vertical : "default";
  return (
    <span className={`inline-flex items-center gap-1 ${verticalColorMap[colorVertical]?.text} ${props.className}`} title={(titleCount ? `${titleCount} ` : "") + verticalLabelMap[vertical]} {...props}>
      {vertical === Vertical.Movies && <LuClapperboard className={props.className ?? ""} />}
      {vertical === Vertical.Series && <LuTv2 className={props.className ?? ""} />}
      {vertical === Vertical.Games && <LuGamepad2 className={props.className ?? ""} />}
      {vertical === "Franchises" && <LuNetwork className={props.className ?? ""} />}
      {vertical === "Book" && <LuBookOpen className={props.className ?? ""} />}
      {(titleCount != null) && <span className="text-sm">{titleCount}</span>}
    </span>
  );
};

export const VerticalIconBadge = ({ vertical, useColors=false, ...props }) => {
  if (!vertical) return null;
  const colorVertical = useColors ? vertical : "default";
  return (
    <span className={`border ${verticalColorMap[colorVertical].border} px-2 py-0.5 rounded-full ${verticalColorMap[colorVertical].text} inline-flex items-center`}>
      <VerticalIcon vertical={vertical} {...props} />
    </span>
  );
}