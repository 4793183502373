import axiosInstance from "../../../../../utils/axios";

interface GetPlatformDataProps {
  ipId: string;
  platform: string;
  startDate: string;
  endDate: string;
  includeZScore?: boolean;
}

export const getPlatformData = ({
  ipId = "",
  platform = "",
  startDate,
  endDate,
  includeZScore=false,
}: GetPlatformDataProps) =>
  axiosInstance.get(
    `/pantheon/globaldata/v1?ip_ids=${ipId}&platforms=${platform}&start_date=${startDate}&end_date=${endDate}&include_zscore=${includeZScore}`,
  );
