import React, { useEffect, useMemo, useRef, useState } from "react";
import { AppLayoutProps, Box, Button, ContentLayout, ExpandableSection, Header, Icon, Link, Multiselect, Popover, Select, SpaceBetween, Spinner, StatusIndicator, Tabs } from "@cloudscape-design/components";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import moment from "moment";
import { GoogleTrendsCharts } from "../google/components/GoogleTrendsCharts";
import { addQueryParams, getQueryParams } from "../../../utils/queryUtils";
import { ALL_CATEGORY, CATEGORIES, Category, COUNTRIES, Country, DEFAULT_GOOGLE_FITLERS, DMA_REGIONS } from "./constants";
import { Parameters } from "./components/Parameters";
import { CountryMap } from "./components/CountryMap/CountryMap";
import { getAllCategories } from "./utils";
import { TimeseriesChart } from "./components/TimeseriesChart/TimeseriesChart";
import { useGetGoogleRankingData } from "./api/hooks/useGetGoogleRankingData";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { useSearchParams } from "react-router-dom";
import { RankingTable } from "./components/RankingTable/RankingTable";
import axiosInstance from "../../../utils/axios";
import { InfoDrawer } from "./components/InfoDrawer";
import TitleTiles from "../../../components/title-tiles/TitleTiles";
import { TileData } from "../../../components/title-tiles/types";
import { TbListSearch } from "react-icons/tb";
import { CompareContainer } from "./components/CompareContainer/CompareContainer";
import { GoogleDashboardContext } from "./hooks/useGoogleDashboardContext";
import { SERVICES, TYPES } from "../../../config-global";
import { verticalOptionsWithAll } from "../../../types/verticalTypes";
import { BasicParameters } from "../../ranking/global-ranking/pantheon-ranking/components/BasicParameters";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { additionalColumns } from "../../ranking/global-ranking/pantheon-ranking/GRPantheonRankingIndex";
import { useFilterDrawer } from "../../../hooks/FilterDrawer/useFilterDrawer";
import { useAuthContext } from "../../../auth/useAuthContext";
import { ManageTitleVariants } from "./components/Manage/TitleVariants/ManageTitleVariants";
import { useGenericPreference_V2 } from "../../../services/generic_v2/hooks/useGenericPreference_V2";

const mainCountries: Array<Country> = [
  COUNTRIES.AUSTRALIA,
  COUNTRIES.BRAZIL,
  COUNTRIES.CANADA,
  COUNTRIES.GERMANY,
  COUNTRIES.SPAIN,
  COUNTRIES.FRANCE,
  COUNTRIES.UNITED_KINGDOM,
  COUNTRIES.IRELAND,
  COUNTRIES.ITALY,
  COUNTRIES.JAPAN,
  COUNTRIES.SOUTH_KOREA,
  COUNTRIES.MEXICO,
  COUNTRIES.THAILAND,
  COUNTRIES.UNITED_STATES_OF_AMERICA,
  COUNTRIES.INDIA,
];

const overviewCategories = [
  CATEGORIES.GENERAL.CATEGORY,
  CATEGORIES.LEARN_ABOUT.GENERAL,
  CATEGORIES.INTENT_TO_WATCH.THEATRICAL,
  CATEGORIES.INTENT_TO_WATCH.LATEST_SEASON,
  CATEGORIES.INTENT_TO_WATCH.PIRACY,
  CATEGORIES.INTENT_TO_PLAY.PHYSICAL.PC,
  CATEGORIES.INTENT_TO_PLAY.PHYSICAL.CONSOLE,
  CATEGORIES.LEARN_ABOUT.TRAILERS,
  CATEGORIES.REVIEWS, 
  CATEGORIES.LEARN_ABOUT.AWARDS,
];

const fullDateRange = {
  type: "absolute",
  startDate: "2023-01-01",
  endDate: moment().format("YYYY-MM-DD"),
};

const rankingCategories = [ALL_CATEGORY, ...getAllCategories(CATEGORIES)];

export const GoogleDashboard = () => {
  const title = "Google";
  const breadcrumbs = [{ text: "Platforms" }, { text: title }];
  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.PLATFORMS, module: "google" };

  const gridRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthContext();

  const [selectedTab, setSelectedTab] = useState<string>(["trends", "overview", "rankings", "insights", "variants"].find(x => x === searchParams.get("tab")) ?? "overview");
  const [selectedTitle, setSelectedTitle] = useState<any | null>(null);

  const [dateRange, setDateRange] = useState<any>({
    type: "relative",
    amount: 2,
    unit: "week",
    startDate: moment().subtract(2, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const {
    filterDrawerConfig,
    filterObject,
    setFilterValue,
  } = useFilterDrawer({ 
    defaultFilterObject: DEFAULT_GOOGLE_FITLERS, 
    prefsApiParams: apiParams, 
    gridRef,
    treatAsDefault: { vertical: "all", category: CATEGORIES.GENERAL.CATEGORY.value },
    ignoreFields: ["category"],
  });

  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState<Array<OptionDefinition>>([]);
  const [selectedIpIds, setSelectedIpIds] = useState<Array<string>>([]);
  const [latestDataDate, setLatestDataDate] = useState<string | null>(null);


  const selectedVertical = useMemo(() => verticalOptionsWithAll.find(o => o.value === (filterObject.filters.find(f => f.field === "vertical")?.value?.[0] || "all")), [filterObject]);
  const selectedCategory = useMemo(() => rankingCategories.find(c => c.value === (filterObject.filters.find(f => f.field === "category")?.value?.[0] || CATEGORIES.GENERAL.CATEGORY)), [filterObject]);
  const titleSearchQuery = useMemo(() => filterObject.filters.find(f => f.field === "ip")?.value?.[0] || "", [filterObject]);
  const additionalColumnOptions = useMemo<Array<OptionDefinition>>(() => {
    const additionalColumnKeys = selectedVertical.value === "all" ? Array.from(new Set(Object.values(additionalColumns).flat())) : additionalColumns[selectedVertical.value];
    return additionalColumnKeys.filter(key => key !== "Score Change").map(key => ({ label: key, value: key }));
  }, [selectedVertical, additionalColumns]);


  const { data: rankingData, isLoading: rankingDataLoading, error: rankingDataError } = useGetGoogleRankingData({
    enabled: selectedTab === "overview",
    geoName: COUNTRIES.UNITED_STATES_OF_AMERICA.value,
    geoType: "country",
    startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
    endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
  });

  const handlePreferenceFirstLoad = (preferenceData) => {
    if (preferenceData) {
      if (preferenceData.selectedAdditionalColumns) {
        setSelectedAdditionalColumns(additionalColumnOptions.filter(o => preferenceData.selectedAdditionalColumns.includes(o.value)));
      }
    }
  };

  const { savePreference } = useGenericPreference_V2({ apiParams, onPreferenceFirstLoad: handlePreferenceFirstLoad });

  useEffect(() => {
    if (searchParams.get("title")) {
      const id = searchParams.get("title");
      setSelectedTitle({ ip_id: id });
      axiosInstance.get(
        `ipmanagement/v2/iplist?id=${id}&include=ip,ip_id,vertical,image_url,release_date`,
      ).then((response) => {
        const title = response.data[0];
        setSelectedTitle(title);
      });
    }
  }, [searchParams]);

  const infoDrawerConfig: AppLayoutProps.Drawer = {
    id: "infoDrawer",
    content: (
      <InfoDrawer />
    ),
    trigger: {
      iconName: "status-info",
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
  };

  return (
    <GoogleDashboardContext.Provider value={{ 
      setSelectedTab, 
      setSplitPanelStatus,
    }}>
      <Layout
        title={title}
        breadcrumbs={breadcrumbs}
        navItems={navItems as NavItemsWithId}
        drawers={[infoDrawerConfig, ...(selectedTab === "rankings" ? [filterDrawerConfig] : [])]}
        splitPanel={
          selectedTab === "rankings" && selectedCategory.value !== "all" && (
            <CompareContainer 
              dateRange={dateRange}
              selectedCategory={selectedCategory}
              selectedIpIds={selectedIpIds}
              setSelectedIpIds={setSelectedIpIds}
            />
          )
        }
        splitPanelStatus={splitPanelStatus}
        setSplitPanelStatus={setSplitPanelStatus}
        contentType="table"
        content={
          <ContentLayout
            header={
              <>
                <Header
                  description="View rankings and insights into the performance of titles on Google search across various categories"
                  variant="h2"
                >
                  {title}
                </Header>
              </>
            }
          >
            <SpaceBetween size="m" direction="vertical">
              <Tabs
                activeTabId={selectedTab}
                onChange={({ detail }) => {
                  setSelectedTab(detail.activeTabId);
                  setSearchParams({ tab: detail.activeTabId });
                }}
                tabs={[
                  { label: "Trends", id: "trends" },
                  { label: "Overview", id: "overview" },
                  { label: "Rankings", id: "rankings" },
                  { label: "Title Insights", id: "insights" },
                  ...(user?.role === "superAdmin" ? [{ label: "Admin: Title Variants", id: "variants" }] : []),
                ]}
                disableContentPaddings
              />
              {selectedTab === "trends" ? (
                <GoogleTrendsCharts
                  setSelectedVertical={(vertical) => addQueryParams({ category: vertical.value })}
                />
              ) : selectedTab === "overview" ? (
                <>
                  <Header
                    actions={
                      <RangeDateSelector
                        onChange={setDateRange}
                        defaults={dateRange}
                      />
                    }
                    description={rankingData?.latest_data_date ? `Last updated on ${moment(rankingData.latest_data_date).format("MMM D, YYYY")}` : ""}
                  >
                    Rankings by category in the United States
                  </Header>
                  {rankingDataLoading ? (
                    <div className="w-full h-96 flex items-center justify-center"><Spinner size="large" /></div>
                  ) : rankingData?.data ? (
                    overviewCategories.map((category) => (
                      rankingData.data[category.value]?.length > 0 && (
                        <div style={{ overflow: "hidden" }}>
                          <Header
                            variant="h3"
                            description={category.description}
                            info={
                              <Button
                                variant="inline-link"
                                onClick={() => {
                                  setSearchParams(params => {
                                    params.set("category", category.value);
                                    params.set("tab", "rankings");
                                    setSelectedTab("rankings");
                                    setFilterValue("category", category.value);
                                    return params;
                                  });
                                }}
                              >
                                <span className="text-xs">view more</span>
                              </Button>
                            }
                          >
                            {category.label}
                          </Header>
                          <TitleTiles
                            variant="row"
                            itemData={rankingData.data[category.value].map((item: any) => (
                              {
                                ipId: item.ip_id,
                                title: item.ip,
                                imageUrl: item.image_url,
                                vertical: item.vertical,
                                releaseDate: item.release_date,
                                rank: item.rank,
                                rankChange: item.rank_change,
                                action: (
                                  <Button
                                    iconSvg={<span className="w-full h-full flex items-center justify-center text-lg"><TbListSearch /></span>}
                                    ariaLabel="View title insights"
                                    onClick={() => {
                                      setSearchParams(params => {
                                        params.set("title", item.ip_id);
                                        params.set("tab", "insights");
                                        setSelectedTab("insights");
                                        return params;
                                      });
                                    }}
                                    variant={"normal"}
                                  />
                                ),
                              } as TileData
                            ))}
                          />
                        </div>
                      )
                    ))
                  ) : (
                    <div className="w-full h-96 flex items-center justify-center">
                      {rankingDataError ? "An error occurred while fetching data" : "No data available"}
                    </div>
                  )}
                </>
              ) : selectedTab === "insights" ? (
                <>
                  <Parameters
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                  />
                  {selectedTitle ? (
                    <>
                      <TimeseriesChart
                        selectedTitle={selectedTitle}
                        dateRange={fullDateRange}
                        categories={getAllCategories(CATEGORIES)}
                        countries={mainCountries}
                      />
                      <CountryMap
                        selectedTitle={selectedTitle}
                        categories={getAllCategories(CATEGORIES)}
                        countries={Object.values(COUNTRIES)}
                        dmaRegions={Object.values(DMA_REGIONS)}
                        dateRange={fullDateRange}
                      />
                    </>
                  ) : (
                    <div className="w-full h-96 flex items-center justify-center">
                      Select a title to get started
                    </div>
                  )}
                </>
              ) : selectedTab === "rankings" ? (
                <>
                  <BasicParameters
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    titleSearchQuery={titleSearchQuery}
                    setTitleSearchQuery={(query) => setFilterValue("ip", query)}
                    showAdvancedFilter={false}
                    additionalFilters={
                      <div className="flex gap-2 items-center">
                        {latestDataDate && (
                          <div className="text-xs dark:text-slate-400 text-slate-600">Last updated on {moment(latestDataDate).format("MMM DD, YYYY")}</div>
                        )}
                        <Multiselect
                          options={additionalColumnOptions}
                          selectedOptions={selectedAdditionalColumns}
                          onChange={({ detail }) => {
                            setSelectedAdditionalColumns(detail.selectedOptions as Array<OptionDefinition>);
                            savePreference({ selectedAdditionalColumns: detail.selectedOptions.map(o => o.value) });
                          }}
                          placeholder="Additional columns"
                          hideTokens
                        />
                        <Select
                          options={verticalOptionsWithAll}
                          selectedOption={selectedVertical as any}
                          onChange={({ detail }) => {
                            setFilterValue("vertical", detail.selectedOption.value);
                          }}
                        />
                        <Select
                          options={rankingCategories}
                          selectedOption={selectedCategory}
                          onChange={({ detail }) => {
                            setFilterValue("category", detail.selectedOption.value);
                          }}
                        />
                      </div>
                    }
                  />
                  <RankingTable 
                    gridRef={gridRef}
                    selectedAdditionalColumns={selectedAdditionalColumns}
                    additionalColumnOptions={additionalColumnOptions}
                    dateRange={dateRange}
                    selectedCategory={selectedCategory}
                    selectedIpIds={selectedIpIds}
                    setSelectedIpIds={setSelectedIpIds}
                    setLatestDataDate={setLatestDataDate}
                  />
                </>
              ) : selectedTab === "variants" && user?.role === "superAdmin" ? (
                <ManageTitleVariants />
              ) : (
                null
              )}
            </SpaceBetween>
          </ContentLayout>
        }
      />
    </GoogleDashboardContext.Provider>
  );
};
